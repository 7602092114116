<template>
  <div id="youvme-lading-page">
    <div class="ld_header_wrap">
      <fixed-header :threshold="100">
        <div class="ld_header">
          <div class="container landing-container">
            <div class="row-header-top">
              <router-link to="/">
                <img class="youvme_logo" alt="Youvme" :src="require('../../assets/images/youvme.svg')" />
              </router-link>
              <a-button class="menu-mobile-btn" @click="showDrawer" :icon="menuIcon" />
              <ul class="desktop-menu">
                <li><a class="page_link_ft" href="/#browse-challenge">How does it Work?</a></li>
                <li><a class="page_link_ft" href="/#get-app">Creating a Challenge</a></li>
                <li><a class="page_link_ft" href="/#joining-the-challenge">App Features</a></li>
                <li><a class="page_link_ft" href="/#what-you-can-find">Browse Categories</a></li>
                <li><button class="btn-get-the-app" v-scroll-to="'#download-application'">Get the APP</button></li>
              </ul>
              <a-drawer class="menu-mobile-wapper" placement="left" :closable="false" :visible="visible"
                @close="onClose">
                <div class="nav_ft">
                  <a class="page_link_ft" href="/#browse-challenge">How does it Work?</a>
                  <a class="page_link_ft" href="/#get-app">Creating a Challenge</a>
                  <a class="page_link_ft" href="/#joining-the-challenge">App Features</a>
                  <a class="page_link_ft" href="/#what-you-can-find">Browse Categories</a>
                  <button class="btn-get-the-app" v-scroll-to="'#download-application'">Get the APP</button>
                </div>
              </a-drawer>
            </div>
          </div>
        </div>
      </fixed-header>
      <top-banner />
    </div>


    <div class="browse-challenges-wrapper landing-container">
      <h2>Terms of Service</h2>

      <p><strong>Last updated June 7, 2022</strong></p>

      <p>These Terms of Use ("Terms") are a legal agreement between Users (“"you” or "your”) and Wired Avenue LLC, with
        its registered address at 611 W G St., San Diego, CA 92101. (also referred to as “Company”, “we”, “us” or
        “our”).</p>

      <p>We implore you to read these terms and conditions carefully before using the platform. If you do not agree to
        these Terms of Use, please do not use the platform.</p>

      <p>These terms and conditions contained herein including any legal notices and disclaimers, constitutes the entire
        and only agreement between us and users and supersedes any and all prior or contemporaneous agreements,
        representations, warranties and all understandings with respect to the use of this platform. If you are under
        age 18, you may only use the Services with the consent of your parent or legal guardian. Please be sure your
        parent or legal guardian has reviewed and discussed these Terms with you.</p>


      <p><strong>1. Our Service</strong></p>

      <p>YOUvME is the ultimate challenge app. Compete with your friends to find out who's the best! From singing,
        dancing, or settling a dispute - the challenge is yours to discover.</p>

      <p><strong>2. Accepting the Terms</strong></p>

      <p>By accessing or using our Services, you confirm that you can form a binding contract with YOUvME, that you
        accept these Terms and that you agree to comply with them. Your access to and use of our Services is also
        subject to our Privacy Policy and Community Guidelines, the terms of which can be found directly on the
        Platform, or where the Platform is made available for download, on your mobile device’s applicable app store,
        and are incorporated herein by reference. By using the Services, you consent to the terms of the Privacy Policy.
      </p>

      <p>If you are accessing or using the Services on behalf of a business or entity, then (a) “you” and “your”
        includes you and that business or entity, (b) you represent and warrant that you are an authorized
        representative of the business or entity with the authority to bind the entity to these Terms, and that you
        agree to these Terms on the entity’s behalf, and (c) your business or entity is legally and financially
        responsible for your access or use of the Services as well as for the access or use of your account by others
        affiliated with your entity, including any employees, agents or contractors.</p>

      <p>You can accept the Terms by accessing or using our Services. You understand and agree that we will treat your
        access or use of the Services as acceptance of the Terms from that point onwards.</p>


      <p><strong>3. Your commitments to our community</strong></p>

      <p>We provide these services to you and others to help advance our mission. In exchange, we need you to make the
        following commitments:</p>

      <p><strong>A. Who can use YOUvME</strong></p>

      <p>Our community is safer and more accountable when you:</p>
      <ul>
        <li>Use the same name that you use in everyday life.</li>
        <li>Provide accurate information about yourself.</li>
        <li>Create only one account.</li>
        <li>Not share your password, give access to your account to others, or transfer your account to anyone else
          (without our permission).</li>
      </ul>
      <p>We try to make YOUvME broadly available to everyone, but you cannot use YOUvME if:</p>

      <ul>
        <li>You are under 13 years old (or the minimum legal age in your country to use our service), and if you are
          under age 18, you may only use the Services with the consent of your parent or legal guardian.</li>
        <li>You are a convicted sex offender.</li>
        <li>We've previously disabled your account for violations of our Terms or Policies.</li>
        <li>You are prohibited from receiving our products, services, or software under applicable laws.</li>
      </ul>

      <p><strong>B. What you can share and do</strong></p>

      <p>You may not use our Products to do or share anything:</p>

      <ul>
        <li>That violates these Terms and other terms and policies that apply to your use of our Platform.</li>
        <li>That is unlawful, misleading, discriminatory, or fraudulent.</li>
        <li>That infringes or violates someone else's rights, including their intellectual property rights.</li>
        <li>You may not upload viruses or malicious code or do anything that could disable, overburden, or impair the
          proper working or appearance of our Platform.</li>
        <li>You may not access or collect data from our Platform using automated means (without our prior permission) or
          attempt to access data you do not have permission to access.</li>
        <li>We can remove or restrict access to content that is in violation of these provisions.</li>
        <li>If we remove content that you have shared in violation of our terms of service, we will let you know and
          explain any options you have to request another review, unless you seriously or repeatedly violate these Terms
          or if doing so may expose us or others to legal liability; harm our community of users; compromise or
          interfere with the integrity or operation of any of our services; where we are restricted due to technical
          limitations; or where we are prohibited from doing so for legal reasons.</li>
        <li>To help support our community, we encourage you to report content or conduct that you believe violates your
          rights (including intellectual property rights) or our terms and policies.</li>
        <li>We also can remove or restrict access to your content, services or information if we determine that doing so
          is reasonably necessary to avoid or mitigate adverse legal or regulatory impacts to us.</li>

      </ul>

      <p>You may not:</p>

      <ul>
        <li>access or use the Services if you are not fully able and legally competent to agree to these Terms or are
          authorized to use the Services by your parent or legal guardian.</li>
        <li>make unauthorized copies, modify, adapt, translate, reverse engineer, disassemble, decompile or create any
          derivative works of the Services or any content included therein, including any files, tables or documentation
          (or any portion thereof) or determine or attempt to determine any source code, algorithms, methods or
          techniques embodied by the Services or any derivative works thereof.</li>
        <li>distribute, license, transfer, or sell, in whole or in part, any of the Services or any derivative works
          thereof.</li>
        <li>market, rent or lease the Services for a fee or charge, or use the Services to advertise or perform any
          commercial solicitation.</li>
        <li>use the Services, without our express written consent, for any commercial or unauthorized purpose, including
          communicating or facilitating any commercial advertisement or solicitation or spamming.</li>
        <li>interfere with or attempt to interfere with the proper working of the Services, disrupt our website or any
          networks connected to the Services, or bypass any measures we may use to prevent or restrict access to the
          Services.</li>
        <li>incorporate the Services or any portion thereof into any other program or product. In such a case, we
          reserve the right to refuse service, terminate accounts or limit access to the Services in our sole
          discretion.</li>
        <li>use automated scripts to collect information from or otherwise interact with the Services.</li>
        <li>impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with
          any person or entity, including giving the impression that any content you upload, post, transmit, distribute,
          or otherwise make available emanates from the Services.</li>
        <li>intimidate or harass another, or promote sexually explicit material, violence or discrimination based on
          race, sex, religion, nationality, disability, sexual orientation, or age.</li>
        <li>use or attempt to use another’s account, service, or system without authorization from YOUvME, or create a
          false identity on the Services.</li>
        <li>use the Services in a manner that may create a conflict of interest or undermine the purposes of the
          Services, such as trading reviews with other users or writing or soliciting fake reviews.</li>
        <li>use the Services to upload, transmit, distribute, store, or otherwise make available in any way: files that
          contain viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful.
        </li>
        <li>any unsolicited or unauthorized advertising, solicitations, promotional materials, “junk mail,” “spam,”
          “chain letters,” “pyramid schemes,” or any other prohibited form of solicitation.</li>
        <li>any private information of any third party, including addresses, phone numbers, email addresses, numbers,
          and/or features in the personal identity document (e.g., National Insurance numbers, passport numbers) or
          credit card numbers.</li>
        <li>any material which does or may infringe upon any copyright, trademark or other intellectual property or
          privacy rights of any other person.</li>
        <li>any material which is defamatory of any person, obscene, offensive, pornographic, hateful, or inflammatory.
        </li>
        <li>any material that would constitute, encourage, or provide instructions for a criminal offense, dangerous
          activities, or self-harm.</li>
        <li>any material that is deliberately designed to provoke or antagonize people, especially trolling and
          bullying, or is intended to harass, harm, hurt, scare, distress, embarrass or upset people.</li>
        <li>any material that contains a threat of any kind, including threats of physical violence.</li>
        <li>any material that is racist or discriminatory, including discrimination on the basis of someone’s race,
          religion, age, gender, disability or sexuality.</li>
        <li>any answers, responses, comments, opinions, analysis, or recommendations that you are not properly licensed
          or otherwise qualified to provide; or</li>
        <li>material that, in the sole judgment of YOUvME, is objectionable or which restricts or inhibits any other
          person from using the Services, or which may expose YOUvME, the Services or its users to any harm or liability
          of any type.</li>
      </ul>

      <p>We reserve the right, at any time and without prior notice, to remove or disable access to content at our
        discretion for any reason or no reason. Some of the reasons we may remove or disable access to content may
        include finding the content objectionable, in violation of these Terms or otherwise harmful to the Services or
        our users. Our automated systems analyze your content (including emails) to provide you personally relevant
        product features, such as customized search results, tailored advertising, and spam and malware detection. This
        analysis occurs as the content is sent, received, and when it is stored.</p>
      <p><strong>C. The permissions you give us</strong></p>
      <p>We need certain permissions from you to provide our services:</p>

      <ol type="a">
        <li>Permission to use content you create and share: Some content that you share or upload, such as photos or
          videos, may be protected by intellectual property laws.</li>
        <li>You own the intellectual property rights (rights like copyright or trademark) in any such content that you
          create and share on YOUvME. Nothing in these Terms takes away the rights you have to your own content. You are
          free to share your content with anyone else, wherever you want.</li>
        <li>However, to provide our services we need you to give us some legal permission (known as a ‘license’) to use
          this content. This is solely for the purposes of providing and improving our Products and services as
          described in Section 1 above.</li>
        <li>Specifically, when you share, post, or upload content that is covered by intellectual property rights on or
          in connection with our Products, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, and
          worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and
          create derivative works of your content (consistent with your privacy and application settings). This means,
          for example, that if you share a video on YOUvME, you give us permission to store, copy, and share it with
          others (again, consistent with your settings) such as service providers that support our service. This license
          will end when your content is deleted from our systems.</li>
        <li>You can delete content individually or all at once by deleting your account.</li>
        <li>When you delete content, it’s no longer visible to other users, however it may continue to exist elsewhere
          on our systems where: <ul>
            <li>
              immediate deletion is not possible due to technical limitations (in which case, your content will be
              deleted within a maximum of 90 days from when you delete it);
            </li>
            <li>your content has been used by others in accordance with this license and they have not deleted it (in
              which case this license will continue to apply until that content is deleted); or</li>
            <li>where immediate deletion would restrict our ability to: <ol type="i">
                <li>investigate or identify illegal activity or violations of our terms and policies (for example, to
                  identify or investigate misuse of our Products or systems);</li>
                <li>comply with a legal obligation, such as the preservation of evidence; or</li>
                <li>comply with a request of a judicial or administrative authority, law enforcement or a
                  government agency;</li>
              </ol>
            </li>
            <li>in which case, the content will be retained for no longer than is necessary for the purposes for which
              it has been retained (the exact duration will vary on a case-by-case basis).</li>
            <li>In each of the above cases, this license will continue until the content has been fully deleted.</li>
          </ul>
        </li>
        <li>Permission to use your name, profile picture, and information about your actions with ads and sponsored
          content: You give us permission to use your name and profile picture and information about actions you have
          taken on YOUvME next to or in connection with ads, offers, and other sponsored content that we display across
          our Products, without any compensation to you</li>
        <li>Permission to update software you use or download: If you download or use our software, you give us
          permission to download and install updates to the software where available.</li>
      </ol>

      <p><strong>D. Limits on using our intellectual property</strong></p>


      <p>If you use content covered by intellectual property rights that we have and make available in our Products (for
        example, images, designs, videos, or sounds we provide that you add to content you create or share), we retain
        all rights to that content (but not yours). You must obtain our written permission (or permission under an open
        source license) to modify, create derivative works of, decompile, or otherwise attempt to extract source code
        from us.</p>

      <p><strong>4. Content</strong></p>
      <p><strong>A. YOUvME Content</strong></p>
      <p>As between you and YOUvME, all content, software, images, text, graphics, illustrations, logos, patents,
        trademarks, service marks, copyrights, photographs, audio, videos, music on and “look and feel” of the Services,
        and all intellectual property rights related thereto (the “YOUvME Content”), are either owned or licensed by
        YOUvME, it being understood that you or your licensors will own any User Content you upload or transmit through
        the Services. Use of the YOUvME Content or materials on the Services for any purpose not expressly permitted by
        these Terms is strictly prohibited. Such content may not be downloaded, copied, reproduced, distributed,
        transmitted, broadcast, displayed, sold, licensed or otherwise exploited for any purpose whatsoever without our
        or, where applicable, our licensors’ prior written consent. We and our licensors reserve all rights not
        expressly granted in and to their content.</p>

      <p>You acknowledge and agree that we may generate revenues, increase goodwill or otherwise increase our value from
        your use of the Services, including, by way of example and not limitation, through the sale of advertising,
        sponsorships, promotions, usage data and Gifts, and except as specifically permitted by us in these Terms or in
        another agreement you enter into with us, you will have no right to share in any such revenue, goodwill or value
        whatsoever. You further acknowledge that, except as specifically permitted by us in these Terms or in another
        agreement you enter into with us, you (i) have no right to receive any income or other consideration from any
        User Content or your use of any musical works, sound recordings or audiovisual clips made available to you on or
        through the Services, including in any User Content created by you, and (ii) are prohibited from exercising any
        rights to monetize or obtain consideration from any User Content within the Services or on any third party
        service.</p>
      <p>Subject to the terms and conditions of the Terms, you are hereby granted a non-exclusive, limited,
        non-transferable, non-sublicensable, revocable, worldwide license to access and use the Services, including to
        download the Platform on a permitted device, and to access the YOUvME Content solely for your personal,
        non-commercial use through your use of the Services and solely in compliance with these Terms. YOUvME reserves
        all rights not expressly granted herein in the Services and the YOUvME Content. You acknowledge and agree that
        YOUvME may terminate this license at any time for any reason or no reason.</p>
      <p>NO RIGHTS ARE LICENSED WITH RESPECT TO SOUND RECORDINGS AND THE MUSICAL WORKS EMBODIED THEREIN THAT ARE MADE
        AVAILABLE FROM OR THROUGH THE SERVICE.</p>
      <p>You acknowledge and agree that when you view content provided by others on the Services, you are doing so at
        your own risk. The content on our Services is provided for general information only. It is not intended to
        amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or
        refraining from, any action on the basis of the content on our Services.</p>

      <p>We make no representations, warranties or guarantees, whether express or implied, that any YOUvME Content
        (including User Content) is accurate, complete or up to date. Where our Services contain links to other sites
        and resources provided by third parties, these links are provided for your information only. We have no control
        over the contents of those sites or resources. Such links should not be interpreted as approval by us of those
        linked websites or information you may obtain from them. You acknowledge that we have no obligation to
        pre-screen, monitor, review, or edit any content posted by you and other users on the Services (including User
        Content).</p>

      <p><strong>B. User-Generated Content</strong></p>
      <p>Users of the Services may be permitted to upload, post or transmit or otherwise make available content through
        the Services including, without limitation, any text, photographs, user videos, sound recordings and the musical
        works embodied therein, including videos that incorporate locally stored sound recordings from your personal
        music library and ambient noise (“User Content”). Users of the Services may also extract all or any portion of
        User Content created by another user to produce additional User Content, including collaborative User Content
        with other users, that combine and intersperse User Content generated by more than one user.</p>
      <p>Any User Content will be considered non-confidential and non-proprietary. You must not post any User Content on
        or through the Services or transmit to us any User Content that you consider to be confidential or proprietary.
        When you submit User Content through the Services, you agree and represent that you own that User Content, or
        you have received all necessary permissions, clearances from, or are authorized by, the owner of any part of the
        content to submit it to the Services, to transmit it from the Services to other third party platforms, and/or
        adopt any third party content.</p>
      <p>If you only own the rights in and to a sound recording, but not to the underlying musical works embodied in
        such sound recordings, then you must not post such sound recordings to the Services unless you have all
        permissions, clearances from, or are authorized by, the owner of any part of the content to submit it to the
        Services.</p>
      <p>You or the owner of your User Content still own the copyright in User Content sent to us, but by submitting
        User Content via the Services, you hereby grant us an unconditional irrevocable, non-exclusive, royalty-free,
        fully transferable, perpetual worldwide license to use, modify, adapt, reproduce, make derivative works of,
        publish and/or transmit, and/or distribute and to authorize other users of the Services and other third-parties
        to view, access, use, download, modify, adapt, reproduce, make derivative works of, publish and/or transmit your
        User Content in any format and on any platform, either now known or hereinafter invented.</p>
      <p>You further grant us a royalty-free license to use your username, image, voice, and likeness to identify you as
        the source of any of your User Content; provided, however, that your ability to provide an image, voice, and
        likeness may be subject to limitations due to age restrictions.</p>
      <p>For the avoidance of doubt, the rights granted in the preceding paragraphs of this Section include, but are not
        limited to, the right to reproduce sound recordings (and make mechanical reproductions of the musical works
        embodied in such sound recordings), and publicly perform and communicate to the public sound recordings (and the
        musical works embodied therein), all on a royalty-free basis. This means that you are granting us the right to
        use your User Content without the obligation to pay royalties to any third party, including, but not limited to,
        a sound recording copyright owner (e.g., a record label), a musical work copyright owner (e.g., a music
        publisher), a performing rights organization (e.g., ASCAP, BMI, SESAC, etc.) (a “PRO”), a sound recording PRO
        (e.g., SoundExchange), any unions or guilds, and engineers, producers or other royalty participants involved in
        the creation of User Content.</p>
      <p><strong>C. Specific Rules for Musical Works and for Recording Artists.</strong></p>
      <p>If you are a composer or author of a musical work and are affiliated with a PRO, then you must notify your PRO
        of the royalty-free license you grant through these Terms in your User Content to us. You are solely responsible
        for ensuring your compliance with the relevant PRO’s reporting obligations. If you have assigned your rights to
        a music publisher, then you must obtain the consent of such music publisher to grant the royalty-free license(s)
        set forth in these Terms in your User Content or have such music publisher enter into these Terms with us. Just
        because you authored a musical work (e.g., wrote a song) does not mean you have the right to grant us the
        licenses in these Terms. If you are a recording artist under contract with a record label, then you are solely
        responsible for ensuring that your use of the Services is in compliance with any contractual obligations you may
        have to your record label, including if you create any new recordings through the Services that may be claimed
        by your label.</p>
      <p><strong>D. Through-To-The-Audience Rights.</strong></p>

      <p>All of the rights you grant in your User Content in these Terms are provided on a through-to-the-audience
        basis, meaning the owners or operators of third-party services will not have any separate liability to you or
        any other third party for User Content posted or used on such third party service via the Services.</p>
      <p><strong>E. Waiver of Rights to User Content.</strong></p>

      <p>By posting User Content to or through the Services, you waive any rights to prior inspection or approval of any
        marketing or promotional materials related to such User Content. You also waive any and all rights of privacy,
        publicity, or any other rights of a similar nature in connection with your User Content, or any portion thereof.
        To the extent any moral rights are not transferable or assignable, you hereby waive and agree never to assert
        any and all moral rights, or to support, maintain or permit any action based on any moral rights that you may
        have in or with respect to any User Content you Post to or through the Services.</p>

      <p>We also have the right to disclose your identity to any third party who is claiming that any User Content
        posted or uploaded by you to our Services constitutes a violation of their intellectual property rights, or of
        their right to privacy.</p>

      <p>We, or authorized third parties, reserve the right to cut, crop, edit or refuse to publish your content at our
        or their sole discretion. We have the right to remove, disallow, block or delete any posting you make on our
        Services if, in our opinion, your post does not comply with the Use of Our Services. In addition, we have the
        right – but not the obligation – in our sole discretion to remove, disallow, block or delete any User Content
        (i) that we consider violating these Terms, or (ii) in response to complaints from other users or third parties,
        with or without notice and without any liability to you. As a result, we recommend that you save copies of any
        User Content that you post to the Services on your personal device(s) in the event that you want to ensure that
        you have permanent access to copies of such User Content. We do not guarantee the accuracy, integrity,
        appropriateness or quality of any User Content, and under no circumstances will we be liable in any way for any
        User Content.</p>

      <p>You control whether your User Content is made publicly available on the Services to all other users of the
        Services or only available to people you approve. To restrict access to your User Content, you should select the
        privacy setting available within the Platform.</p>

      <p>We accept no liability in respect of any content submitted by users and published by us or by authorized third
        parties.</p>

      <p>YOUvME takes reasonable measures to expeditiously remove from our Services any infringing material that we
        become aware of. It is YOUvME’s policy, in appropriate circumstances and at its discretion, to disable or
        terminate the accounts of users of the Services who infringe upon copyrights or intellectual property rights of
        others.</p>

      <p>While our own staff is continually working to develop and evaluate our own product ideas and features, we pride
        ourselves on paying close attention to the interests, feedback, comments, and suggestions we receive from the
        user community. If you choose to contribute by sending us or our employees any ideas for products, services,
        features, modifications, enhancements, content, refinements, technologies, content offerings (such as audio,
        visual, games, or other types of content), promotions, strategies, or product/feature names, or any related
        documentation, artwork, computer code, diagrams, or other materials (collectively “Feedback”), then regardless
        of what your accompanying communication may say, the following terms will apply, so that future
        misunderstandings can be avoided. Accordingly, by sending Feedback to us, you agree that:</p>

      <p>YOUvME has no obligation to review, consider, or implement your Feedback, or to return to you all or part of
        any Feedback for any reason.</p>

      <p>Feedback is provided on a non-confidential basis, and we are not under any obligation to keep any Feedback you
        send confidential or to refrain from using or disclosing it in any way; and</p>

      <p>You irrevocably grant us perpetual and unlimited permission to reproduce, distribute, create derivative works
        of, modify, publicly perform (including on a through-to-the-audience basis), communicate to the public, make
        available, publicly display, and otherwise use and exploit the Feedback and derivatives thereof for any purpose
        and without restriction, free of charge and without attribution of any kind, including by making, using,
        selling, offering for sale, importing, and promoting commercial products and services that incorporate or embody
        Feedback, whether in whole or in part, and whether as provided or as modified.</p>

      <p><strong>5. Changes to the Terms</strong></p>

      <p>We amend these Terms from time to time, for instance when we update the functionality of our Services, when we
        combine multiple apps or services operated by us or our affiliates into a single combined service or app, or
        when there are regulatory changes. We will use commercially reasonable efforts to generally notify all users of
        any material changes to these Terms, such as through a notice on our Platform, however, you should look at the
        Terms regularly to check for such changes. We will also update the “Last Updated” date at the top of these
        Terms, which reflect the effective date of such Terms. Your continued access or use of the Services after the
        date of the new Terms constitutes your acceptance of the new Terms. If you do not agree to the new Terms, you
        must stop accessing or using the Services.
      </p>
      <p><strong>6. Your Account with Us</strong></p>
      <p>To access or use some of our Services, you must create an account with us. When you create this account, you
        must provide accurate and up-to-date information. It is important that you maintain and promptly update your
        details and any other information you provide to us, to keep such information current and complete.</p>
      <p>It is important that you keep your account password confidential and that you do not disclose it to any third
        party. If you know or suspect that any third party knows your password or has accessed your account, you must
        notify us immediately.</p>
      <p>You agree that you are solely responsible (to us and to others) for the activity that occurs under your
        account.</p>
      <p>We reserve the right to disable your user account at any time, including if you have failed to comply with any
        of the provisions of these Terms, or if activities occur on your account which, in our sole discretion, would or
        might cause damage to or impair the Services or infringe upon or violate any third-party rights, or violate any
        applicable laws or regulations.</p>
      <p>If you no longer want to use our Services again, you may delete your account. Once you choose to delete your
        account, you will not be able to reactivate your account or retrieve any of the content or information you have
        added.</p>
      <p><strong>7. Account suspension or termination</strong></p>
      <p>We want YOUvME to be a place where people feel welcome and safe to express themselves and share their thoughts
        and ideas.</p>
      <p>If we determine that you have clearly, seriously or repeatedly breached our Terms or Policies, we may suspend
        or permanently disable access to your account. We may also suspend or disable your account if you repeatedly
        infringe upon other people’s intellectual property rights or where we are required to do so for legal reasons.
      </p>
      <p>Where we take such action, we will let you know and explain any options you have to request a review, unless
        doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with
        the integrity or operation of any of our services, systems or Products; or where we are restricted due to
        technical limitations; or where we are prohibited from doing so for legal reasons.</p>

      <p><strong>8. Other Legal Terms</strong></p>
      <p><strong>A. Warranty</strong></p>
      <p>We provide the Service with reasonable care and skill.</p>
      <p><strong>B. Disclaimer</strong></p>
      <p>By law, consumers have certain rights that cannot be excluded or altered by a contract. Nothing in this
        Agreement affects those rights you may have as a consumer. Other than as expressly stated in this Agreement or
        as required by law, YOUvME does not make any specific promises about the Service. For example, we don’t make any
        promises about: the Content provided through the Service; the specific features of the Service, or its accuracy,
        reliability, availability, or ability to meet your needs; or that any Content you submit will be accessible or
        stored on the Service.</p>
      <p><strong>C. Limitation of Liability</strong></p>
      <p>All users: Nothing in this Agreement is intended to exclude or limit any party’s liability for: death or
        personal injury; fraud; fraudulent misrepresentation; or any liability that cannot be excluded or limited by
        law.</p>
      <p>To the extent permitted by applicable law, YOUvME and its Affiliates will not be responsible for:</p>

      <ul>
        <li>losses that were not caused by YOUvME or its Affiliates’ breach of this Agreement.
        </li>
        <li>any loss or damage that was not, at the time that this Agreement was formed between you and YOUvME, a
          reasonably foreseeable consequence of YOUvME or its Affiliates breaching this Agreement; or</li>
        <li>the Content submitted by any user, or for the defamatory, offensive, or illegal conduct of any user.</li>
      </ul>

      <p>Business Users only: If you are using the Service for the purpose of your trade, business, craft or profession,
        (a “Business User”), to the extent permitted by applicable law, the following limitations of liability will also
        apply:</p>

      <ul>
        <li>YOUvME and its Affiliates will not be responsible for lost profits, revenues, or data; loss of opportunity
          or anticipated savings; indirect or consequential losses, or punitive damages (in all cases whether such
          losses were foreseeable or not); and
        </li>
        <li>YOUvME and its Affiliates’ total liability for any claims arising from or relating to the Service is limited
          to the greater of: (a) the amount of revenue that YOUvME has paid to you from your use of the Service in the
          12 months before the date of your notice, in writing to YOUvME, of the claim or (b) $1, whichever is higher.
        </li>
      </ul>
      <p><strong>D. Third-Party Links</strong></p>

      <p>The Service may contain links to third-party websites and online services that are not owned or controlled by
        YOUvME. YOUvME has no control over, and assumes no responsibility for, such websites and online services. Be
        aware when you leave the Service; we suggest you read the terms and privacy policy of each third-party website
        and online service that you visit.</p>

      <p><strong>9. About this Agreement</strong></p>

      <p><strong>A. Changing this Agreement</strong></p>

      <p>We may change this Agreement to reflect changes to our Service or how we do business - for example,
        when we add new products or features or remove old ones, (2) for legal, regulatory, or security reasons, or
        (3) to prevent abuse or harm.</p>

      <p>If we materially change this Agreement, we’ll provide you with advance notice and the opportunity to review the
        changes, except (1) when we launch a new product or feature, or (2) in urgent situations, such as preventing
        ongoing abuse or responding to legal requirements. If you don’t agree to the new terms, you should remove any
        Content you uploaded and stop using the Service.</p>
      <p><strong>B. Severance</strong></p>

      <p>If it turns out that a particular term of this Agreement is not enforceable for any reason, this will not
        affect any other terms.</p>


      <p><strong>C. Assignment</strong></p>
      <p>YOUvME may assign or sell part or all of this Agreement to an Affiliate or any other legal entity including an
        unrelated third party.
      </p>

      <p><strong>D. No Waiver</strong></p>

      <p>If you do not comply with this Agreement and we do not take action immediately, this doesn’t mean that we are
        giving up any rights that we may have (such as the right to take action in the future).</p>

      <p><strong>E. Governing Law</strong></p>


      <p>This Agreement, and your relationship with YOUvME under this Agreement, will be governed by the laws of the
        State of California, and legal disputes may be brought in the courts of the State of California.</p>

    </div>
    <Footer />
  </div>
</template>

<script>
import TopBanner from "@/components/landing/TopBanner.vue";
import mediaCheckerMixins from "@/helpers/media-checker";
import FixedHeader from 'vue-fixed-header'
import Footer from '@/components/landing/Footer.vue';

export default {
  metaInfo() {
    return {
      title: "Terms"
    };
  },
  mixins: [mediaCheckerMixins],
  components: {
    TopBanner,
    FixedHeader,
    Footer
  },
  name: "Terms",
  data() {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      loadingBtn: false,
      modalVisible: false,
      form: {
        name: "",
        email: ""
      },
      visible: false,
      menuIcon: "menu",
      copyrightYear: new Date().getFullYear(),
      rules: {
        name: [
          {
            required: true,
            message: "Your name cannot be empty",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change"
          },
          {
            pattern: /\S+@\S+\.\S+/,
            message: "Please type in a valid email address"
          }
        ]
      }
    };
  },
  methods: {
    showDrawer() {
      this.visible = true;
      this.menuIcon = "menu-fold";
    },
    onClose() {
      this.visible = false;
      this.menuIcon = "menu-unfold";
    },
    showModal() {
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixins.less";
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

#youvme-lading-page {
  font-family: "Gilroy";
}

.row-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_link_ft {
  color: #715fa6;
}

.ld_header_wrap {
  // .header__landing--bg();
  background-color: #201347;

  .ld_header.vue-fixed-header--isFixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    background: #2f205b;
    z-index: 99;
  }

  .ld_header {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 30px 20px 30px;

    .youvme_logo {
      max-width: 120px;
    }

    .header_content {
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: center;

      .header_title {
        .gilroy-bold(3.5em, #fff);
      }

      .header_username {
        .gilroy-bold(1.2em, #8c8bbc);
      }

      .header_description {
        .gilroy-bold(1.2em, #fff);
      }
    }
  }
}

.ld_content {
  padding-top: 50px;
  padding-bottom: 80px;

  .ld_form {
    display: flex;
    justify-content: center;
  }

  .landing_page_form {
    min-width: 300px;
  }

  .landing_page_form .form-label {
    color: #2f205b !important;
    font-weight: bold;
  }

  .appstore-box {
    padding-top: 70px;
    display: flex;
    justify-content: center;
    text-align: center;

    p {
      color: #2f205b;
      font-weight: bold;
      .gilroy-bold(1.2em, #2f205b);
    }
  }
}

.ld_footer {
  .logo_ft {
    .youvme_logo {
      max-width: 120px;
    }

    padding: 30px 90px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #291b54;

    #contact_us-button {
      background: #322360;
      border: none;
      padding: 0px 40px;
      height: 40px;
      .gilroy-bold(1em, #fff);
    }
  }

  .link_ft {
    padding: 30px 90px;
    background: #2f205c;

    .nav_ft {
      display: flex;
      padding-top: 8px;

      .page_link_ft {
        .gilroy(1em, #715fa6);
        padding: 0px 30px;
      }

      .page_link_ft:nth-child(1) {
        padding-left: 0px !important;
      }
    }

    .social_icon_ft {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .copyright-wraper {
      padding-top: 30px;
      display: flex;
      justify-content: flex-start;

      .copyright {
        padding-right: 50px;
        .gilroy(1em, #4c3c7d);

        a {
          .gilroy(1em, #4c3c7d);
        }
      }
    }
  }
}

.menu-toggle {
  position: absolute;
  right: 0px;
  z-index: 999;
  min-width: 200px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.desktop-menu {
  display: none;
}

.menu-mobile-btn {
  display: block;
}

.btn-get-the-app {
  font-size: 0.8rem;
  color: white;
  background: #00b7e8;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .ld_header {
    padding-left: 0;
    padding-right: 0;
  }

  .menu-mobile-btn {
    display: none;
  }

  .desktop-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    align-items: center;

    li {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: #715fa6;
    }
  }
}

.ant-drawer {
  z-index: 999;
}

@media (min-width: 1220px) {
  .desktop-menu {
    li {
      margin-left: 40px;
    }
  }
}

.browse-challenges-wrapper {
  padding: 40px 24px;

  h2 {
    font-size: 32px;
    color: #2f205b;
    line-height: 1.2;
    font-weight: 800;
    margin: 0 0 40px;
  }
}

@media screen and (min-width: 768px) {
  .browse-challenges-wrapper {
    padding: 110px 24px;

    h2 {
      font-size: 60px;
      margin: 0 0 32px;
    }
  }
}

@import "../../styles/responsive.less";
</style>