<template>
  <div>
    <a-row type="flex" justify="space-between" class="header-container">
      <a-col :span="12">
        <div class="header__headline--font">Settings</div>
        <div class="header__description--font">
          YOUvME settings
        </div>
      </a-col>
      <a-col>
        <a-button
          :loading="settingSaving"
          class="button__state--secondary"
          @click="onChangeSaved"
          >Save Changes</a-button
        >
      </a-col>
    </a-row>
    <a-spin :spinning="settingLoading"></a-spin>
    <div class="settings__container" v-if="!settingLoading">
      <div class="settings__container__section">Notification Settings</div>
      <notification-settings></notification-settings>
      <a-divider />
      <div class="settings__container__section">Linked Accounts</div>
      <linked-account></linked-account>
      <a-divider />
      <div class="settings__container__section">Flag Controls</div>
      <flags-control></flags-control>
      <a-divider />
      <div class="settings__container__section">Comments Controls</div>
      <comments-control></comments-control>
      <a-divider />
      <div class="settings__container__section">Challenge Durations</div>
      <challenge-duration></challenge-duration>
    </div>
  </div>
</template>

<script>
import LinkedAccount from "../components/settings-screen/LinkedAccount.vue";
import NotificationSettings from "../components/settings-screen/NotificationSettings.vue";
import FlagsControl from "../components/settings-screen/FlagsControl.vue";
import ChallengeDuration from "../components/settings-screen/ChallengeDuration.vue";
import CommentsControl from "../components/settings-screen/CommentsControl.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Settings"
    };
  },
  name: "Settings",
  components: {
    NotificationSettings,
    LinkedAccount,
    FlagsControl,
    ChallengeDuration,
    CommentsControl
  },
  data() {
    return {
      changeSaved: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["settings", "settingLoading", "settingSaving"])
  },
  methods: {
    ...mapActions({
      fetchSettings: "FETCH_SETTINGS",
      saveChanges: "SAVE_CHANGES"
    }),
    onChangeSaved() {
      this.saveChanges();
    }
  },
  created() {
    this.fetchSettings();
  }
};
</script>

<style lang="less" scoped>
@import "../styles/fonts.less";
@import "../styles/colors.less";
@import "../styles/mixins.less";

.button__state--secondary {
  .secondary-button(150px);
}

.settings__container {
  background-color: @white;
  padding: 30px 30px;
  border-radius: 4px;
  margin-bottom: 120px;
}

.ant-divider {
  margin: 40px 0px;
  height: 0.5px;
}

.settings__container__section {
  .poppins-medium(16px, @main-dark);
  margin-bottom: 30px;
}
</style>
