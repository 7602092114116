import { request } from "../helpers";

export const fetchUser = async params =>
  request({
    method: "GET",
    url: "/users",
    params: params
  });

export const getUser = async userId =>
  request({
    method: "GET",
    url: `/users/${userId}`
  });

export const createUser = async ({ payload }) =>
  request({
    method: "POST",
    url: "/users",
    data: payload,
    headers: {
      Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data"
    }
  });

export const updateUser = async (userId, payload) =>
  request({
    method: "PUT",
    url: `/users/${userId}`,
    data: payload,
    headers: {
      Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data"
    }
  });

export const patchUser = async (userId, payload) =>
  request({
    method: "PUT",
    url: `/users/${userId}`,
    data: payload
  });

export const deleteUser = async userId =>
  request({
    method: "DELETE",
    url: `/users/${userId}`
  });

export const searchUser = async params =>
  request({
    method: "GET",
    url: "/users",
    params
  });

export const changePassword = async data =>
  request({
    method: "PUT",
    url: "/update-password-by-admin",
    data
  });

export const fetchBlockUser = async params =>
  request({
    method: "GET",
    url: "/users/block",
    params
  });