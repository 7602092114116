<template>
  <base-icon :viewBox="'0 0 16 16'">
    <template v-slot:icon-name>
      <path
        class="st0"
        :class="{ active: active }"
        d="M6.5,1.2v6.9H1.2V1.2H6.5z M6.5,11.2v3.6H1.2v-3.6H6.5z M14.8,7.9v6.9H9.5V7.9H14.8z M14.8,4.8H9.5V1.2h5.3V4.8
	z"
      />
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "../base/BaseIcon.vue";
export default {
  name: "Users",
  props: ["active"],
  components: {
    BaseIcon
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";

.st0 {
  fill: none;
  stroke: #454176;
  stroke-width: 1.4;
}

.active {
  stroke: @blue;
}
</style>
