<template>
  <div id="youvme-lading-page">
    <div class="ld_header_wrap">
      <fixed-header :threshold="100">
        <div class="ld_header">
          <div class="container landing-container">
            <div class="row-header-top">
              <router-link to="/">
                <img class="youvme_logo" alt="Youvme" :src="require('../../assets/images/youvme.svg')" />
              </router-link>
              <a-button class="menu-mobile-btn" @click="showDrawer" :icon="menuIcon" />
              <ul class="desktop-menu">
                <li><a class="page_link_ft" href="/#browse-challenge">How does it Work?</a></li>
                <li><a class="page_link_ft" href="/#get-app">Creating a Challenge</a></li>
                <li><a class="page_link_ft" href="/#joining-the-challenge">App Features</a></li>
                <li><a class="page_link_ft" href="/#what-you-can-find">Browse Categories</a></li>
                <li><button class="btn-get-the-app" v-scroll-to="'#download-application'">Get the APP</button></li>
              </ul>
              <a-drawer class="menu-mobile-wapper" placement="left" :closable="false" :visible="visible"
                @close="onClose">
                <div class="nav_ft">
                  <a class="page_link_ft" href="/#browse-challenge">How does it Work?</a>
                  <a class="page_link_ft" href="/#get-app">Creating a Challenge</a>
                  <a class="page_link_ft" href="/#joining-the-challenge">App Features</a>
                  <a class="page_link_ft" href="/#what-you-can-find">Browse Categories</a>
                  <button class="btn-get-the-app" v-scroll-to="'#download-application'">Get the APP</button>
                </div>
              </a-drawer>
            </div>
          </div>
        </div>
      </fixed-header>
      <top-banner />
    </div>


    <div class="browse-challenges-wrapper landing-container">
      <h2>Community Guidelines</h2>


      <p><strong>Last updated July 15, 2022</strong></p>

      <p><strong>1. Overview</strong></p>

      <p>Community Guidelines ("Guidelines") are a legal agreement between Users (“you” or "your”) and Wired Avenue LLC,
        with its registered address at 611 W G S.t, San Diego, CA 92101 (also referred to as “Company”, “we”, “us”, or
        “our”). We implore you to read these guidelines carefully before using the platform. If you do not agree to
        these guidelines, please do not use the platform.</p>

      <p>YOUvME is the ultimate challenge app. Compete with your friends to find out who's the best! From singing,
        dancing, or settling a dispute - the challenge is yours to discover. We are committed to maintaining a
        supportive environment for our growing community. Our Community Guidelines establish a set of norms and a common
        code of conduct that provide for a safe and welcoming space for the users. We will remove any content –
        including videos, audio, photos, comments, links, or other text – that violates our Community Guidelines.
        Individuals are notified of our decisions and can appeal them if they believe no violation has occurred. YOUvME
        will temporarily or permanently ban accounts and/or users that are involved in severe or repeated on-platform
        violations. Circumstances that involve any instance of a threat of real-world harm to human life that is
        specific, credible, and imminent may be reported to law enforcement authorities. The full YOUvME experience is
        for people 18 and older, and if you are under age 18, you may only use the Services with the consent of your
        parent or legal guardian. Please be sure your parent or legal guardian has reviewed and discussed these
        guidelines with you.</p>

      <p>In consultation with relevant stakeholders, we update our Community Guidelines from time to time to evolve
        alongside new behaviors and risks, as part of our commitment to keeping YOUvME a safe place for creativity and
        joy.</p>

      <p><strong>2. Dangerous acts and challenges</strong></p>


      <p>We do not permit users to share content depicting, promoting, or glorifying dangerous acts that may lead to
        serious injury or death. We also do not allow content that promotes or endorses collective participation in
        dangerous or harmful activities that violate any aspect of our Community Guidelines.</p>


      <p>We define dangerous acts or other dangerous behavior as activities conducted in a non-professional context or
        without the necessary skills and safety precautions that may lead to serious injury or death for the user or the
        public. This includes amateur stunts or dangerous challenges.
      </p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that shows the potentially inappropriate use of dangerous tools or objects.</li>
        <li>Content that depicts dangerous driving behavior.</li>
        <li>Content that depicts or promotes ingesting substances that are not meant for consumption and could lead to
          severe harm.</li>
        <li>Content that describes or provides instructional detail on how to perform a dangerous activity.</li>

        <li>Dangerous games, dare challenges, or stunts that might lead to injury or property damage.</li>
      </ul>

      <p><strong>3. Suicide, self-harm, and disordered eating</strong></p>


      <p>We care deeply about the health and well-being of the individuals who use our app. We do not allow content
        depicting, promoting, or glorifying activities that could lead to suicide, self-harm, or disordered eating.
        However, we do support members of our community in sharing their personal experiences with these issues in a
        safe way to raise awareness and find community support. We also encourage individuals who are struggling with
        thoughts of suicide or self-harm, or who know someone is seriously considering suicide, to immediately contact
        local emergency services (United States: 1-800-273-TALK (8255)). If our intervention could help a user who may
        be at risk of harming themselves, YOUvME may also alert local emergency services.</p>

      <p><strong>A. Suicide and self-harm</strong></p>

      <p>We remove content that depicts suicide, involves suicidal ideation, or that might encourage suicidal or
        self-injurious behavior. We also remove content that depicts attempted suicide or behavior likely to lead to
        self-inflicted death. We prohibit any form of content that promotes or glorifies suicide, provides instructions
        for suicide, or posts that portray suicide as heroic or honourable. Circumstances that involve any instance of a
        threat of real-world harm to human life that is specific, credible, and imminent may be reported to law
        enforcement authorities.</p>


      <p>Content that encourages or promotes suicide or self-harm hoaxes is also not allowed. This includes alarming
        warnings that could cause panic and widespread harm. We will remove such warnings while allowing content that
        seeks to dispel panic and promote accurate information about such hoaxes.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that depicts, promotes, or glorifies suicide or self-harm.</li>
        <li>Content that provides instructions for suicide or how to engage in self-harm.</li>
        <li>Suicide or self-harm games, dares, challenges, pacts, or hoaxes.
          (without our permission).</li>
      </ul>

      <p><strong>B. Disordered eating</strong></p>

      <p>Content that promotes unhealthy eating behaviors or habits that are likely to cause adverse health outcomes is
        not allowed on the platform. This includes content expressing a desire for an eating disorder, sharing tips or
        coaching on disordered eating, and participation in unhealthy body measurement challenges.</p>
      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that depicts, promotes, or glorifies disordered eating.</li>
        <li>Content that depicts, promotes, or glorifies any dangerous weight loss behaviors associated with disordered
          eating.</li>
      </ul>

      <p><strong>4. Adult nudity and sexual activities</strong></p>

      <p>We strive to create a platform that feels welcoming and safe. We do not allow nudity, pornography, or sexually
        explicit content on YOUvME. We also prohibit content depicting or supporting non-consensual sexual acts, the
        sharing of non-consensual intimate imagery, and adult sexual solicitation.</p>
      <p><strong>A. Sexual exploitation</strong></p>
      <p>Sexual exploitation is defined as any actual or attempted abuse of a position of vulnerability, power, or trust
        for sexual purposes, including profiting monetarily, socially, or politically from the sexual exploitation of
        another. We do not permit sexually exploitative content.</p>
      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that depicts, solicits, promotes, or glorifies non-consensual sexual acts or non-consensual
          touching, including rape and sexual assault.</li>
        <li>Content that depicts, solicits, promotes, or glorifies the sharing of non-consensual intimate imagery,
          including sexual images that are taken, created, or shared without consent.</li>
        <li>Content that depicts, promotes, or glorifies sexual violence.</li>
        <li>Content that depicts, promotes or glorifies sexual solicitation, including offering or asking for sexual
          partners, sexual chats or imagery, sexual services, and premium sexual content.</li>
      </ul>

      <p><strong>B. Nudity and sexual activity involving adults</strong></p>


      <p>Nudity and sexual activity include content that is overtly revealing of breasts, genitals, anus, or buttocks or
        behaviors that mimic, imply, or display sex acts. We do not allow depictions, including digitally created or
        manipulated content, of nudity or sexual activity. We are mindful that some content may be offensive or
        culturally inappropriate in certain regions or may not be suitable for users of all ages.</p>
      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Clips extracted from non-pornographic films, shows, or other content to isolate sexual content (real world
          or artistic).</li>
        <li>Content that explicitly or implicitly depicts sexual activities including penetrative and non-penetrative
          sex, oral sex, or erotic kissing.</li>
        <li>Content that depicts sexual arousal or sexual stimulation.</li>
        <li>Content that depicts a sexual fetish.</li>
        <li>Content that depicts genitals, buttocks, the pubic region, or female nipples.</li>
        <li>Content that contains sexually explicit language for sexual gratification.</li>
      </ul>

      <p><strong>5. Bullying and harassment</strong></p>

      <p>We believe in an inclusive community and individual expression without fear of abuse. We do not tolerate
        members of YOUvME community being shamed, bullied, or harassed. Abusive content or behavior can cause severe
        psychological distress and will be removed from our platform.</p>

      <p><strong>A. Abusive behavior</strong></p>
      <p>We remove expressions of abuse, including threats or degrading statements intended to mock, humiliate,
        embarrass, intimidate, or hurt an individual. This prohibition extends to the use of YOUvME features. To enable
        expression about matters of public interest, critical comments of public figures may be allowed; however,
        serious abusive behavior against public figures is prohibited.</p>
      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that insults another individual, or disparages an individual based on attributes such as intellect,
          appearance, personality traits, or hygiene.</li>
        <li>Content that encourages coordinated harassment.</li>
        <li>Content that disparages victims of violent tragedies.</li>
        <li>Content that depicts willful harm or intimidation, such as cyberstalking or trolling.</li>
        <li>Content that wishes death, serious disease, or other serious harm on an individual.</li>
      </ul>

      <p><strong>B. Sexual harassment</strong></p>


      <p>Sexual harassment involves unwanted or inappropriate sexual behavior directed at another individual. We do not
        allow content that glorifies, or promotes sexual harassment, regardless of the user's intent.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>
      <ul>
        <li>Content that attempts to make unwanted sexual contact.</li>
        <li>Content that disparages another person’s sexual activity.</li>
        <li>Content that stimulates sexual activity with another person, either verbally, in a text (including emojis),
          or through the use of any in-app features.</li>
        <li>Content that alters or morphs an image of another individual to portray or imply sexual suggestiveness or
          engagement in sexual activity.</li>
        <li>Content that reveals, or threatens to reveal, details of a person's private sexual life, including digital
          content, sexual history, and names of previous sexual partners.</li>
        <li>Content that exposes, or threatens to expose, a person's sexual orientation without their consent.</li>
      </ul>

      <p><strong>C. Threats of hacking, doxing, and blackmail</strong></p>
      <p>Threatening to hack or dox with an intent to harass or blackmail another individual can cause serious emotional
        distress and other offline harm. We define doxing as the act of collecting and publishing personal data or
        personally identifiable information (PII) for malicious purposes. We consider these online behaviors as forms of
        abuse and do not allow them on YOUvME.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that threatens to reveal personal data or personally identifiable information (PII), including
          residential address, private email address, private phone number, bank statement, social security number, or
          passport number.</li>
        <li>Content that threatens to blackmail an individual or hack an individual's account.</li>
        <li>Content that incites or encourages others to hack or reveal another person's account, personal data, or
          personally identifiable information (PII).</li>
        <li>An individual's account, personal data, or personally identifiable information to encourage others to abuse,
          troll, or harass that individual.</li>
      </ul>

      <p><strong>6. Hateful behavior</strong></p>

      <p>YOUvME is a diverse and inclusive community that has no tolerance for discrimination. We do not permit content
        that contains hate speech or involves hateful behavior, and we remove it from our platform. We ban accounts
        and/or users that engage in severe or multiple hate speech violations or that are associated with hate speech
        off the YOUvME platform.</p>

      <p><strong>A. Attacks and slurs based on protected attributes</strong></p>

      <ol type="i">
        <li>Race</li>
        <li>Ethnicity</li>
        <li>National origin</li>
        <li>Religion</li>
        <li>Caste</li>
        <li>Sexual orientation</li>
        <li>Sex</li>
        <li>Gender</li>
        <li>Gender identity</li>
        <li>Serious disease</li>
        <li>Disability</li>
        <li>Immigration status</li>
      </ol>


      <p>Slurs are defined as derogatory terms that are intended to disparage groups or individuals based on any
        protected attributes listed above. To minimize the spread of egregiously offensive terms, we remove all slurs
        from our platform, unless the terms are reappropriated, used self-referentially (i.e., by members of the
        protected group), or used in a way that does not disparage (e.g., educational context).</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content claiming individuals or groups with protected attributes are physically, mentally, or morally
          inferior or referring to them as criminals, animals, inanimate objects, or other non-human entities. </li>
        <li>Content promoting or justifying violence, exclusion, segregation, or discrimination against them.</li>
        <li>Content that includes the use of non-self-referential slurs.</li>
        <li>Content that targets transgender or non-binary individuals through misgendering or deadnaming.</li>
        <li>Content that depicts harm inflicted upon an individual or a group based on a protected attribute.</li>
      </ul>

      <p><strong>B. Hateful ideology</strong></p>

      <p>Hateful ideologies are those that demonstrate clear hostility toward people because of their protected
        attributes. Hateful ideologies are incompatible with the inclusive and supportive community that our platform
        provides and we remove content that promotes them.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that praises, promotes, glorifies, or supports any hateful ideology (e.g., white supremacy,
          misogyny, anti-LGBTQ, antisemitism).</li>
        <li>Content that contains names, symbols, logos, flags, slogans, uniforms, gestures, salutes, illustrations,
          portraits, songs, music, lyrics, or other objects related to a hateful ideology.</li>
        <li>Content that promotes, supports, or advertises conversion therapy or related program.</li>
        <li>Content that denies well-documented and violent events have taken place affecting groups with protected
          attributes (e.g., Holocaust denial).</li>
        <li>Claims of supremacy over a group of people concerning other protected attributes.</li>
        <li>Conspiracy theories used to justify hateful ideologies.</li>
      </ul>

      <p><strong>7. Violent extremism</strong></p>

      <p>We take a firm stance against enabling violence on or off YOUvME. We do not allow people to use YOUvME to
        threaten or incite violence, or to promote violent extremist organizations, individuals, or acts. When there is
        a threat to public safety or an account is used to promote or glorify off-platform violence, we ban the account.
        When warranted, we will report threats to relevant legal authorities. To effectively protect our community, we
        may consider off-platform behavior to identify violent extremist organizations and individuals on our platform.
        If we find such organizations or individuals, we will ban their accounts.</p>
      <p><strong>A. Threats and incitement to violence</strong></p>

      <p>We consider incitement to violence as advocating for, directing, or encouraging other people to commit
        violence. We do not allow threats of violence or incitement to violence on our platform that may result in
        serious physical harm.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>


      <ul>
        <li>Statements of intent to inflict physical injuries on an individual or a group.</li>
        <li>Statements or imagery that encourage others to commit or that advocate for violence.</li>
        <li>Conditional or aspirational statements that encourage other people to commit violence.</li>
        <li>Calls to bring weapons to a location with the intent to intimidate or threaten an individual or group with
          violence.</li>
        <li>Instructions on how to make or use weapons that may incite violence.</li>
      </ul>
      <p><strong>B. Violent extremist organizations and individuals</strong></p>


      <p>We do not allow organizations or individuals on our platform who promote or engage in violence, including
        terrorist organizations, organized hate groups, criminal organizations, and other non-state armed groups that
        target civilians.</p>

      <p><i>i. Terrorist organizations</i></p>

      <p>Terrorists and terrorist organizations are non-state actors that threaten violence, use violence, and/or commit
        serious crimes (such as crimes against humanity) against civilian populations in pursuit of political,
        religious, ethnic, or ideological objectives.</p>
      <p><i>ii. Organized hate groups</i></p>

      <p>We use the term “organized hate” to refer to those individuals and organizations who attack people based on
        protected attributes, such as race, ethnicity, national origin, religion, caste, sexual orientation, sex,
        gender, gender identity, or immigration status. We consider attacks to include actions that incite violence or
        hatred, that aim to dehumanize individuals or groups, or that embrace a hateful ideology.</p>
      <p><i>iii. Criminal organizations</i></p>

      <p>Criminal organizations are transnational, national, or local groups that have engaged in serious crimes,
        including violent crimes (e.g., homicide, rape, robbery, assault), trafficking (e.g., human, organ, drug,
        weapons), kidnapping, financial crimes (e.g., extortion, blackmail, fraud, money laundering), or cybercrime.</p>


      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that praises, promotes, glorifies, or supports violent acts or extremist organizations or
          individuals.</li>
        <li>Content that encourages participation in, or intends to recruit individuals to, violent extremist
          organizations.</li>
        <li>Content with names, symbols, logos, flags, slogans, uniforms, gestures, salutes, illustrations, portraits,
          songs, music, lyrics, or other objects meant to represent violent extremist organizations or individuals.</li>
      </ul>

      <p><strong>8. Integrity and authenticity</strong></p>


      <p>We believe that trust forms the foundation of our community. We do not allow activities that may undermine the
        integrity of YOUvME platform or the authenticity of our users. We remove content or accounts that involve spam
        or fake engagement, impersonation, or misleading information that causes significant harm.</p>

      <p><strong>A. Spam and fake engagement</strong></p>

      <p>Fake engagement includes any content or activity that seeks to artificially inflate popularity on the platform.
        We prohibit any attempts to manipulate the platform to increase interaction metrics.</p>

      <p><strong>Do not:</strong></p>

      <ul>
        <li>Share instructions on how to artificially increase views, likes, followers, shares, or comments.</li>
        <li>Engage in selling or buying views, likes, followers, shares, or comments.</li>
        <li>Promote artificial traffic generation services.</li>
        <li>Operate multiple YOUvME accounts under false or fraudulent pretenses to distribute commercial spam.</li>
        <li>Create malicious software or modify code to artificially increase views, likes, followers, shares, or
          comments.</li>
      </ul>

      <p><strong>B. Impersonation</strong></p>


      <p>We do not allow accounts that pose as another person or entity deceptively. When we confirm a report of
        impersonation, we may ask the user to revise the profile or may ban the account. We do allow accounts that are
        parody, commentary, or fan-based, such as where the username indicates that it is a fan, commentary, or parody
        account and not affiliated with the subject of the account.</p>
      <p><strong>Do not:</strong></p>
      <ul>
        <li>Pose as another person or entity by using someone else's name, biographical details, or profile picture in a
          misleading manner.</li>
      </ul>
      <p><strong>C. Harmful misinformation</strong></p>

      <p>Misinformation is defined as content that is inaccurate or false. We will remove misinformation that causes
        significant harm to individuals, our community, or the larger public regardless of intent. Significant harm
        includes serious physical injury, illness, or death; severe psychological trauma; large-scale property damage,
        and the undermining of public trust in civic institutions and processes such as governments, elections, and
        scientific bodies. This does not include simply inaccurate information, myths, or commercial or reputational
        harm.</p>
      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Misinformation that incites hate or prejudice.</li>
        <li>Misinformation related to emergencies that induce panic.</li>
        <li>Medical misinformation that can cause harm to an individual's physical health.</li>
        <li>Content that misleads community members about elections or other civic processes.</li>
        <li>Conspiratorial content including content that attacks a specific person or a protected group, includes a
          violent call to action, or denies a violent or tragic event occurred.</li>
        <li>Digital Forgeries (Synthetic Media or Manipulated Media) that mislead users by distorting the truth of
          events and cause significant harm to the subject of the video, other persons, or society.</li>
      </ul>

      <p><strong>Do not:</strong></p>

      <ul>
        <li>Engage in coordinated inauthentic behavior such as the use of multiple accounts to exert influence and sway
          public opinion while misleading individuals, our community, or our systems about the account's identity,
          location, relationships, popularity, or purpose.</li>
      </ul>

      <p><strong>9. Illegal activities and regulated goods</strong></p>
      <p>We work to ensure YOUvME does not enable activities that violate laws or regulations. We prohibit the trade,
        sale, promotion, and use of certain regulated goods, as well as the promotion or facilitation of criminal
        activities, including human exploitation. Content may be removed if it relates to activities or goods that are
        regulated or illegal in the majority of the region or world.</p>
      <p><strong>A. Criminal activities</strong></p>

      <p>Criminal activities cover a wide spectrum of acts punishable by law, including theft, assault, human
        exploitation, counterfeiting, and other harmful behavior. To prevent such behavior from being normalized,
        imitated, or facilitated, we remove content that promotes or enables criminal activities.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that promotes acts of physical harm, such as assault or kidnapping.</li>
        <li>Content that risks the safety of others, including swatting.</li>
        <li>Content that promotes human exploitation, including human smuggling, bonded labor, domestic servitude, sex
          trafficking, or prostitution.</li>
        <li>Content that promotes vandalism or damage to property.</li>
        <li>Content that promotes the poaching or illegal trade of wildlife.</li>
        <li>Content that offers the purchase, sale, trade, or solicitation of unlawfully acquired or counterfeit goods.
        </li>
        <li>Content that provides instructions on how to conduct criminal activities that result in harm to people,
          animals, or property.</li>
      </ul>

      <p><strong>B. Weapons</strong></p>

      <p>We do not allow the depiction, promotion, or trade of firearms, ammunition, firearm accessories, or explosive
        weapons. We also prohibit instructions on how to manufacture those weapons. We may allow depiction when
        appearing in educational contexts (e.g., as part of a museum's collection), involving authorized government
        personnel (e.g., a police officer), or used in a safe and controlled environment (e.g., shooting range,
        recreational hunting).</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that displays firearms, firearm accessories, ammunition, or explosive weapons.</li>
        <li>Content that offers the purchase, sale, trade, or solicitation of firearms, accessories, ammunition,
          explosive weapons, or instructions on how to manufacture them.</li>
      </ul>

      <p><strong>C. Drugs, controlled substances, alcohol, and tobacco</strong></p>

      <p>We do not allow the depiction, promotion, or trade of drugs or other controlled substances. The trade of
        tobacco and alcohol products is also prohibited on the platform.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that depicts or promotes drugs, drug consumption, or encourages others to make, use, or trade drugs
          or other controlled substances.</li>
        <li>Content that offers the purchase, sale, trade, or solicitation of drugs or other controlled substances,
          alcohol, or tobacco products (including vaping products, smokeless or combustible tobacco products, synthetic
          nicotine products, E-cigarettes, and other ENDS [Electronic Nicotine Delivery Systems]).</li>
        <li>Content that provides information on how to buy illegal or controlled substances.</li>
        <li>Content that depicts or promotes the misuse of legal substances, or instruction on how to make homemade
          substances, to become intoxicated.</li>
      </ul>

      <p><strong>D. Frauds and scams</strong></p>

      <p>We do not permit anyone to exploit our platform to take advantage of the trust of users and bring about
        financial or personal harm. We remove content that deceives people to gain an unlawful financial or personal
        advantage, including schemes to defraud individuals or steal assets.</p>
      <p><strong>Do not post, upload, stream, or share:</strong></p>
      <ul>
        <li>Content that promotes phishing.</li>
        <li>Content that promotes Ponzi, multi-level marketing, or pyramid schemes.</li>
        <li>Content that promotes investment schemes with the promise of high returns, fixed betting, or any other types
          of scams.</li>
      </ul>

      <p><strong>E. Gambling</strong></p>

      <p>We do not allow content promoting gambling services, or that could be perceived as advertising for casinos,
        sports betting, or any other commercial gambling activity.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that promotes casinos, sports betting, poker, lotteries, gambling-related software, apps, or other
          gambling services.</li>
      </ul>

      <p><strong>F. Privacy, personal data, and personally identifiable information (PII)</strong></p>

      <p>We do not allow content that violates the confidentiality of personal data or personally identifiable
        information (e.g., social security information, phone numbers, physical addresses). We remove content that
        depicts personal data or personally identifiable information (PII) from the platform.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that contains personal data or personally identifiable information (PII).</li>
      </ul>

      <p><strong>10. Violent and graphic content</strong></p>


      <p>YOUvME is a platform that celebrates creativity to help people raise awareness about issues. We do not allow
        content that promotes, or glorifies extreme violence or celebrates the suffering, or humiliation of others
        because it may create an environment that discourages participation on our platform. When it is a threat to
        public safety, we ban the account and, when warranted, we will report it to relevant legal authorities.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content of humans that depicts: <ul>
            <li>violent or graphic deaths or accidents.</li>
            <li>dismembered, mutilated, charred, or burned human remains.</li>
            <li>gore in which an open wound or injury is the core focus.</li>
            <li>real-world physical violence, fighting, or torture.</li>
          </ul>
        </li>
        <li>Content of animals that depicts: <ul>
            <li>the slaughter or other non-natural death of animals.</li>
            <li>dismembered, mutilated, charred, or burned animal remains.</li>
            <li>animal cruelty and gore.</li>
          </ul>
        </li>
      </ul>

      <p><strong>11. Copyright and trademark infringement</strong></p>

      <p>Copyright is a legal right that protects original works of authorship (e.g., music, videos) and original
        expression of an idea (e.g., the specific way a video or music is expressed or created), although it does not
        protect underlying ideas or facts. A trademark is a word, symbol, slogan, or design that identifies and
        distinguishes the source of a product or service. We encourage everyone to create and share original content.
        Content that infringes someone else's intellectual property rights is prohibited on YOUvME platform and will be
        removed if we become aware of it. The use of copyrighted work under certain circumstances, such as the fair use
        doctrine or other applicable laws, or the use of a trademark to reference, lawfully comment, criticize, parody,
        make a fan page, or review a product or service, may not be considered a violation of our policies.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content that violates or infringes someone else's copyrights, trademarks, or other intellectual property
          rights.</li>
      </ul>

      <p><strong>12. Platform security</strong></p>

      <p>A top priority for YOUvME is the safety and security of our users, creators, business partners, vendors, and
        employee data, including their personal information, accounts, profiles, content, and other proprietary
        information, as well as our product and services. We do not allow access to the YOUvME platform - which includes
        our website, app, network, and associated infrastructure or networks - through unauthorized methods, or to
        obtain sensitive confidential commercial or personal information. Any attempt to undermine or abuse the
        security, integrity, or reliability of our platform, products, or services is expressly prohibited.</p>

      <p><strong>Do not:</strong></p>

      <ul>
        <li>Attempt to access the YOUvME platform in an unauthorized manner, and do not create fake or misleading
          versions of the YOUvME platform.</li>
        <li>Create or distribute malicious files, content, or messages that contain viruses, Trojan horses, worms, logic
          bombs, or other materials that may be harmful to the community or platform.</li>
        <li>Use automated scripts, web crawling, software, deceptive techniques, or any other way to attempt to obtain,
          acquire, or request login credentials or other sensitive information, including non-public data, from YOUvME
          or its users.</li>
        <li>Leverage YOUvME accounts under false or fraudulent pretenses to distribute spam, phishing, or smishing
          content in an attempt to perpetrate cybercrime or gain unauthorized access to others’ content, accounts,
          systems, or data.</li>
        <li>Modify, adapt, translate, reverse engineer, disassemble, decompile, or create any derivative products based
          on YOUvME, including any files, tables, or documentation, or attempt to regenerate any source code,
          algorithms, methods, or techniques embodied in YOUvME.</li>
        <li>Provide access to your account credentials to others or enable others to conduct activities against our
          Community Guidelines.</li>
        <li>Click on suspicious links or engage in requests for information about your account details, passwords,
          verification qualification, financial, or other personal information.</li>
      </ul>

      <p><strong>13. Protection of Minor</strong></p>

      <p>YOUvME doesn’t allow content that endangers the emotional and physical well-being of minors. A minor is defined
        as someone under the legal age of majority -- usually, anyone younger than 18 years old in most
        countries/regions.</p>

      <p><strong>Do not post, upload, stream, or share:</strong></p>

      <ul>
        <li>Content showing a minor participating in dangerous activities or encouraging minors to do dangerous
          activities. Never put minors in harmful situations that may lead to injury, including dangerous stunts, dares,
          or pranks. </li>
        <li>Sexually explicit content featuring minors and content that sexually exploits minors.</li>
        <li>Content that could cause minor participants or viewers emotional distress, including; <ul>
            <li>Exposing minors to mature themes.</li>
            <li>Simulating parental abuse.</li>
            <li>Coercing minors.</li>
            <li>Violence.</li>
          </ul>
        </li>
        <li>Content that targets individuals for abuse or humiliation including content that; <ul>
            <li>Reveals personal information like email addresses or bank account numbers.</li>
            <li>Records someone without their consent.</li>
            <li>Sexually harasses.</li>
            <li>Encourages others to bully or harass.</li>
          </ul>
        </li>

      </ul>

      <p>Thank you for being a part of our vibrant global community and working with us to maintain a welcoming space
        for all users. If you come across content or accounts that you believe may violate our Community Guidelines,
        please <a href="Mailto:info@youvme.com">contact us</a>, so we can review and take appropriate action.</p>

    </div>
    <Footer />
  </div>
</template>

<script>
import TopBanner from "@/components/landing/TopBanner.vue";
import mediaCheckerMixins from "@/helpers/media-checker";
import FixedHeader from 'vue-fixed-header'
import Footer from '@/components/landing/Footer.vue';

export default {
  metaInfo() {
    return {
      title: "Terms"
    };
  },
  mixins: [mediaCheckerMixins],
  components: {
    TopBanner,
    FixedHeader,
    Footer
  },
  name: "Terms",
  data() {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      loadingBtn: false,
      modalVisible: false,
      form: {
        name: "",
        email: ""
      },
      visible: false,
      menuIcon: "menu",
      copyrightYear: new Date().getFullYear(),
      rules: {
        name: [
          {
            required: true,
            message: "Your name cannot be empty",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change"
          },
          {
            pattern: /\S+@\S+\.\S+/,
            message: "Please type in a valid email address"
          }
        ]
      }
    };
  },
  methods: {
    showDrawer() {
      this.visible = true;
      this.menuIcon = "menu-fold";
    },
    onClose() {
      this.visible = false;
      this.menuIcon = "menu-unfold";
    },
    showModal() {
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixins.less";
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

#youvme-lading-page {
  font-family: "Gilroy";
}

.row-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_link_ft {
  color: #715fa6;
}

.ld_header_wrap {
  // .header__landing--bg();
  background-color: #201347;

  .ld_header.vue-fixed-header--isFixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    background: #2f205b;
    z-index: 99;
  }

  .ld_header {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 30px 20px 30px;

    .youvme_logo {
      max-width: 120px;
    }

    .header_content {
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: center;

      .header_title {
        .gilroy-bold(3.5em, #fff);
      }

      .header_username {
        .gilroy-bold(1.2em, #8c8bbc);
      }

      .header_description {
        .gilroy-bold(1.2em, #fff);
      }
    }
  }
}

.ld_content {
  padding-top: 50px;
  padding-bottom: 80px;

  .ld_form {
    display: flex;
    justify-content: center;
  }

  .landing_page_form {
    min-width: 300px;
  }

  .landing_page_form .form-label {
    color: #2f205b !important;
    font-weight: bold;
  }

  .appstore-box {
    padding-top: 70px;
    display: flex;
    justify-content: center;
    text-align: center;

    p {
      color: #2f205b;
      font-weight: bold;
      .gilroy-bold(1.2em, #2f205b);
    }
  }
}

.ld_footer {
  .logo_ft {
    .youvme_logo {
      max-width: 120px;
    }

    padding: 30px 90px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #291b54;

    #contact_us-button {
      background: #322360;
      border: none;
      padding: 0px 40px;
      height: 40px;
      .gilroy-bold(1em, #fff);
    }
  }

  .link_ft {
    padding: 30px 90px;
    background: #2f205c;

    .nav_ft {
      display: flex;
      padding-top: 8px;

      .page_link_ft {
        .gilroy(1em, #715fa6);
        padding: 0px 30px;
      }

      .page_link_ft:nth-child(1) {
        padding-left: 0px !important;
      }
    }

    .social_icon_ft {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .copyright-wraper {
      padding-top: 30px;
      display: flex;
      justify-content: flex-start;

      .copyright {
        padding-right: 50px;
        .gilroy(1em, #4c3c7d);

        a {
          .gilroy(1em, #4c3c7d);
        }
      }
    }
  }
}

.menu-toggle {
  position: absolute;
  right: 0px;
  z-index: 999;
  min-width: 200px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.desktop-menu {
  display: none;
}

.menu-mobile-btn {
  display: block;
}

.btn-get-the-app {
  font-size: 0.8rem;
  color: white;
  background: #00b7e8;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .ld_header {
    padding-left: 0;
    padding-right: 0;
  }

  .menu-mobile-btn {
    display: none;
  }

  .desktop-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    align-items: center;

    li {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: #715fa6;
    }
  }
}

.ant-drawer {
  z-index: 999;
}

@media (min-width: 1220px) {
  .desktop-menu {
    li {
      margin-left: 40px;
    }
  }
}

.browse-challenges-wrapper {
  padding: 40px 24px;

  h2 {
    font-size: 32px;
    color: #2f205b;
    line-height: 1.2;
    font-weight: 800;
    margin: 0 0 40px;
  }
}

@media screen and (min-width: 768px) {
  .browse-challenges-wrapper {
    padding: 110px 24px;

    h2 {
      font-size: 60px;
      margin: 0 0 32px;
    }
  }
}

@import "../../styles/responsive.less";
</style>