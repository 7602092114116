var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"limiter"},[_c('div',{staticClass:"container-login100"},[_c('div',{staticClass:"wrap-login100"},[_c('div',{staticClass:"login100-form"},[_c('div',{staticClass:"login100-form-title p-b-43"},[_c('img',{attrs:{"alt":"Youvme","src":require('../assets/images/youvme_dark.svg'),"width":"200"}})]),_c('div',{staticStyle:{"text-align":"center"}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-space',{attrs:{"size":"middle","direction":"vertical"}},[_c('a-form-item',{staticClass:"login-input-wrapper",staticStyle:{"width":"100%"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Email is required!'
                        }
                      ]
                    }
                  ]),expression:"[\n                    'email',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Email is required!'\n                        }\n                      ]\n                    }\n                  ]"}],attrs:{"placeholder":"Email","size":"large"}})],1),_c('a-form-item',{staticClass:"password-box"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Password is required!'
                        }
                      ]
                    }
                  ]),expression:"[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Password is required!'\n                        }\n                      ]\n                    }\n                  ]"}],attrs:{"placeholder":"Password","size":"large"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loginLoading}},[_vm._v(" Login ")])],1)],1)],1)],1)]),_c('div',{staticClass:"login100-more"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }