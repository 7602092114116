<template>
  <a-row :gutter="32">
    <a-col :md="24" :lg="12">
      <div class="challenger-role">Challenger</div>
      <ChallengersInfoCard
        v-if="owner.userId"
        :role="'challenger'"
        :challenger="owner"
        :videoUrl="ownerVideoUrl"
        :shares="shares"
      />
    </a-col>
    <a-col :md="24" :lg="12">
      <div class="challenger-role">Opponent</div>
      <ChallengersInfoCard
        v-if="opponent.userId"
        :role="'opponent'"
        :challenger="opponent"
        :videoUrl="opponentVideoUrl"
        :shares="shares"
      />
    </a-col>
  </a-row>
</template>

<script>
import ChallengersInfoCard from "./ChallengersInfoCard.vue";

export default {
  metaInfo() {
    return {
      title: "Challengers Info"
    };
  },
  name: "ChallengersInfo",
  props: ["owner", "opponent", "ownerVideoUrl", "opponentVideoUrl", "shares"],
  components: {
    ChallengersInfoCard
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.challenger-role {
  .gilroy(16px, @main-dark);
  box-sizing: border-box;
  margin-bottom: 20px;
}
</style>
