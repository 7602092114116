<template>
  <div id="youvme-lading-page">
    <div class="ld_header_wrap">
      <fixed-header :threshold="100">
        <div class="ld_header">
          <div class="container landing-container">
            <div class="row-header-top">
              <router-link to="/">
                <img class="youvme_logo" alt="Youvme" :src="require('../../assets/images/youvme.svg')" />
              </router-link>
              <a-button class="menu-mobile-btn" @click="showDrawer" :icon="menuIcon" />
              <ul class="desktop-menu">
                <li>
                  <a class="page_link_ft" href="/#browse-challenge">How does it Work?</a>
                </li>
                <li>
                  <a class="page_link_ft" href="/#get-app">Creating a Challenge</a>
                </li>
                <li>
                  <a class="page_link_ft" href="/#joining-the-challenge">App Features</a>
                </li>
                <li>
                  <a class="page_link_ft" href="/#what-you-can-find">Browse Categories</a>
                </li>
                <li>
                  <button class="btn-get-the-app" v-scroll-to="'#download-application'">
                    Get the APP
                  </button>
                </li>
              </ul>
              <a-drawer class="menu-mobile-wapper" placement="left" :closable="false" :visible="visible"
                @close="onClose">
                <div class="nav_ft">
                  <a class="page_link_ft" href="/#browse-challenge">How does it Work?</a>
                  <a class="page_link_ft" href="/#get-app">Creating a Challenge</a>
                  <a class="page_link_ft" href="/#joining-the-challenge">App Features</a>
                  <a class="page_link_ft" href="/#what-you-can-find">Browse Categories</a>
                  <button class="btn-get-the-app" v-scroll-to="'#download-application'">
                    Get the APP
                  </button>
                </div>
              </a-drawer>
            </div>
          </div>
        </div>
      </fixed-header>
      <top-banner />
    </div>

    <div class="browse-challenges-wrapper landing-container">
      <h2>PRIVACY POLICY</h2>

      <p><strong>Last modified on May 6,2022</strong></p>

      <p>This privacy policy governs your use of YOUvME (the “Platform”). The Platform (also referred to as “we”, “us”
        or “our”) is provided and controlled by Wired Avenue LLC, with its registered address at 611 W G S.t, San Diego,
        CA 92101.</p>

      <p>To provide the services on the Platform, we must process information about you. The types of information we
        collect depend on how you use our services.</p>

      <p><strong>1. What information do we collect?</strong></p>

      <ul class="list-none">
        <li><strong>Information and content you provide.</strong> We collect the content, communications, and
          other information you
          provide when you use our Platform, including when you sign up for an account, create or share content, and
          message or communicate with others. This can include information in or about the content you provide, such
          as the location of a photo or the date a file was created.
        </li>

      </ul>
      <ul>
        <li>
          <strong>Networks and connections.</strong> We collect information about the people, accounts, groups and Pages
          you are connected to and how you interact with them across our Platform, such as people you communicate with
          the most or groups you are part of. We also collect contact information if you choose to upload, sync or
          import it from a device (such as an address book), which we use for things like helping you and others find
          people you may know and for the other purposes listed below.
        </li>
      </ul>

      <ul>
        <li>
          <strong>Your usage.</strong> We collect information about how you use our Platform, such as the types of
          content you view or engage with; the features you use; the actions you take; the people or accounts you
          interact with; and the time, frequency, and duration of your activities. For example, we log when you're using
          and have last used our Platform, and what posts, videos, and other content you view on our Platform.
        </li>
      </ul>

      <ul>
        <li>
          <strong>Information about transactions made on our Platform.</strong> If you use our Platform for purchases or
          other financial transactions (such as when you make a purchase in a game or make a donation), we collect
          information about the purchase or transaction. This includes payment information, such as your credit or debit
          card number and other card information; other account and authentication information; and billing, shipping,
          and contact details.
        </li>
      </ul>

      <ul>
        <li>
          <strong>Things others do and information they provide about you.</strong> We also receive and analyze content,
          communications and information that other people provide when they use our Platform. This can include
          information about you, such as when others share or comment on a video of you, send a message to you, or
          upload, sync or import your contact information.
        </li>
      </ul>

      <ul>
        <li>
          <strong>Device Information.</strong> As described below, we collect information from and about the computers,
          phones, connected TVs and other web-connected devices you use that integrate with our Platform, and we combine
          this information across different devices you use. For example, we use information collected about your use of
          our Platform on your phone to better personalize the content (including ads) or features you see when you use
          our Platform on another device, such as your laptop or tablet, or to measure whether you took an action in
          response to an ad we showed you on your phone on a different device. Information we obtain from these devices
          includes:
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              information such as the operating system, hardware and software versions, battery level, signal strength,
              available storage space, browser type, app and file names and types, and plugins.
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              information about operations and behaviors performed on the device, such as whether a window is
              foregrounded or backgrounded, or mouse movements (which can help distinguish humans from bots).
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              unique identifiers, device IDs, and other identifiers, such as from games, apps or accounts you use, and
              Family Device IDs.
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              Bluetooth signals, and information about nearby Wi-Fi access points, beacons, and cell towers.
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              information you allow us to receive through device settings you turn on, such as access to your GPS
              location, camera, or photos.
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              information such as the name of your mobile operator or ISP, language, time zone, mobile phone number, IP
              address, connection speed and, in some cases, information about other devices that are nearby or on your
              network.
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              data from cookies stored on your device, including cookie IDs and settings.
            </li>
          </ul>
        </li>
      </ul>

      <ul>
        <li>
          <strong>Information from partners.</strong> Advertisers, app developers, and publishers can send us
          information. These partners provide information about your activities off of our Platform—including
          information about your device, websites you visit, purchases you make, the ads you see, and how you use their
          services—whether or not you have an account or are logged into our Platform. We also receive information about
          your online and offline actions and purchases from third-party data providers who have the rights to provide
          us with your information.
          Partners receive your data when you visit or use their services or through third parties they work with. We
          require each of these partners to have lawful rights to collect, use and share your data before providing any
          data to us.
        </li>
      </ul>

      <p><strong>2. How will we use the information about you?</strong></p>

      <ul>
        <li>
          <strong>Improve and administer the Platform</strong> We use your information to provide the Platform to you
          and to improve and administer it. We use your information to, among other things, show you suggestions,
          improve and develop the Platform and ensure your safety. Where appropriate, we will also use your personal
          information to serve you targeted advertising and promote the Platform.
        </li>
      </ul>

      <ul>
        <li><strong>Promote safety, integrity, and security.</strong> We use the information we have to verify accounts
          and activity, combat harmful conduct, detect and prevent spam and other bad experiences, maintain the
          integrity of our Platform, and promote safety and security on and off our Platform.
        </li>
      </ul>

      <ul>
        <li><strong>Communicate with you.</strong> We use the information we have to send you marketing communications,
          communicate with you about our Platform, and let you know about our policies and terms. We also use your
          information to respond to you when you contact us.
        </li>
      </ul>

      <p><strong>3. How is this information shared?</strong></p>

      <ul>
        <li><strong>Business Partners</strong> If you choose to register to use the Platform using your social network
          account, you will provide us or allow your social network to provide us with your phone number, email address,
          username and public profile. We will likewise share certain information with the relevant social network such
          as your app ID, access token and the referring URL. If you choose to allow a third-party service to access
          your account, we will share certain information about you with the third party. Depending on the permissions
          you grant, the third party may be able to obtain your account information and other information you choose to
          provide.<br><br>
          Where you opt to share content on social media platforms, the video, username and accompanying text will be
          shared on that platform or, in the case of sharing via instant messaging platforms, a link to the content will
          be shared.

        </li>
      </ul>

      <ul>
        <li><strong>Payment Providers</strong> If you conduct payment related transactions, we will share data with the
          relevant payment provider to facilitate this transaction.
        </li>
      </ul>

      <ul>
        <li><strong>Service Providers</strong> We provide information and content to service providers who support our
          business, such as cloud service providers and providers of content moderation services to ensure that the
          Platform is a safe and enjoyable place and service providers that assist us in marketing the Platform.
        </li>
      </ul>

      <ul>
        <li><strong>Analytics providers</strong> We use analytics providers to help us in the optimization and
          improvement of the Platform. Our third-party analytics providers also help us serve targeted adverts.
        </li>
      </ul>

      <ul>
        <li><strong>Advertisers and Advertising Networks</strong> We share information with advertisers and third-party
          measurement companies to show how many and which users of the Platform have viewed or clicked on an
          advertisement. We share your device ID with measurement companies so that we can link your activity on the
          Platform with your activity on other websites; we then use this information to show you adverts which may be
          of interest to you.
        </li>
      </ul>

      <ul>
        <li><strong>Our Corporate Group</strong> We may also share your information with other members, subsidiaries, or
          affiliates of our corporate group, to provide the Platform including improving and optimizing the Platform,
          preventing illegal use and supporting users.
        </li>
      </ul>

      <ul>
        <li><strong>Law Enforcement</strong> We will share your information with law enforcement agencies, public
          authorities or other organizations if legally required to do so, or if such use is reasonably necessary to:
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              comply with legal obligation, process or request;
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              enforce our Terms of Service and other agreements, policies, and standards, including investigation of any
              potential violation thereof;
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              detect, prevent or otherwise address security, fraud or technical issues; or
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              protect the rights, property or safety of us, our users, a third party or the public as required or
              permitted by law (including exchanging information with other companies and organizations for the purposes
              of fraud protection and credit risk reduction).
            </li>
          </ul>
        </li>
      </ul>

      <ul>
        <li><strong>Public Profiles</strong> Please note that if your profile is public, your content will be visible to
          anyone on the Platform and may also be accessed or shared by your friends and followers as well as third
          parties such as search engines, content aggregators and news sites. You can change who can see a video each
          time you upload a video. Alternatively, you can change your profile to default private by changing your
          settings to 'Private Account' in “Manage my account” settings.
        </li>
      </ul>

      <ul>
        <li><strong>Sale or Merger</strong> We will also disclose your information to third parties:
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              in the event that we sell or buy any business or assets (whether a result of liquidation, bankruptcy or
              otherwise), in which case we will disclose your data to the prospective seller or buyer of such business
              or assets; or
            </li>
          </ul>
        </li>
      </ul>

      <ul style="list-style:none;">
        <li>
          <ul>
            <li>
              if we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or
              all of our assets. In such transactions, user information may be among the transferred assets.
            </li>
          </ul>
        </li>
      </ul>

      <p><strong>4. Where we store your personal data</strong></p>

      <p>The personal data we collect from you may be stored on a server located in the United States or Canada, outside
        of the country where you live. We maintain major servers around the world to bring you our services globally and
        continuously. </p>

      <p><strong>5. How can you manage or delete information about you?</strong></p>

      <p>We provide you with the ability to access, rectify, port, and erase your data.
        When you delete your account, we delete things you have posted, such as your photos and status updates, and you
        won't be able to recover that information later. Information that others have shared about you isn't part of
        your account and won't be deleted. If you don't want to delete your account but want to temporarily stop using
        the Platform, you can deactivate your account instead. </p>

      <p><strong>6. How long do we store your data?</strong></p>

      <p>We retain your information for as long as it is necessary, for example, to comply with our contractual
        relationship or to provide you with our service. Where we do not need your information in order to provide the
        service to you, we retain it only for so long as we have a legitimate business purpose in keeping such data, as
        may be allowed by applicable laws. However, there are occasions where we are likely to keep this data for longer
        in accordance with our legal obligations or where it is necessary for the establishment, exercise, or defense of
        legal claims. After you have terminated your use of our Platform, we store your information in an aggregated and
        anonymized format. </p>

      <p><strong>7. Information relating to minor</strong></p>

      <p>The Platform is not directed at children under the age of 13. In certain cases, this age may be higher due to
        local regulatory requirements, please see your local privacy policy for more information. If you believe that we
        have personal data about or collected from a child under the relevant age, please contact us. </p>

      <p><strong>8. How will we notify you of changes to this policy?</strong></p>

      <p>We'll notify you before we make changes to this policy and give you the opportunity to review the revised
        policy before you choose to continue using our Platform.</p>

      <p><strong>9. Contact Details, Complaints and Questions?</strong></p>


      <p>If you have any questions regarding our Privacy Policy or wish to make a complaint about how we process your
        personal data, you can contact us. We will endeavour to deal with your request as soon as possible. This is
        without prejudice to your right to launch a claim with the relevant data protection authority.</p>


      <p>
        Wired Avenue LLC<br />
        611 W G St, Suite 122<br />
        San Diego, CA 92101<br />
        United States
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopBanner from "@/components/landing/TopBanner.vue";
import mediaCheckerMixins from "@/helpers/media-checker";
import FixedHeader from "vue-fixed-header";
import Footer from "@/components/landing/Footer.vue";

export default {
  metaInfo() {
    return {
      title: "Privacy"
    };
  },
  mixins: [mediaCheckerMixins],
  components: {
    TopBanner,
    FixedHeader,
    Footer
  },
  name: "Privacy",
  data() {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      loadingBtn: false,
      modalVisible: false,
      form: {
        name: "",
        email: ""
      },
      visible: false,
      menuIcon: "menu",
      copyrightYear: new Date().getFullYear(),
      rules: {
        name: [
          {
            required: true,
            message: "Your name cannot be empty",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change"
          },
          {
            pattern: /\S+@\S+\.\S+/,
            message: "Please type in a valid email address"
          }
        ]
      }
    };
  },
  methods: {
    showDrawer() {
      this.visible = true;
      this.menuIcon = "menu-fold";
    },
    onClose() {
      this.visible = false;
      this.menuIcon = "menu-unfold";
    },
    showModal() {
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixins.less";
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

#youvme-lading-page {
  font-family: "Gilroy";
}

.row-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_link_ft {
  color: #715fa6;
}

.ld_header_wrap {
  // .header__landing--bg();
  background-color: #201347;

  .ld_header.vue-fixed-header--isFixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    background: #2f205b;
    z-index: 99;
  }

  .ld_header {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 30px 20px 30px;

    .youvme_logo {
      max-width: 120px;
    }

    .header_content {
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: center;

      .header_title {
        .gilroy-bold(3.5em, #fff);
      }

      .header_username {
        .gilroy-bold(1.2em, #8c8bbc);
      }

      .header_description {
        .gilroy-bold(1.2em, #fff);
      }
    }
  }
}

.ld_content {
  padding-top: 50px;
  padding-bottom: 80px;

  .ld_form {
    display: flex;
    justify-content: center;
  }

  .landing_page_form {
    min-width: 300px;
  }

  .landing_page_form .form-label {
    color: #2f205b !important;
    font-weight: bold;
  }

  .appstore-box {
    padding-top: 70px;
    display: flex;
    justify-content: center;
    text-align: center;

    p {
      color: #2f205b;
      font-weight: bold;
      .gilroy-bold(1.2em, #2f205b);
    }
  }
}

.ld_footer {
  .logo_ft {
    .youvme_logo {
      max-width: 120px;
    }

    padding: 30px 90px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #291b54;

    #contact_us-button {
      background: #322360;
      border: none;
      padding: 0px 40px;
      height: 40px;
      .gilroy-bold(1em, #fff);
    }
  }

  .link_ft {
    padding: 30px 90px;
    background: #2f205c;

    .nav_ft {
      display: flex;
      padding-top: 8px;

      .page_link_ft {
        .gilroy(1em, #715fa6);
        padding: 0px 30px;
      }

      .page_link_ft:nth-child(1) {
        padding-left: 0px !important;
      }
    }

    .social_icon_ft {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .copyright-wraper {
      padding-top: 30px;
      display: flex;
      justify-content: flex-start;

      .copyright {
        padding-right: 50px;
        .gilroy(1em, #4c3c7d);

        a {
          .gilroy(1em, #4c3c7d);
        }
      }
    }
  }
}

.menu-toggle {
  position: absolute;
  right: 0px;
  z-index: 999;
  min-width: 200px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.desktop-menu {
  display: none;
}

.menu-mobile-btn {
  display: block;
}

.btn-get-the-app {
  font-size: 0.8rem;
  color: white;
  background: #00b7e8;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .ld_header {
    padding-left: 0;
    padding-right: 0;
  }

  .menu-mobile-btn {
    display: none;
  }

  .desktop-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    align-items: center;

    li {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: #715fa6;
    }
  }
}

.ant-drawer {
  z-index: 999;
}

@media (min-width: 1220px) {
  .desktop-menu {
    li {
      margin-left: 40px;
    }
  }
}

.browse-challenges-wrapper {
  padding: 40px 24px;

  h2 {
    font-size: 32px;
    color: #2f205b;
    line-height: 1.2;
    font-weight: 800;
    margin: 0 0 40px;
  }
}

@media screen and (min-width: 768px) {
  .browse-challenges-wrapper {
    padding: 110px 24px;

    h2 {
      font-size: 60px;
      margin: 0 0 32px;
    }
  }
}

@import "../../styles/responsive.less";
</style>
