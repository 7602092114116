<template>
  <div class="ready-to-get-started-wrapper landing-container">
    <div class="video-wrapper" :class="[{ active }]" @click="play">
      <div class="play-btn">
        <img src="@/assets/images/play.svg" />
      </div>
      <video
        src="@/assets/videos/youvme-trailer.mp4"
        ref="video"
        poster="@/assets/videos/youvme-trailer-poster.jpg"
      />
    </div>
    <div id="download-application" class="download-app">
      <download-app />
      <div class="bottom">
        YOUvME Android app coming soon to the Google Play Store
      </div>
    </div>
    
  </div>
</template>

<script>
import DownloadApp from "./DownloadApp.vue";

export default {
  metaInfo() {
    return {
      title: "Ready To Get Started"
    };
  },
  components: { DownloadApp },
  data() {
    return {
      active: false
    };
  },
  methods: {
    play() {
      this.active = !this.active;
      if (this.active) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ready-to-get-started-wrapper {
  .video-wrapper {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: 1;
      border-radius: 10px;
    }

    &.active {
      &:before {
        display: none;
      }

      .play-btn {
        display: none;
      }
    }

    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  video {
    width: 100%;
    display: block;
    border-radius: 10px;
  }

  .bottom {
    margin: 25px 0 0;
    color: #2f205b;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    font-weight: 500;
  }

  .download-app {
    padding: 60px 0;
  }
}
</style>
