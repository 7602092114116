<template>
  <div>
    <a-row type="flex" align="middle" class="user-info">
      <a-col flex="100px">
        <a-avatar :src="userInfo.avatar" :size="65"
          >{{ userInfo.username.slice(0, 2) }}
        </a-avatar>
      </a-col>
      <a-col flex="auto">
        <div>{{ userInfo.username }}</div>
        <div>
          <span
            class="user-info-detail"
            v-if="userInfo.fullName && userInfo.fullName !== ' '"
            >{{ userInfo.fullName }}</span
          >
          <span class="user-info-detail"
            >Followers: {{ userInfo.followers.length }}</span
          >
          <span class="user-info-detail"
            >Following: {{ userInfo.following.length }}</span
          >
          <span class="user-info-detail"
            >Following Categories: {{ userInfo.favoriteCategory.length }}</span
          >
          <span class="user-info-detail">{{ userInfo.email }}</span>
        </div>
      </a-col>
      <a-col>
        <a-button
          class="block-user"
          @click="blockUserModal = true"
          >Block User</a-button
        >
        <a-button class="edit-user" @click="$emit('openEditForm')"
          >Edit User</a-button
        >
        <a-button
          class="button-state--secondary"
          @click="changePasswordModal = true"
          >Change password</a-button
        >
        <a-button
          class="delete-user"
          v-if="userInfo.status == 'ACTIVE'"
          @click="modalVisible = true"
          >Delete User</a-button
        >
      </a-col>
      <DeleteUserModal
        :modalVisible="modalVisible"
        :maskStyle="maskStyle"
        :dialogStyle="dialogStyle"
        :userInfo="{
          id: userInfo._id,
          name: userInfo.username,
          avatar: userInfo.avatar
        }"
        @closeModal="modalVisible = false"
      />
      <ChangePasswordModal
        :modalVisible="changePasswordModal"
        :maskStyle="maskStyle"
        :dialogStyle="dialogStyle"
        :userInfo="{
          id: userInfo._id,
          name: userInfo.username,
          avatar: userInfo.avatar
        }"
        @closeModal="changePasswordModal = false"
      />
      <BlockUserModal
        :modalVisible="blockUserModal"
        :maskStyle="maskStyle"
        :dialogStyle="dialogStyle"
        :loading="loading"
        :userId="userInfo._id"
        @closeModal="blockUserModal = false"
      />
    </a-row>
    <a-row
      type="flex"
      justify="space-around"
      align="middle"
      class="user-summary"
    >
      <a-col lg="3" md="6">
        <div>Total Cases</div>
        <div>{{ userInfo.configurations.totalCase }}</div>
      </a-col>
      <a-col lg="3" md="6">
        <div>Total Won</div>
        <div>{{ userInfo.configurations.totalWon }}</div>
      </a-col>
      <a-col lg="3" md="6">
        <div>Total Lost</div>
        <div>{{ userInfo.configurations.totalLost }}</div>
      </a-col>
      <a-col lg="3" md="6">
        <div>Total Votes</div>
        <div>{{ userInfo.configurations.totalVotes }}</div>
      </a-col>
      <a-col lg="3" md="6">
        <div>Favourite Category</div>
        <div>{{ favoriteCategory }}</div>
      </a-col>
      <a-col lg="3" md="6">
        <div>Date Joined</div>
        <div>{{ joinedDate }}</div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DeleteUserModal from "./DeleteUserModal.vue";
import ChangePasswordModal from "./ChangePasswordModal.vue";
import BlockUserModal from "./BlockUserModal.vue";
import getJoinedDate from "../../utilities/getJoinedDate";

export default {
  metaInfo() {
    return {
      title: "User Summary"
    };
  },
  name: "UserSummary",
  props: ["collapsed", "userInfo", "loading"],
  components: {
    DeleteUserModal,
    ChangePasswordModal,
    BlockUserModal
  },
  data() {
    return {
      modalVisible: false,
      changePasswordModal: false,
      blockUserModal:false
    };
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      const marginTop = "60px";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft,
        "margin-top": marginTop
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "2.5%" : "8.5%"
      };
    },
    joinedDate() {
      return getJoinedDate(this.userInfo.created_at);
    },
    favoriteCategory() {
      return this.userInfo.favoriteCategory.length > 0
        ? this.userInfo.favoriteCategory[0].title
        : "None";
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.user-info {
  margin-bottom: 25px;
  height: auto;
  > .ant-col:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
  }
  > .ant-col:nth-child(2) {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    > div:nth-child(1) {
      .header__headline--font();
      margin-bottom: 10px;
    }
  }
  > .ant-col:nth-child(3) {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .button-state--secondary {
    .secondary-button(auto);
    margin-left: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .user-info-detail {
    display: block;
    margin-bottom: 10px;
  }
}

.user-info-detail {
  .header__description--font();
  height: auto;
  opacity: 0.5;
  margin-right: 25px;
}

.user-summary {
  background-color: @white;
  border-radius: 4px;
  margin-bottom: 20px;
  > .ant-col {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    > div:nth-child(1) {
      .gilroy(16px, @dark);
      align-self: center;
      opacity: 0.5;
      letter-spacing: -0.03em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > div:nth-child(2) {
      .poppins-medium(16px, @main-dark);
      text-align: center;
      letter-spacing: 0.01em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.edit-user {
  .primary-button(110px);
}
.block-user{
  .primary-button(110px);
  margin-right: 20px;
}
.delete-user {
  .red-button(120px);
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
}
</style>
