import { challengeApi } from "../apis";

export default {
  data() {
    return {
   
    }
  },
  created: function () {
    
  },
  methods: {
    getAvarta: function (avatarLink, type = 'USER_AVATAR') {
      if(!avatarLink) return null
      let split = [];
      split =  avatarLink.split('/');
      let form = {
        type,
        "fileName": split[split.length -1]
      }
      return challengeApi.presignedDownload(form);
    },
  },
}