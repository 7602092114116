<template>
  <div>
    <a-spin :spinning="loading"></a-spin>
    <div v-if="challengeInfo">
      <challenge-summary :challengeInfo="{ ...challengeInfo }">
        <template v-slot:button-group>
          <a-button
            class="button-state--third"
            @click="cloneChallenge(challengeInfo._id)"
            >Clone Challenge</a-button
          >
          <a-button
            class="button-state--primary"
            @click="editModalVisible = true"
            >Edit Case</a-button
          >
          <a-button
            class="button-state--secondary"
            @click="shareModalVisible = true"
            >Share Case</a-button
          >
          <a-button
            v-if="challengeInfo.status == 'SUSPENDED'"
            @click="unsuspend(challengeInfo._id)"
            type="danger"
            class="button-state--danger"
            >Unsuspend</a-button
          >
        </template>
        <template v-slot:summary>
          <div class="challenge-summary">
            <a-row type="flex" justify="space-around" :gutter="16">
              <a-col
                ><div>Total Votes</div>
                <div>
                  {{ challengeInfo.owner.votes + challengeInfo.opponent.votes }}
                </div></a-col
              >
              <a-col>
                <div>Duration</div>
                <div>{{ remainingTime }}</div>
              </a-col>
              <a-col>
                <div>Date Created</div>
                <div>{{ createdDate }}</div>
              </a-col>
              <a-col>
                <div>Category</div>
                <div>
                  {{
                    challengeInfo.category ? challengeInfo.category.title : ""
                  }}
                </div>
              </a-col>
            </a-row>
          </div>
        </template>
      </challenge-summary>
      <ChallengersInfo
        :owner="{ ...challengeInfo.owner }"
        :opponent="{ ...challengeInfo.opponent }"
        :ownerVideoUrl="challengeInfo.owner.videoUrl"
        :opponentVideoUrl="challengeInfo.opponent.videoUrl"
        :shares="challengeInfo.shares"
      />
      <ChallengeEditModal
        v-if="challengeInfo.owner.userId && challengeInfo.opponent.userId && !loading"
        :challengeId="challengeInfo._id"
        :title="challengeInfo.title"
        :duration="challengeInfo.remainingTime"
        :maxDuration="challengeInfo.durations"
        :owner="{ ...challengeInfo.owner }"
        :opponent="{ ...challengeInfo.opponent }"
        :modalVisible="editModalVisible"
        :collapsed="collapsed"
        :status="challengeInfo.status"
        @closeModal="editModalVisible = false"
        @submitEditForm="onSubmitEditForm"
        @openVideoModal='openVideoModal'

      />
      <ChallengeCommentsModal
        v-if="commentModalVisible"
        :modalVisible="commentModalVisible"
        :collapsed="collapsed"
        :challengeId="challengeInfo._id"
        :commentRef="commentRef"
      />
      <ChallengeVideoModal
        :url="displayedVideo"
        :modalVisible="videoModalVisible"
        :collapsed="collapsed"
        @closeVideoModal="videoModalVisible = false"
      />
      <ChallengeShareModal
        v-if="shareModalVisible"
        :modalVisible="shareModalVisible"
        :challenge="challengeInfo"
        :collapsed="collapsed"
        @closeShareModal="shareModalVisible = false"
      />
    </div>
  </div>
</template>

<script>
import ChallengeSummary from "../components/challengeDetail/ChallengeSummary.vue";
import ChallengersInfo from "../components/challengeDetail/ChallengersInfo.vue";
import ChallengeEditModal from "../components/challengeDetail/ChallengeEditModal.vue";
import ChallengeCommentsModal from "../components/challengeDetail/ChallengeCommentsModal.vue";
import ChallengeVideoModal from "../components/challengeDetail/ChallengeVideoModal.vue";
import ChallengeShareModal from "../components/challengeDetail/ChallengeShareModal.vue";
import { challengeApi } from "../apis";
import getJoinedDate from "../utilities/getJoinedDate";
import { getReadableDuration } from "../utilities/getDuration";
import { message } from "ant-design-vue";
import mixin from '@/mixins/myMixins.js'

export default {
  mixins: [mixin],
  metaInfo() {
    return {
      title: "Challenge Detail"
    };
  },
  name: "ChallengeDetail",
  props: ["collapsed"],
  components: {
    ChallengeSummary,
    ChallengersInfo,
    ChallengeEditModal,
    ChallengeCommentsModal,
    ChallengeVideoModal,
    ChallengeShareModal
  },
  data() {
    return {
      editModalVisible: false,
      commentModalVisible: false,
      videoModalVisible: false,
      shareModalVisible: false,
      challengeInfo: null,
      commentRef: null,
      displayedVideo: null,
      loading: false
    };
  },
  computed: {
    createdDate() {
      return getJoinedDate(this.challengeInfo.created_at);
    },
    remainingTime() {
      return getReadableDuration(this.challengeInfo.remainingTime);
    }
  },
  methods: {
    openCommentModal(commentRef) {
      this.commentModalVisible = true;
      this.commentRef = commentRef;
    },
    closeCommentModal() {
      this.commentModalVisible = false;
    },
    openVideoModal(videoUrl) {
      this.displayedVideo = videoUrl;
      this.videoModalVisible = true;
    },
    onSubmitEditForm() {
      this.editModalVisible = false;
      this.getChallenge(this.challengeInfo._id);
    },
    async getChallenge(challengeId) {
      this.loading = true;
      const { data, success } = await challengeApi.getChallenge(challengeId);
      setTimeout(() => this.loading = false, 200)
      
      if (success) {
       
        let newOwnerAvatar = await this.getAvarta(data.owner.userId.avatar)
        let newOpponentAvatar = await this.getAvarta(data.opponent.userId.avatar)

        let newwOwnerVideo = await this.getAvarta(data.owner.videoUrl, 'VIDEO_CHALLENGE')
        let newOpponentVideo = await this.getAvarta(data.opponent.videoUrl, 'VIDEO_CHALLENGE')

        
        data.owner.userId.avatar = newOwnerAvatar && newOwnerAvatar.data || null;
        data.opponent.userId.avatar = newOpponentAvatar && newOpponentAvatar.data || null;
        
        data.owner.videoUrl = newwOwnerVideo.data;
        data.opponent.videoUrl = newOpponentVideo.data;

        this.challengeInfo = {
          ...data,
        };
      }
    },
    async cloneChallenge(challengeId) {
      challengeApi
        .cloneChallenge(challengeId)
        .then((res) => {
          if(res.success) {
            let categoryId = res.data.category._id;
            let caseId = res.data.id;
            this.$router.push(`/admin/challenges/${categoryId}/${caseId}`)
            message.success("Clone challenge successful.");
          }
        })
    },
    async unsuspend(challengeId) {
      challengeApi
        .unsuspend(challengeId)
        .then(() => message.success("Unsuspend challenge successful."))
        .catch(err => message.error(err.message));
    }
  },
  provide() {
    return {
      openCommentModal: this.openCommentModal,
      closeCommentModal: this.closeCommentModal,
      openVideoModal: this.openVideoModal,
      showComments: true
    };
  },
  created() {
    this.getChallenge(this.$route.params.id);
  }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";

.button-state--primary {
  margin-left: 20px;
  .primary-button(117px);
}



.button-state--third {
  height: 48px;
  border-radius: 5px;
  background-color: rgba(0, 183, 232, 0.05);
  border: none;
  .poppins-medium(12px, @blue);
  &:hover,
  &:focus {
    border: none;
  }
}

.button-state--secondary {
  .secondary-button(131px);
  margin-left: 20px;
}



.button-state--danger {
  .secondary-btn(131px);
  margin-left: 20px;
}

.challenge-summary {
  background-color: white;
  border-radius: 4px;
  padding: 0px 20%;
  box-sizing: border-box;
  margin-bottom: 30px;
  > .ant-row-flex > .ant-col {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    > div:nth-child(1) {
      .gilroy(16px, @dark);
      letter-spacing: -0.03em;
      line-height: 19px;
      opacity: 0.5;
    }
    > div:nth-child(2) {
      .poppins-medium(16px, @dark);
      line-height: 24px;
      letter-spacing: 0.01em;
    }
  }
}
</style>
