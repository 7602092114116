<template>
  <div>
    <a-row type="flex" justify="space-between" class="header-container">
      <a-col :span="6">
        <div class="header__headline--font">Dashboard</div>
        <div class="header__description--font">
          All the information we have collected
        </div>
      </a-col>
      <a-col>
        <a-button @click="showDropdown = !showDropdown"
          ><span>{{ chosenPeriod.label }}</span> <a-icon type="down"
        /></a-button>
        <transition name="slide">
          <a-menu v-if="showDropdown">
            <a-menu-item
              v-for="period in periodList"
              :key="period.value"
              @click="setPeriod(period)"
              >{{ period.label }}</a-menu-item
            >
          </a-menu>
        </transition>
      </a-col>
    </a-row>
    <a-spin :spinning="overviewLoading"></a-spin>
    <OverviewCardList v-if="!overviewLoading" :overviewStats="overviewStats" />
    <a-row type="flex" justify="space-between" class="header-container">
      <a-col :span="12">
        <div class="header__headline--font">Detailed Statistics</div>
        <div class="header__description--font">
          Take a deep look at the data we have collected
        </div>
      </a-col>
    </a-row>
    <a-row :style="{ marginBottom: '30px' }">
      <a-col :span="24">
        <CategoriesChart
          v-if="challengeStats"
          :challengeStats="challengeStats"
        />
      </a-col>
    </a-row>
    <a-row :gutter="32">
      <a-col :lg="12" :xs="24" :style="{ marginBottom: '30px' }"
        ><ChallengesChart
          v-if="dashboardStats"
          :challengeGrowth="dashboardStats.challenge"
      /></a-col>
      <a-col :lg="12" :xs="24" :style="{ marginBottom: '30px' }">
        <UsersChart v-if="dashboardStats" :userGrowth="dashboardStats.user" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import OverviewCardList from "../components/analysis/OverviewCardList";
import CategoriesChart from "../components/analysis/CategoriesChart";
import ChallengesChart from "../components/analysis/ChallengesChart";
import UsersChart from "../components/analysis/UsersChart";
import { mapActions, mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Home"
    };
  },
  name: "Home",
  components: {
    OverviewCardList,
    CategoriesChart,
    ChallengesChart,
    UsersChart
  },
  data() {
    return {
      showDropdown: false,
      chosenPeriod: {
        value: "ONE_MONTH",
        label: "Last 30 days"
      },
      periodList: [
        { value: "ONE_MONTH", label: "Last 30 days" },
        { value: "TWO_MONTH", label: "Last 60 days" },
        { value: "THREE_MONTH", label: "Last 90 days" }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "challengeStats",
      "dashboardStats",
      "overviewStats",
      "statsLoading",
      "overviewLoading"
    ])
  },
  methods: {
    ...mapActions({
      fetchOverviewStats: "FETCH_OVERVIEW_STATS",
      fetchChallengeStats: "FETCH_CHALLENGE_STATS",
      fetchDashboardStats: "FETCH_DASHBOARD_STATS"
    }),
    setPeriod(period) {
      this.chosenPeriod = period;
      this.showDropdown = false;
      this.fetchOverviewStats({ timePeriod: this.chosenPeriod.value });
    }
  },
  created() {
    this.fetchOverviewStats({ timePeriod: this.chosenPeriod.value });
    this.fetchChallengeStats();
    this.fetchDashboardStats();
  }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";
@import "../styles/fonts.less";
@import "../styles/colors.less";

.ant-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  height: 38px;
  width: 150px;
  box-sizing: border-box;
  border-radius: 5px;
  .poppins-light(12px, @dark-blue);
  border: 1px solid #ebeff2;
  &:hover,
  &:focus {
    border: 1px solid #ebeff2;
  }
  > .anticon {
    color: @blue;
  }
}

.ant-menu {
  position: absolute;
  z-index: 3;
  width: 150px;
  left: 2px;
  top: 70px;
  border-radius: 5px;
}

.ant-menu-item {
  margin: 0px 0px !important;
  .poppins-light(12px, @dark-blue);
  text-transform: capitalize;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s linear;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-300px);
}
</style>
