<template>
  <base-modal
    :title="'Challenge Videos'"
    :closable="true"
    :modalVisible="modalVisible"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    :width="'500px'"
    @closeModal="$emit('closeVideoModal')"
  >
    <template v-slot:modal-content>
      <div class="video__container">
        <video controls autoplay width="350">
          <source :src="url" />
        </video>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../base/BaseModal.vue";

export default {
  metaInfo() {
    return {
      title: "Challenge Video Modal"
    };
  },
  name: "ChallengeVideoModal",
  props: ["url", "modalVisible", "collapsed"],
  components: {
    BaseModal
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "2.5%" : "8.5%"
      };
    }
  }
};
</script>

<style lang="less" scoped>
.video__container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
</style>
