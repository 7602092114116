<template>
  <div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">
        <div class="login100-form">
          <div class="login100-form-title p-b-43">
            <img
              alt="Youvme"
              :src="require('../assets/images/youvme_dark.svg')"
              width="200"
            />
          </div>
          <div style="text-align: center">
            <a-form :form="form" @submit="handleSubmit">
              <a-space size="middle" direction="vertical">
                <a-form-item style="width: 100%" class="login-input-wrapper">
                  <a-input
                    v-decorator="[
                      'email',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Email is required!'
                          }
                        ]
                      }
                    ]"
                    placeholder="Email"
                    size="large"
                  />
                </a-form-item>
                <a-form-item class="password-box">
                  <a-input-password
                    placeholder="Password"
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Password is required!'
                          }
                        ]
                      }
                    ]"
                    size="large"
                  />
                </a-form-item>
                <a-form-item>
                  <a-button
                    class="login-button"
                    type="primary"
                    html-type="submit"
                    :loading="loginLoading"
                  >
                    Login
                  </a-button>
                </a-form-item>
              </a-space>
            </a-form>
          </div>
        </div>
        <div class="login100-more"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { authAction } from "../store/actions";
import { checkAuthentication } from "../helpers";
import { mapState } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Login"
    };
  },
  name: "Login",
  components: {},
  beforeCreate() {
    if (checkAuthentication()) {
      this.$router.push("/admin");
      return;
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "login" })
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch(authAction.LOGIN_ADMIN, values);
        }
      });
    }
  },
  computed: {
    ...mapState({
      loginLoading: state => state.auth.loginLoading
    })
  }
};
</script>
<style lang="less" scoped>
@import url("../assets/css/login.css");
.login-button {
  width: 100%;
}
.container-login100 .ant-input-affix-wrapper {
  padding: 0 !important;
}
</style>
