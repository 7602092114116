<template>
  <div>
    <a-row type="flex" justify="space-between" class="header-container">
      <a-col :sm="24" :md="10">
        <div class="header__headline--font">Categories</div>
        <div class="header__description--font">
          All the YOUvME categories
        </div>
      </a-col>
      <a-col>
        <a-button
          class="button__state--secondary"
          @click="addModalVisible = true"
          >Add Category</a-button
        >
      </a-col>
    </a-row>
    <CategoryAddModal
      :modalVisible="addModalVisible"
      :collapsed="collapsed"
      @closeAddModal="addModalVisible = false"
      @categoryCreated="onCategoryAdded"
    />
    <CategoryEditModal
      v-if="editedCategory"
      :modalVisible="editModalVisible"
      :collapsed="collapsed"
      :category="editedCategory"
      @closeEditModal="onCloseEditModal"
    />
    <CategoryDeleteModal
      v-if="deletedCategory"
      :modalVisible="deleteModalVisible"
      :collapsed="collapsed"
      @closeDeleteModal="onCloseDeleteModal"
      :category="deletedCategory"
    />
    <a-spin :spinning="categoryLoading"></a-spin>
    <CategoryList :cardLiftable="true" v-if="!categoryLoading" />
  </div>
</template>

<script>
import CategoryList from "../components/categories/CategoryList.vue";
import CategoryAddModal from "../components/categories/CategoryAddModal.vue";
import CategoryEditModal from "../components/categories/CategoryEditModal.vue";
import CategoryDeleteModal from "../components/categories/CategoryDeleteModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Categories"
    };
  },
  name: "Categories",
  props: ["collapsed"],
  components: {
    CategoryList,
    CategoryAddModal,
    CategoryEditModal,
    CategoryDeleteModal
  },
  data() {
    return {
      loading: false,
      addModalVisible: false,
      editModalVisible: false,
      editedCategory: null,
      deleteModalVisible: false,
      deletedCategory: null
    };
  },
  computed: {
    ...mapGetters(["categoryLoading"])
  },
  methods: {
    ...mapActions({
      fetchCategories: "FETCH_CATEGORIES"
    }),
    onCategoryAdded() {
      this.addModalVisible = false;
      this.fetchCategories();
    },
    openEditForm(category) {
      this.editModalVisible = true;
      this.editedCategory = category;
    },
    openDeleteModal(category) {
      this.deleteModalVisible = true;
      this.deletedCategory = category;
    },
    onCloseEditModal() {
      this.editModalVisible = false;
      this.editedCategory = null;
    },
    onCloseDeleteModal() {
      this.deleteModalVisible = false;
      this.deletedCategory = null;
    }
  },
  provide() {
    return {
      openEditForm: this.openEditForm,
      openDeleteModal: this.openDeleteModal
    };
  },
  created() {
    this.fetchCategories();
  }
};
</script>

<style lang="less" scoped>
@import "../styles/fonts.less";
@import "../styles/colors.less";
@import "../styles/mixins.less";

.button__state--secondary {
  .secondary-button(150px);
}
</style>
