<template>
  <div>
    <a-row type="flex" class="header-container">
      <a-col>
        <div class="header__headline--font">New Challege</div>
        <div class="header__description--font">
          Create new challege
        </div>
      </a-col>
    </a-row>
    <a-spin :spinning="categoryLoading"></a-spin>

    <ChallengeFormNew v-if="!categoryLoading" />

  </div>
</template>

<script>
import ChallengeFormNew from "../components/challenges/ChallengeFormNew.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Challenges"
    };
  },
  name: "Challenges",
  components: {
    ChallengeFormNew
  },
  methods: {
    ...mapActions({
      fetchCategories: "FETCH_CATEGORIES"
    })
  },
  computed: {
    ...mapGetters(["categoryLoading"])
  },
  created() {
    this.fetchCategories();
  }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";
</style>
