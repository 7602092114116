import { request } from "../helpers";

export const getOverviewStats = async params =>
  request({
    method: "GET",
    url: "/analysis",
    params
  });

export const getDashboardStats = async () =>
  request({
    method: "GET",
    url: "/analysis/dashboard-analysis"
  });

export const getChallengeStats = async () =>
  request({
    method: "GET",
    url: "/analysis/analysis-challenge"
  });
