<template>
  <div class="chart-container">
    <div class="chart-name">Challenges growth</div>
    <LineChart :data="chartData" :labels="chartLabels" />
  </div>
</template>

<script>
import LineChart from "./LineChart";
import moment from "moment";
export default {
  metaInfo() {
    return {
      title: "Challenges Chart"
    };
  },
  name: "ChallengesChart",
  props: ["challengeGrowth"],
  components: {
    LineChart
  },
  computed: {
    chartLabels() {
      return this.challengeGrowth.map(stat =>
        moment.monthsShort(stat.month - 1)
      );
    },
    chartData() {
      return this.challengeGrowth.map(stat => stat.amount);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.chart-container {
  background-color: white;
  border-radius: 4px;
  padding: 30px;
}

.chart-name {
  .poppins-medium(16px, @main-dark);
  margin-bottom: 30px;
  line-height: 24px;
}
</style>
