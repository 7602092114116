<template>
  <div class="card-container">
    <a-row type="flex">
      <a-col flex="50px">
        <a-avatar :size="40" :src="challenger.userId.avatar">{{
          challenger.userId.username.slice(0, 2)
        }}</a-avatar>
      </a-col>
      <a-col flex="auto">
        <router-link :to="`/admin/users/${challenger.userId._id}`"
          ><div class="challenger-name">
            {{ challenger.userId.username }}
          </div></router-link
        >
        <div class="property-title role">{{ role }}</div>
      </a-col>
      <a-col flex="340px">
        <a-row>
          <a-col :span="6"
            ><div class="property-title stat">Total Likes</div>
            <div class="property-stat">{{ challenger.likes || 0 }}</div></a-col
          >
          <a-col :span="6"
            ><div class="property-title stat">Total Shares</div>
            <div class="property-stat">{{ shares || 0 }}</div></a-col
          >
          <a-col :span="6"
            ><div class="property-title stat">Total Votes</div>
            <div class="property-stat">{{ challenger.votes || 0 }}</div></a-col
          >
          <a-col :span="6"
            ><div class="property-title stat">Reports</div>
            <div class="property-stat">
              {{ challenger.reports || 0 }}
            </div></a-col
          >
        </a-row>
      </a-col>
    </a-row>
    <div class="challenger-expression">
      <div class="property-title">User's expression</div>
      <div>
        {{ challenger.userExpression }}
      </div>
    </div>
    <div>
      <div class="property-title" v-if="videoUrl">Case description</div>
      <a-row type="flex" align="middle" v-if="videoUrl">
        <a-col flex="40px">
          <img src="../../assets/images/mov.svg" alt="mov logo" />
        </a-col>
        <a-col class="file-container">
          <div v-if="!videoUrl" class="file-name">File Name</div>
          <div v-else class="file-name">{{ videoUrl }}</div>
          <!-- <div>
            <span class="file-size">File size</span>
            <span class="button-delete">Delete</span>
          </div> -->
        </a-col>
        <a-col flex="auto" class="align-end">
          <a-button class="button-play" @click="openVideoModal(videoUrl)"
            >Play Video</a-button
          >
        </a-col>
      </a-row>
    </div>
    <div v-if="showComments">
      <a-button
        class="button-view"
        @click="
          openCommentModal({
            userId: challenger.userId._id,
            username: challenger.userId.username
          })
        "
        >View Comments</a-button
      >
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Challengers Info Card"
    };
  },
  name: "ChallengersInfoCard",
  props: ["role", "challenger", "videoUrl", "shares"],
  inject: ["openCommentModal", "openVideoModal", "showComments"],
  data() {
    return {
      commentModalVisible: false
    };
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.card-container {
  background-color: @white;
  border-radius: 4px;
  padding: 20px;
  > .ant-row,
  .ant-row-flex {
    box-sizing: border-box;
    margin-bottom: 25px;
  }
  > div:nth-child(4) {
    box-sizing: border-box;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}

.property-title {
  .gilroy(14px, @dark);
  opacity: 0.5;
  letter-spacing: -0.03em;
  margin-bottom: 7px;
}

.challenger-name {
  .poppins-bold(14px, @blue);
  letter-spacing: 0.01em;
}

.role {
  text-transform: capitalize;
}

.stat {
  text-align: right;
}

.property-stat {
  .gilroy(14px, @dark);
  letter-spacing: -0.03em;
  text-align: right;
}

.challenger-expression {
  margin-bottom: 25px;
  > div:nth-child(2) {
    .poppins-medium(16px, @main-dark);
    letter-spacing: 0.01em;
  }
}

.file-container {
  max-width: 70%;
}

.file-name {
  .poppins-medium(14px, @main-dark);
  letter-spacing: 0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.file-size {
  .poppins-medium(12px, @main-dark);
  letter-spacing: 0.01em;
  opacity: 0.5;
  box-sizing: border-box;
  margin-right: 10px;
}

// .button-delete {
//   .poppins-medium(12px, @blue);
//   letter-spacing: 0.01em;
//   cursor: pointer;
// }

.button-play {
  height: 38px;
  border-radius: 5px;
  background-color: rgba(0, 183, 232, 0.05);
  border: none;
  .poppins-medium(12px, @blue);
  &:hover,
  &:focus {
    border: none;
  }
}

.button-view {
  .primary-button(162px);
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 700px) {
  .align-end {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}
</style>
