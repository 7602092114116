import Vue from "vue";
import Vuex from "vuex";

import {
  user,
  auth,
  category,
  challenge,
  flag,
  setting,
  analysis
} from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    auth,
    category,
    challenge,
    flag,
    setting,
    analysis
  }
});
