import { render, staticRenderFns } from "./CommentsControl.vue?vue&type=template&id=40f30fb8&scoped=true&"
import script from "./CommentsControl.vue?vue&type=script&lang=js&"
export * from "./CommentsControl.vue?vue&type=script&lang=js&"
import style0 from "./CommentsControl.vue?vue&type=style&index=0&id=40f30fb8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f30fb8",
  null
  
)

export default component.exports