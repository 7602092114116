<template>
  <div>
    <base-modal
      :modalVisible="modalVisible"
      :title="'Add User'"
      :maskStyle="maskStyle"
      :dialogStyle="dialogStyle"
      @closeModal="modalVisible = false"
      :width="'480px'"
    >
      <template v-slot:modal-content>
        <AddUserForm :visible="modalVisible" @submitForm="onSubmit" />
      </template>
    </base-modal>
    <a-row type="flex" justify="space-between" class="header-container">
      <a-col :span="6">
        <div class="header__headline--font">Users</div>
        <div class="header__description--font">
          All the YOUvME users
        </div>
      </a-col>
      <a-col>
        <a-button class="button__state--secondary" @click="modalVisible = true"
          >Add User</a-button
        >
        <a-button class="select_status" @click="showDropdown = !showDropdown"
          ><span>{{ this.chosenStatus }}</span> <a-icon type="down"
        /></a-button>
        <transition name="slide">
          <a-menu v-if="showDropdown">
            <a-menu-item
              v-for="status in statusList"
              :key="status"
              @click="setStatus(status)"
              >{{ status }}</a-menu-item
            >
          </a-menu>
        </transition>
      </a-col>
    </a-row>
    <UserTable
      :tableData="userData"
      :pageCount="pageCount"
      :loading="userLoading"
      @paginate="onPaginate"
    />
  </div>
</template>

<script>
import AddUserForm from "../components/usersList/AddUserForm.vue";
import UserTable from "../components/usersList/UserTable.vue";
import BaseModal from "../components/base/BaseModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Users",
  props: ["collapsed"],
  components: {
    AddUserForm,
    UserTable,
    BaseModal
  },
  data() {
    return {
      modalVisible: false,
      statusList: ["ACTIVE", "INACTIVE"],
      chosenStatus: "ACTIVE",
      showDropdown: false,
      currentPage: 1
    };
  },
  methods: {
    async onSubmit() {
      this.modalVisible = false;
      await this.fetchUsers({ page: 1, limit: 10 });
    },
    async onPaginate(currentPage) {
      this.loading = true;
      await this.fetchUsers({ page: currentPage, limit: 10 });
      this.loading = false;
    },
    setStatus(status) {
      this.chosenStatus = status;
      this.showDropdown = false;
      this.currentPage = 1;
      this.fetchUsers({
        page: this.currentPage,
        limit: 10,
        status: this.chosenStatus
      });
    },
    ...mapActions({
      fetchUsers: "FETCH_USERS"
    })
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      const marginTop = "60px";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft,
        "margin-top": marginTop
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "2.5%" : "8.5%"
      };
    },
    ...mapGetters(["users", "pageCount", "userLoading", "userData"])
  },
  created() {
    this.fetchUsers({
      page: this.currentPage,
      limit: 10,
      status: this.chosenStatus
    });
  }
};
</script>

<style lang="less" scoped>
@import "../styles/fonts.less";
@import "../styles/colors.less";
@import "../styles/mixins.less";

.select_status {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  height: 48px;
  width: 150px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ebeff2;
  &:hover,
  &:focus {
    border: 1px solid #ebeff2;
  }
  > .anticon {
    color: @blue;
  }
}

.ant-menu {
  position: absolute;
  z-index: 3;
  width: 150px;
  right: 0;
  top: 70px;
  border-radius: 5px;
  box-shadow: 0px 17px 20px rgba(50, 50, 50, 0.03);
}

.ant-menu-item {
  margin: 0px 0px !important;
  .poppins-light(12px, @dark-blue);
  text-transform: capitalize;
  &:hover {
    background: rgba(27, 160, 251, 0.4);
  }
  &:first-child {
    border-radius: 5px 5px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 5px 5px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s linear;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-300px);
}
.button__state--secondary {
  .secondary-button(130px);
}
</style>
