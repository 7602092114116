<template>
  <div>
    <a-spin :spinning="loading"></a-spin>
    <div v-if="flagInfo">
      <ChallengeSummary
        :challengeInfo="{
          ...flagInfo.challenge,
          status: flagInfo.status,
          created_at: flagInfo.created_at,
          reports: flagInfo.count
        }"
      >
        <template v-slot:button-group v-if="flagInfo.status == 'PENDING'">
          <a-button
            class="button-state--primary"
            @click="ignoreFlag"
            :loading="loading"
            >Ignore</a-button
          >
          <a-button
            class="button-state--red"
            @click="suspendFlag"
            :loading="loading"
            >Suspend</a-button
          >
        </template>
        <template v-slot:summary>
          <div class="complain-title">Complainer</div>
          <div class="complain-summary">
            <a-row
              class="group-user"
              type="flex"
              v-for="groupUser in flagInfo.groupUsers"
              :key="groupUser._id"
            >
              <a-col flex="80px">
                <a-avatar :size="60" :src="groupUser.user.avatar"></a-avatar>
              </a-col>
              <a-col flex="auto">
                <div>
                  <span class="complainer-name">{{
                    groupUser.user.username
                  }}</span>
                  <span class="complain-date">{{ flagDate }}</span>
                </div>
                <div class="complain-message">
                  {{
                    `@${groupUser.user.username} - "${groupUser.flagSelection.title}" of the user video that was flagged`
                  }}
                </div>
              </a-col>
            </a-row>
          </div>
        </template>
      </ChallengeSummary>
      <ChallengersInfo
        :owner="{ ...flagInfo.challenge.owner, reports: reportOwner }"
        :opponent="{ ...flagInfo.challenge.opponent, reports: reportOpponent }"
        :ownerVideoUrl="flagInfo.challenge.owner.videoUrl"
        :opponentVideoUrl="flagInfo.challenge.opponent.videoUrl"
        :shares="flagInfo.challenge.shares"
      />
      <ChallengeVideoModal
        :url="displayedVideo"
        :modalVisible="videoModalVisible"
        :collapsed="collapsed"
        @closeVideoModal="videoModalVisible = false"
      />
    </div>
  </div>
</template>

<script>
import ChallengeSummary from "../components/challengeDetail/ChallengeSummary.vue";
import ChallengersInfo from "../components/challengeDetail/ChallengersInfo.vue";
import ChallengeVideoModal from "../components/challengeDetail/ChallengeVideoModal.vue";
import { flagApi } from "../apis";
import getJoinedDate from "../utilities/getJoinedDate";
import { message } from "ant-design-vue";

export default {
  metaInfo() {
    return {
      title: "Flag Detail"
    };
  },
  name: "FlagDetail",
  props: ["collapsed"],
  components: {
    ChallengeSummary,
    ChallengersInfo,
    ChallengeVideoModal
  },
  data() {
    return {
      loading: false,
      flagInfo: null,
      displayedVideo: null,
      videoModalVisible: false,
      shareModalVisible: false,
      commentModalVisible: false
    };
  },
  computed: {
    flagDate() {
      return getJoinedDate(this.flagInfo.created_at);
    },
    reportOwner() {
      return this.flagInfo.flagPerson._id ==
        this.flagInfo.challenge.owner.userId._id
        ? this.flagInfo.groupUsers.length
        : this.flagInfo?.meta?.groupUsers?.length || 0;
    },
    reportOpponent() {
      return this.flagInfo.flagPerson._id ==
        this.flagInfo.challenge.opponent.userId._id
        ? this.flagInfo.groupUsers.length
        : this.flagInfo?.meta?.groupUsers?.length || 0;
    }
  },
  methods: {
    async getFlag(flagId) {
      this.loading = true;
      const { data, success } = await flagApi.getFlag({
        flagId,
        challengeId: this.$route.query.challengeId
      });
      this.loading = false;
      if (success) {
        this.flagInfo = data;
      }
    },
    async ignoreFlag() {
      this.loading = true;
      const { success } = await flagApi.updateFlag(this.flagInfo._id, "IGNORE");
      this.loading = false;
      if (success) {
        message.success("Flag ignored successfully");
      } else {
        message.error("Error while ignoring flag");
      }
    },
    async suspendFlag() {
      this.loading = true;
      const { success } = await flagApi.updateFlag(
        this.flagInfo._id,
        "SUSPEND"
      );
      this.loading = false;
      if (success) {
        message.success("Flag suspended successfully");
      } else {
        message.error("Error while suspending flag");
      }
    },
    openVideoModal(videoUrl) {
      this.displayedVideo = videoUrl;
      this.videoModalVisible = true;
    },
    openCommentModal() {
      this.commentModalVisible = true;
    }
  },
  provide() {
    return {
      openVideoModal: this.openVideoModal,
      openCommentModal: this.openCommentModal,
      showComments: false
    };
  },
  created() {
    this.getFlag(this.$route.params.id);
  }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";
@import "../styles/fonts.less";
@import "../styles/colors.less";

.button-state--primary {
  .primary-button(100px);
}

.button-state--red {
  .red-button(114px);
  margin-left: 20px;
}

.complain-summary {
  background-color: white;
  border-radius: 4px;
  padding: 20px 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
  max-height: 200px;
  overflow-y: scroll;
  .group-user {
    padding-bottom: 20px;
  }
}

.ant-col {
  height: auto;
  &:nth-child(1) {
    display: flex;
    justify-content: center;
  }
}

.complainer-name {
  .poppins-bold(14px, @blue);
  letter-spacing: 0.01em;
  margin-right: 10px;
  line-height: 21px;
}

.complain-date {
  .gilroy(14px, @dark);
  letter-spacing: -0.03em;
  opacity: 0.4;
  line-height: 17px;
}

.complain-message {
  .gilroy(14px, @dark);
  letter-spacing: -0.03em;
  line-height: 17px;
}

.complain-title {
  .gilroy(16px, @main-dark);
  margin-bottom: 20px;
  line-height: 19px;
}
</style>
