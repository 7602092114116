<template>
  <div>
    <a-row
      type="flex"
      align="middle"
      justify="space-between"
      class="header-container"
    >
      <a-col>
        <div class="header__headline--font">Edit Profile</div>
        <div class="header__description--font">Joined: {{ joinedDate }}</div>
      </a-col>
      <a-col>
        <a-button
          class="cancel-button"
          @click="$emit('closeEditForm')"
          :disabled="loading"
          >Cancel</a-button
        >
        <a-button
          class="save-button"
          html-type="submit"
          @click="onSubmit"
          :loading="loading"
          >Save Profile</a-button
        >
      </a-col>
    </a-row>
    <a-row type="flex" align="middle" class="edit-avatar">
      <a-col flex="100px" class="avatar-container">
        <a-avatar :size="60" :src="displayedAvatar">{{
          userInfo.username.slice(0, 2)
        }}</a-avatar>
      </a-col>
      <a-col flex="auto" class="avatar-info">
        <div>{{ fileName }}</div>
        <div>
          <span class="avatar-size" v-if="fileSize">{{ fileSize }} KB</span>
          <input
            type="file"
            id="upload"
            accept="image/*"
            @change="handleUpload"
          />
          <label for="upload">
            <span>
              <span
                class="upload-avatar-button"
                :class="{ 'no-avatar': !fileSize }"
                >Upload</span
              >
            </span>
          </label>
        </div>
      </a-col>
      <a-col :lg="{ offset: 10, span: 6 }" class="activation">
        <span>Account Activation</span>
        <a-switch v-model="userStatus"></a-switch>
      </a-col>
    </a-row>
    <div class="edit-fields">
      <a-form-model
        ref="userEditForm"
        @submit="onSubmit"
        :model="form"
        :rules="rules"
      >
        <a-row :gutter="32">
          <a-col :md="12" :sm="24">
            <base-form-item :prop="'firstName'" :label="'First Name'">
              <template v-slot:form-input>
                <a-input v-model="form.firstName" />
              </template>
            </base-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <base-form-item :prop="'lastName'" :label="'Last Name'">
              <template v-slot:form-input>
                <a-input v-model="form.lastName" />
              </template>
            </base-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <base-form-item :prop="'username'" :label="'Username'">
              <template v-slot:form-input>
                <a-input v-model="form.username" />
              </template>
            </base-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <base-form-item :prop="'email'" :label="'Email Address'">
              <template v-slot:form-input>
                <a-input v-model="form.email" />
              </template>
            </base-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <base-form-item :prop="'location'" :label="'Location'">
              <template v-slot:form-input>
                <a-input v-model="form.location" />
              </template>
            </base-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <base-form-item :prop="'phone'" :label="'Phone Number'">
              <template v-slot:form-input>
                <a-input v-model="form.phone" />
              </template>
            </base-form-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import BaseFormItem from "../base/BaseFormItem.vue";
import { userApi } from "../../apis";
import getJoinedDate from "../../utilities/getJoinedDate";
import { message } from "ant-design-vue";

export default {
  metaInfo() {
    return {
      title: "User Edit Form"
    };
  },
  name: "userEditForm",
  props: ["userInfo"],
  components: {
    BaseFormItem
  },
  data() {
    return {
      loading: false,
      avatar: this.userInfo.avatar,
      displayedAvatar: this.userInfo.avatar,
      fileSize: null,
      fileName: "User's Avatar",
      form: {
        firstName: this.userInfo.fullName
          ? this.userInfo.fullName.split(" ")[0]
          : "",
        lastName: this.userInfo.fullName
          ? this.userInfo.fullName.split(" ")[1]
          : "",
        email: this.userInfo.email ? this.userInfo.email : "",
        username: this.userInfo.username,
        location: this.userInfo.location ? this.userInfo.location : "",
        phone: this.userInfo.phone ? this.userInfo.phone : "+"
      },
      rules: {
        username: [
          {
            required: true,
            message: "Username cannot be empty."
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty."
          }
        ],
        location: {},
        phone: [
          {
            pattern: /^\+?[0-9]*$/,
            message: "Please type in a valid phone number"
          }
        ],
        firstName: {},
        lastName: {}
      },
      userStatus: this.userInfo.status.toLowerCase() === "active"
    };
  },
  computed: {
    joinedDate() {
      return getJoinedDate(this.userInfo.created_at);
    }
  },
  methods: {
    handleUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.avatar = file;
        this.fileName = file.name;
        this.fileSize = (file.size / 1000).toFixed(2);
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const imageBuffer = reader.result;
          this.displayedAvatar = imageBuffer;
        };
      }
    },
    async onSubmit(e) {
      e.preventDefault();
      this.$refs.userEditForm.validate(async valid => {
        if (valid) {
          let formData = new FormData();
          formData.append(
            "fullName",
            this.form.firstName + " " + this.form.lastName
          );
          formData.append("username", this.form.username);
          if (this.form.email !== "") {
            formData.append("email", this.form.email);
          }
          formData.append("phone", this.form.phone);
          formData.append("location", this.form.location);
          formData.append("status", this.userStatus ? "ACTIVE" : "INACTIVE");

          if (this.avatar !== this.displayedAvatar) {
            formData.append("avatar", this.avatar);
          }

          this.loading = true;
          const { success } = await userApi.updateUser(
            this.userInfo._id,
            formData
          );
          this.loading = false;
          if (success) {
            this.$emit("submitEditForm");
            message.success("User edited successfully");
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.cancel-button {
  .primary-button(104px);
}

.save-button {
  .secondary-button(130px);
  margin-left: 20px;
}

.edit-avatar {
  background-color: white;
  height: auto;
  padding: 15px;
  border-radius: 4px;
  > .ant-col:nth-child(1) {
    display: flex;
    justify-content: center;
  }
}

#upload {
  display: none;
}

.avatar-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  height: 60px;
  > div:nth-child(1) {
    .poppins-medium(14px, @main-dark);
    letter-spacing: 0.01em;
  }
}

@media screen and (max-width: 600px) {
  .avatar-info,
  .avatar-container {
    margin-bottom: 15px;
  }
}

.avatar-size {
  .poppins-medium(12px, @main-dark);
  letter-spacing: 0.01em;
  opacity: 0.3;
}

.upload-avatar-button,
.delete-avatar-button {
  cursor: pointer;
  color: @blue;
  display: inline-block;
  margin-left: 10px;
}

.no-avatar {
  margin-left: 0px;
}

.activation {
  display: flex;
  justify-content: center;
  > span:nth-child(1) {
    .gilroy(14px, @dark);
    letter-spacing: -0.03em;
    margin-right: 15px;
    opacity: 0.5;
  }
}

.ant-switch {
  width: 30px;
  height: 18px;
}

.ant-switch::after {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  top: -2.5px;
  left: -1px;
}

.ant-switch-checked::after {
  margin-left: 0px;
  left: 103%;
}

.ant-switch-checked {
  background-color: @green;
}

.edit-fields {
  background-color: @white;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 30px;
  padding: 15px 30px;
}

.form-label {
  .gilroy(14px, @dark);
  opacity: 0.5;
  letter-spacing: -0.03em;
}

.ant-input {
  .gilroy(14px, @main-dark);
  height: 48px;
}
</style>
