<template>
  <div class="ld_footer">
    <div class="logo_ft">
      <router-link to="/">
        <img
          class="youvme_logo"
          alt="Youvme"
          :src="require('../../assets/images/youvme.svg')"
        />
      </router-link>
      <a-button id="contact_us-button" @click="showModal">
        Contact us
      </a-button>
    </div>
    <div class="link_ft">
      <a-row>
        <a-col
          class="gutter-row"
          :lg="{ span: 20 }"
          :md="{ span: 24 }"
          :sm="{ span: 24 }"
          :xs="{ span: 24 }"
        >
          <div class="nav_ft">
            <a class="page_link_ft">About YOUvME</a>
            <router-link class="page_link_ft" :to="'/#get-app'"
              >Creating a Challenge</router-link
            >
            <router-link class="page_link_ft" :to="'/#joining-the-challenge'"
              >App Features</router-link
            >
            <router-link class="page_link_ft" :to="'/#what-you-can-find'"
              >Browse Categories</router-link
            >
            <router-link class="page_link_ft" :to="'/privacy'"
              >Privacy Policy</router-link
            >
            <router-link class="page_link_ft" :to="'/guidelines'"
              >Guidelines</router-link
            >
            <router-link class="page_link_ft" :to="'/terms'"
              >Terms of Use</router-link
            >
          </div>
        </a-col>
        <a-col
          class="gutter-row"
          :lg="{ span: 4 }"
          :md="{ span: 24 }"
          :sm="{ span: 24 }"
          :xs="{ span: 24 }"
        >
          <div class="social_icon_ft">
            <a class="logo_social_ft">
              <img
                class="social_logo"
                alt="Youtube"
                :src="require('../../assets/images/youtube.png')"
              />
            </a>
            <a class="logo_social_ft">
              <img
                class="social_logo"
                alt="Facebook"
                :src="require('../../assets/images/logo-facebook.png')"
              />
            </a>
            <a class="logo_social_ft">
              <img
                class="social_logo"
                alt="Twiter"
                :src="require('../../assets/images/logo-twitter.png')"
              />
            </a>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <div class="copyright-wraper">
          <div class="copyright">
            Copyright @ {{ copyrightYear }} YOUvME LLC. All rights reserved.
          </div>
          <div class="copyright">
            Made with ❤️ by
            <a href="https://www.wiredave.com/" target="_blank">Wired Avenue</a>
          </div>
        </div>
      </a-row>
    </div>
    <contact-form
      v-if="modalVisible"
      :title="'Contact Us'"
      :closable="true"
      :width="700"
      :modalVisible="modalVisible"
      @closeModal="onCloseModal"
    />
  </div>
</template>

<script>
import ContactForm from "../../components/landing/ContactForm.vue";

export default {
  metaInfo() {
    return {
      title: "Footer",
    };
  },
  components: {
    ContactForm,
  },
  data() {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      loadingBtn: false,
      modalVisible: false,
      form: {
        name: "",
        email: "",
      },
      visible: false,
      menuIcon: "menu",
      copyrightYear: new Date().getFullYear(),
      rules: {
        name: [
          {
            required: true,
            message: "Your name cannot be empty",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change",
          },
          {
            pattern: /\S+@\S+\.\S+/,
            message: "Please type in a valid email address",
          },
        ],
      },
    };
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../styles/mixins.less";
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
.row-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page_link_ft {
  color: #715fa6;
}
.ld_header_wrap {
  .header__landing--bg();
  .ld_header.vue-fixed-header--isFixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    background: #2f205b;
    z-index: 99;
  }
  .ld_header {
    padding: 30px 20px 30px;
    .youvme_logo {
      max-width: 120px;
    }
    .header_content {
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: center;
      .header_title {
        .gilroy-bold(3.5em, #fff);
      }
      .header_username {
        .gilroy-bold(1.2em, #8c8bbc);
      }
      .header_description {
        .gilroy-bold(1.2em, #fff);
      }
    }
  }
}
.ld_content {
  padding-top: 50px;
  padding-bottom: 80px;
  .ld_form {
    display: flex;
    justify-content: center;
  }
  .landing_page_form {
    min-width: 300px;
  }
  .landing_page_form .form-label {
    color: #2f205b !important;
    font-weight: bold;
  }

  .appstore-box {
    padding-top: 70px;
    display: flex;
    justify-content: center;
    text-align: center;
    p {
      color: #2f205b;
      font-weight: bold;
      .gilroy-bold(1.2em, #2f205b);
    }
  }
}
.ld_footer {
  .logo_ft {
    .youvme_logo {
      max-width: 120px;
    }
    padding: 30px 90px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #291b54;
    #contact_us-button {
      background: #322360;
      border: none;
      padding: 0px 40px;
      height: 40px;
      .gilroy-bold(1em, #fff);
    }
  }
  .link_ft {
    padding: 30px 90px;
    background: #2f205c;
    .nav_ft {
      display: flex;
      padding-top: 8px;
      .page_link_ft {
        .gilroy(1em, #715fa6);
        padding: 0px 30px;
      }
      .page_link_ft:nth-child(1) {
        padding-left: 0px !important;
      }
    }
    .social_icon_ft {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .copyright-wraper {
      padding-top: 30px;
      display: flex;
      justify-content: flex-start;
      .copyright {
        padding-right: 50px;
        .gilroy(1em, #4c3c7d);
        a {
          .gilroy(1em, #4c3c7d);
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .ld_header {
    padding-left: 0;
    padding-right: 0;
  }
}

@import "../../styles/responsive.less";
</style>
