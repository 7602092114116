import { flagApi } from "../../apis";
import { flagAction } from "../actions";
import { flagMutation } from "../mutations";
import getJoinedDate from "./../../utilities/getJoinedDate";

const initialState = {
  flags: [],
  flagPageCount: 0,
  flagCount: 0,
  flagLoading: false
};

export const state = { ...initialState };

export const actions = {
  async [flagAction.FETCH_FLAGS](context, params) {
    context.state.flagLoading = true;
    const { data, success, paginate } = await flagApi.fetchFlags(params);
    context.state.flagLoading = false;
    if (success) {
      context.commit(flagMutation.SET_FLAG_MUTATION, data);
      const flagPageCount = Math.ceil(paginate.total / 10);
      context.commit(flagMutation.SET_FLAG_PAGE_MUTATION, flagPageCount);
      context.commit(flagMutation.SET_FLAG_COUNT_MUTATION, paginate.total);
    }
  }
};

export const mutations = {
  [flagMutation.SET_FLAG_MUTATION](state, flags) {
    state.flags = flags;
  },
  [flagMutation.SET_FLAG_PAGE_MUTATION](state, flagPageCount) {
    state.flagPageCount = flagPageCount;
  },
  [flagMutation.SET_FLAG_COUNT_MUTATION](state, flagCount) {
    state.flagCount = flagCount;
  }
};

const getters = {
  flags(state) {
    return state.flags;
  },
  flagCount(state) {
    return state.flagCount;
  },
  flagLoading(state) {
    return state.flagLoading;
  },
  flagData(state) {
    return state.flags.map(flag => {
      return {
        key: flag._id,
        count: flag.count,
        status: flag.status.toLowerCase(),
        user: flag.flagPerson ? flag.flagPerson.username : "",
        challenge: flag.challenge ? flag.challenge.title : "",
        challengeId: flag.challenge ? flag.challenge._id : "",
        challenge_user: flag.challenge ? `${flag.challenge.owner?.userId?.username} vs ${flag.challenge.opponent?.userId?.username}` : "",
        category:
          flag.challenge && flag.challenge.category
            ? flag.challenge.category.title
            : "",
        date: getJoinedDate(flag.created_at)
      };
    });
  },
  flagPageCount(state) {
    return state.flagPageCount;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
