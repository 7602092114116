<template>
  <base-icon :viewBox="'0 0 16 16'">
    <template v-slot:icon-name>
      <path
        class="st0"
        :class="{ active: active }"
        d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z"
      />
      <path
        class="st0"
        :class="{ active: active }"
        d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z"
      />
      <path
        class="st0"
        :class="{ active: active }"
        d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z"
      />
      <path
        class="st0"
        :class="{ active: active }"
        d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z"
      />
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "../base/BaseIcon.vue";
export default {
  metaInfo() {
    return {
      title: "Users"
    };
  },
  name: "Users",
  props: ["active"],
  components: {
    BaseIcon
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";

.st0 {
  fill: #454176;
}

.active {
  fill: @blue;
}
</style>
