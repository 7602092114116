const getMonth = month => {
  switch (month) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "Jul";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    default:
      return "Dec";
  }
};

const getJoinedDate = dateString => {
  const dateInfo = dateString.slice(0, 10);
  const [year, month, day] = [...dateInfo.split("-")];
  return `${getMonth(month)} ${day}, ${year}`;
};

export default getJoinedDate;
