import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Users from "../views/Users.vue";
import UserProfile from "../views/UserProfile.vue";
import Login from "../views/Login.vue";
import Categories from "../views/Categories.vue";
import Challenges from "../views/Challenges.vue";
import Settings from "../views/Settings.vue";
import Flags from "../views/Flags.vue";
import FlagDetail from "../views/FlagDetail.vue";
import ChallengesByCategory from "../views/ChallengesByCategory.vue";
import ChallengeDetail from "../views/ChallengeDetail.vue";
import LandingPage from "../views/LandingPage.vue";
import Privacy from "../views/static_pages/Privacy.vue";
import Guidelines from "../views/static_pages/Guidelines.vue";
import Terms from "../views/static_pages/Terms.vue";
import NewChallenge from "../views/NewChallenge.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
    meta: {
      layoutDefault: false
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    meta: {
      layoutDefault: false
    }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      layoutDefault: false
    }
  },
  {
    path: "/guidelines",
    name: "Guidelines",
    component: Guidelines,
    meta: {
      layoutDefault: false
    }
  },
  {
    path: "/admin",
    name: "Home",
    component: Home,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/users",
    name: "Users",
    component: Users,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/users/:id",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layoutDefault: false
    }
  },
  {
    path: "/admin/categories",
    name: "Categories",
    component: Categories,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/challenges",
    name: "Challenges",
    component: Challenges,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/challenge/new",
    name: "NewChallenge",
    component: NewChallenge,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/challenges/:category",
    name: "ChallengesByCategory",
    component: ChallengesByCategory,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/challenges/:category/:id",
    name: "Challenge",
    component: ChallengeDetail,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/settings",
    name: "Settings",
    component: Settings,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/flags",
    name: "Flags",
    component: Flags,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "/admin/flags/:id",
    name: "FlagDetail",
    component: FlagDetail,
    meta: {
      layoutDefault: true
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
