<template>
  <a-layout-header :class="{ sidebarCollapsed: sidebarCollapsed }">
    <a-row type="flex" align="middle">
      <a-col flex="1 1 auto">
        <a-avatar class="search-icon-container">
          <a-icon slot="icon" type="search" />
        </a-avatar>
        <!-- test -->
        <div class="certain-category-search-wrapper" style="width: 100%">
          <a-auto-complete
            class="certain-category-search"
            dropdown-class-name="certain-category-search-dropdown"
            :dropdown-match-select-width="false"
            :dropdown-style="{ width: '300px' }"
            size="large"
            style="width: 100%"
            placeholder="Search for users or challenges"
            option-label-prop="value"
            :value="searchedValue"
            :open="searchedValue !== ''"
            @search="handleSearch"
            @select="handleSelect"
          >
            <template slot="dataSource">
              <a-select-opt-group
                key="Challenges"
                v-if="challengeSource.children.length > 0"
              >
                <a-select-option
                  v-for="opt in challengeSource.children"
                  :key="opt.id"
                  :value="opt.title"
                >
                  {{ opt.title }}
                </a-select-option>
              </a-select-opt-group>
              <a-select-opt-group
                key="Users"
                v-if="userSource.children.length > 0"
              >
                <a-select-option
                  v-for="opt in userSource.children"
                  :key="opt.id"
                  :value="opt.name"
                >
                  {{ opt.name }}
                </a-select-option>
              </a-select-opt-group>
            </template>
            <a-input />
          </a-auto-complete>
        </div>
      </a-col>
      <!--
        <a-col flex="0 1 50px">
        <span class="notification-badge" v-show="notifications.length > 0" />
        <a-dropdown placement="bottomRight">
          <a-button>
            <i class="fas fa-bell" />
          </a-button>

          <a-menu slot="overlay">
            <a-menu-item v-for="noti in notifications" :key="noti">
              <span>{{ noti }}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
      -->
      <a-col flex="0 1 50px">
        <a-dropdown placement="bottomRight">
          <a-avatar
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          ></a-avatar>
          <a-menu slot="overlay">
            <a-menu-item @click="logout" v-for="item in ['Logout']" :key="item">
              <span>{{ item }}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import { challengeApi } from "../apis";
import { userApi, settingApi } from "../apis";
import { removeItemLocalForage } from "../helpers";
import localForage from "localforage";

export default {
  name: "TheHeader",
  props: ["sidebarCollapsed"],
  data() {
    return {
      challengeSource: {
        title: "Challenges",
        children: []
      },
      userSource: {
        title: "Users",
        children: []
      },
      searchedValue: "",
      notifications: [
        "Silverfrog has just been added",
        "A post from yellow beaver was deleted",
        "Bluecat has just updated his account"
      ]
    };
  },
  methods: {
    async handleSearch(value) {
      this.searchedValue = value;
      if (value) {
        const {
          data: challenges,
          success: challengeSuccess
        } = await challengeApi.searchChallenge({
          keyword: value.trim(),
          page: 1,
          limit: 20
        });
        const { data: users, success: userSuccess } = await userApi.searchUser({
          keyword: value.trim(),
          page: 1,
          limit: 20
        });
        if (challengeSuccess) {
          this.challengeSource.children = challenges.map(el => {
            return {
              id: el._id,
              title: el.title,
              category: el.category ? el.category._id : "all"
            };
          });
        }
        if (userSuccess) {
          this.userSource.children = users.map(el => {
            return {
              id: el._id,
              name: el.username
            };
          });
        }
      }
    },
    handleSelect(value) {
      const challenge = this.challengeSource.children.find(
        challenge => challenge.title === value
      );
      const user = this.userSource.children.find(user => user.name === value);
      if (challenge) {
        this.searchedValue = challenge.title;
        this.challengeSource.children = [];
        this.userSource.children = [];
        if (
          this.$route.path !==
          `/admin/challenges/${challenge.category}/${challenge.id}`
        ) {
          this.$router.push(
            `/admin/challenges/${challenge.category}/${challenge.id}`
          );
        }
      }
      if (user) {
        this.searchedValue = user.name;
        this.challengeSource.children = [];
        this.userSource.children = [];
        if (this.$route.path !== `/admin/users/${user.id}`) {
          this.$router.push(`/admin/users/${user.id}`);
        }
      }
    },
    logout() {
      localForage
        .getItem("youvme_token_firebase")
        .then(token => {
          settingApi.deleteWebKeyToken({
            token
          });
        })
        .catch(err => console.log(err));

      localStorage.removeItem("youvme_token");
      document.cookie = "a_d=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      removeItemLocalForage("youvme_token");
      removeItemLocalForage("youvme_token_firebase");
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/fonts.less";
@import "../styles/colors.less";

.ant-layout-header {
  background-color: @white;
  padding: 0px 25px;
  height: 60px !important;
  position: fixed;
  top: 0px;
  width: calc(100% - 256px);
  z-index: 4;
  transition: all 0.1s linear !important;
  box-shadow: 0px 1px 10px @table-gray;
}

@media screen and (max-width: 460px) {
  .ant-layout-header {
    padding: 0px;
  }
  .search-icon-container {
    display: none;
  }
}

.sidebarCollapsed {
  width: calc(100% - 80px) !important;
}

.ant-row {
  height: 60px !important;
}
.ant-col {
  height: 60px !important;
  display: flex;
  align-items: center;
}
.ant-avatar {
  background-color: @white;
  cursor: pointer;
}
.anticon {
  color: @dark;
  opacity: 0.5;
}
.ant-btn {
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.fa-bell {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.notification-badge {
  background-color: @blue;
  border: 2px solid @white;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 15px;
  left: 15px;
}
.ant-input {
  border: none !important;
  .poppins-medium(13px, @main-dark);
  box-sizing: border-box;
  &:focus {
    border: none !important;
    box-shadow: none;
  }
}
</style>

<style lang="less">
@import "../styles/fonts.less";
@import "../styles/colors.less";
@import "../styles/mixins.less";

.certain-category-search-dropdown {
  .scroll-bar;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  .poppins-bold(12px, @dark);
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.ant-select-auto-complete.ant-select .ant-input {
  border: none !important;
  &:focus {
    border: none !important;
    box-shadow: none;
  }
}
.ant-select-selection__rendered {
  width: 100% !important;
}
.ant-select-combobox {
  height: auto !important;
  width: 100% !important;
}
.ant-select-dropdown
  .ant-select-dropdown--single
  .ant-select-dropdown-placement-bottomLeft {
  margin-top: 10px;
}
</style>
<style scoped>
.certain-category-search-wrapper
  >>> .certain-category-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input-suffix {
  right: 12px;
}
</style>
