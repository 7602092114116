<template>
  <div class="top-banner-wrapper">
    <div class="svg-container">
      <svg
        version="1.1"
        class="svg-container"
        :class="{ portrait }"
        id="boltSvgContainer"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1440 1024"
        enable-background="new 0 0 1440 1024"
        preserveAspectRatio="xMaxYMin meet"
      >
        <rect id="bg" fill="#201347" width="1440" height="1024" />
        <g>
          <rect
            v-for="index in 15"
            :key="`vertLine-${index}`"
            class="vert-line"
            :x="100 * (index - 1)"
            y="0"
            width="1"
            height="100%"
            fill="#423175"
          />
        </g>
        <g id="bottomBoltGroup">
          <image
            class="bolt-flash"
            id="boltFlash1"
            width="600"
            height="700"
            x="740"
            y="100"
            xlink:href="@/assets/images/bolt-flash.png"
          />
          <path
            id="bottomBolt"
            opacity="0"
            fill="#2F205B"
            d="M806.5,1024.9L1039.1,700c2.1-2.9,0-6.9-3.5-6.9l-44.1,0c-0.7,0-1-0.8-0.6-1.3L1292,310 c2.3-2.9,0.3-7.1-3.4-7.1h-77.2c-0.7,0-1-0.8-0.6-1.3L1439.9,0H0v1024.9H806.5z"
          />
          <path
            id="bottomBoltStroke"
            fill="none"
            stroke="#352563"
            stroke-width="2"
            opacity="0"
            d="M805.8,1024l232.4-324.6 c2.1-2.9,0-6.9-3.5-6.9h-44.1c-0.7,0-1-0.8-0.6-1.3l300.8-381.5c2.3-2.9,0.3-7.1-3.4-7.1h-77.1c-0.7,0-1-0.8-0.6-1.3L1438.6,0"
          />
          <path
            id="bottomBoltBlurStroke"
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            opacity="0"
            filter="url(#boltStrokeFilter)"
            d="M805.8,1024l232.4-324.6 c2.1-2.9,0-6.9-3.5-6.9h-44.1c-0.7,0-1-0.8-0.6-1.3l300.8-381.5c2.3-2.9,0.3-7.1-3.4-7.1h-77.1c-0.7,0-1-0.8-0.6-1.3L1438.6,0"
          />
        </g>
        <g id="topBoltGroup">
          <image
            class="bolt-flash"
            id="boltFlash2"
            width="400"
            height="400"
            x="840"
            y="0"
            xlink:href="@/assets/images/bolt-flash.png"
          />
          <image
            class="bolt-flash"
            id="boltFlash3"
            width="400"
            height="400"
            x="630"
            y="500"
            xlink:href="@/assets/images/bolt-flash.png"
          />
          <path
            id="topBolt"
            opacity="0"
            fill="#2F205B"
            d="M774.7,1024L0,1024.9V0h1285.5L1055,349c-2,3,0.2,6.9,3.8,6.8l94.6-3c0.7,0,1.1,0.7,0.7,1.3L889.6,733.2 c-2,2.9,0,6.9,3.6,6.9h49.4c0.4,0,0.8,0.4,0.8,0.8v1.5L774.7,1024z"
          />
          <path
            id="topBoltStroke"
            fill="none"
            stroke="#352563"
            stroke-width="2"
            opacity="0"
            d="M1284.4,0l-230.3,348.7 c-2,3,0.2,6.9,3.8,6.8l94.5-3c0.7,0,1.1,0.7,0.7,1.3L888.8,732.6c-2,2.9,0,6.9,3.6,6.9h49.4c0.4,0,0.8,0.4,0.8,0.8v1.5L774,1024"
          />
          <path
            id="topBoltBlurStroke"
            filter="url(#boltStrokeFilter)"
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            opacity="0"
            d="M1284.4,0l-230.3,348.7 c-2,3,0.2,6.9,3.8,6.8l94.5-3c0.7,0,1.1,0.7,0.7,1.3L888.8,732.6c-2,2.9,0,6.9,3.6,6.9h49.4c0.4,0,0.8,0.4,0.8,0.8v1.5L774,1024"
          />
        </g>
        <rect
          x="0"
          y="0"
          id="fadeRect1"
          fill="#2F205B"
          width="250"
          height="1024"
          opacity="0"
        />
        <rect
          x="0"
          y="0"
          id="fadeRect2"
          fill="#2F205B"
          width="250"
          height="1024"
          opacity="0"
        />
        <g id="textContainer" class="text-container">
          <text
            id="preTitle"
            class="hero-title-element"
            x="100"
            y="20"
            fill="#5A4988"
            font-family="Gilroy"
            font-weight="800"
            font-size="16"
            letter-spacing="8"
          >
            PREMIUM BRAGGING APP
          </text>
          <text
            id="titleOne"
            class="hero-title-element"
            x="100"
            y="111"
            fill="#FFFFFF"
            font-family="Gilroy"
            font-size="90"
            font-weight="800"
            letter-spacing="2"
          >
            Join the Greatest
          </text>
          <text
            id="titleTwo"
            class="hero-title-element"
            x="100"
            y="201"
            fill="#FFFFFF"
            font-family="Gilroy"
            font-size="90"
            font-weight="800"
            letter-spacing="2"
          >
            <tspan fill="#00B7E8">Challenges</tspan>
            <tspan dx="25">Platform</tspan>
          </text>
          <text id="postTitle">
            <tspan
              class="hero-title-element"
              x="100"
              y="269"
              fill="#8472B5"
              font-family="Gilroy"
              font-weight="400"
              font-size="20"
            >
              YOUvME is the ultimate challenge app. Compete with your friends to
              find out who's the best!
            </tspan>
            <tspan
              class="hero-title-element"
              x="100"
              y="298"
              fill="#8472B5"
              font-family="Gilroy"
              font-weight="400"
              font-size="20"
            >
              From singing, dancing or settling a dispute - the challenge is
              yours to discover.
            </tspan>
          </text>
          <g id=" button" class="hero-title-element">
            <rect
              x="100"
              y="340"
              width="200"
              height="60"
              ry="6"
              rx="6"
              fill="#00B7E8"
            />
            <text
              x="137"
              y="378"
              fill="#FFFFFF"
              font-family="Gilroy"
              font-weight="600"
              font-size="20"
              letter-spacing="2"
            >
              Get Started
            </text>
            <rect
              x="100"
              y="340"
              width="200"
              height="60"
              ry="6"
              rx="6"
              id="btnEventTarget"
              fill="none"
              pointer-events="fill"
              cursor="pointer"
              v-scroll-to="'#download-application'"
            />
          </g>
        </g>
        <g class="portrait-text-container">
          <text
            id="preTitle"
            class="hero-title-element-portrait"
            x="400"
            y="230"
            fill="#5A4988"
            font-family="Gilroy"
            font-weight="800"
            font-size="16"
            letter-spacing="2"
          >
            PREMIUM BRAGGING APP
          </text>
          <text
            id="titleOne"
            class="hero-title-element-portrait"
            x="400"
            y="340"
            fill="#FFFFFF"
            font-family="Gilroy"
            font-size="90"
            font-weight="800"
            letter-spacing="2"
          >
            Join the
          </text>
          <text
            id="titleTwo"
            class="hero-title-element-portrait"
            x="400"
            y="430"
            fill="#FFFFFF"
            font-family="Gilroy"
            font-size="90"
            font-weight="800"
            letter-spacing="2"
          >
            Greatest
          </text>
          <text
            id="titleTwo"
            class="hero-title-element-portrait"
            x="400"
            y="520"
            fill="#00B7E8"
            font-family="Gilroy"
            font-size="90"
            font-weight="800"
            letter-spacing="2"
          >
            Challenges
          </text>
          <text
            id="titleTwo"
            class="hero-title-element-portrait"
            x="400"
            y="610"
            fill="#FFFFFF"
            font-family="Gilroy"
            font-size="90"
            font-weight="800"
            letter-spacing="2"
          >
            Platform
          </text>
          <text id="postTitle">
            <tspan
              class="hero-title-element-portrait"
              x="400"
              y="670"
              fill="#8472B5"
              font-family="Gilroy"
              font-weight="400"
              font-size="20"
            >
              YOUvME is the ultimate challenge app. Compete with your
            </tspan>
            <tspan
              class="hero-title-element-portrait"
              x="400"
              y="700"
              fill="#8472B5"
              font-family="Gilroy"
              font-weight="400"
              font-size="20"
            >
              friends to find out who's the best! From singing, dancing
            </tspan>
            <tspan
              class="hero-title-element-portrait"
              x="400"
              y="730"
              fill="#8472B5"
              font-family="Gilroy"
              font-weight="400"
              font-size="20"
            >
              or settling a dispute - the challenge is yours to discover.
            </tspan>
          </text>
          <g id=" button" class="hero-title-element-portrait">
            <rect
              x="400"
              y="780"
              width="200"
              height="60"
              ry="6"
              rx="6"
              fill="#00B7E8"
            />
            <text
              x="444"
              y="818"
              fill="#FFFFFF"
              font-family="Gilroy"
              font-weight="600"
              font-size="20"
              letter-spacing="2"
            >
              Get Started
            </text>
            <rect
              x="400"
              y="780"
              width="200"
              height="60"
              ry="6"
              rx="6"
              id="btnEventTarget"
              fill="none"
              pointer-events="fill"
              cursor="pointer"
              v-scroll-to="'#download-application'"
            />
          </g>
        </g>
        <defs>
          <filter
            id="boltShadowFilter"
            x="-20%"
            y="-20%"
            width="140%"
            height="140%"
            filterUnits="objectBoundingBox"
            primitiveUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feDropShadow
              stdDeviation="20 10"
              in="SourceGraphic"
              dx="25"
              dy="1"
              flood-color="#000000"
              flood-opacity="0.3"
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              result="dropShadow2"
            />
          </filter>
          <filter
            id="boltStrokeFilter"
            x="-20%"
            y="-20%"
            width="140%"
            height="140%"
            filterUnits="objectBoundingBox"
            primitiveUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feGaussianBlur
              stdDeviation="3 3"
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              in="SourceGraphic"
              edgeMode="none"
              result="blur"
            />
          </filter>
          <pattern
            id="bgimg"
            x="0"
            y="0"
            width="400"
            height="400"
            patternUnits="userSpaceOnUse"
          >
            <image
              id="boltFlash"
              width="400"
              height="400"
              xlink:href="https://assets.codepen.io/33073/bolt-flash.png"
            />
          </pattern>
        </defs>
      </svg>
    </div>
    <div class="landing-container">
      <strong>Premium bragging app</strong>
      <h1>
        Be part of<wbr /> the greatest <br />
        <span>challenges</span> platform
      </h1>
      <p>
        YOUvME is the ultimate challenge app. Compete with your friends to find
        out who's the best! From singing, dancing or settling a dispute - the
        challenge is yours to discover.
      </p>
      <button v-scroll-to="'#download-application'">Get Started</button>
    </div>
    <div class="mouse-container">
      <lottie :options="{ animationData: scroll }" :width="40" :height="40" />
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import Lottie from "vue-lottie";
import scroll from "@/assets/animations/scroll.json";

export default {
  metaInfo() {
    return {
      title: "Top Banner"
    };
  },
  components: {
    Lottie
  },
  data() {
    return {
      portrait: false,
      scroll
    };
  },
  mounted() {
    // Bolt Flashes Timeline
    const boltFlashes = document.querySelectorAll(".bolt-flash");
    this.flashesTimeline = gsap
      .timeline({ paused: true, repeat: -1, repeatDelay: 1 })
      .to(boltFlashes[1], { duration: 0.1, opacity: 0.7 })
      .to(boltFlashes[1], { duration: 0.1, opacity: 0.3 })
      .to(boltFlashes[1], { duration: 0.07, opacity: 0.7 })
      .to(boltFlashes[1], { duration: 0.1, opacity: 0 })
      .addLabel("flash2", 1.5)
      .to(boltFlashes[2], { duration: 0.1, opacity: 0.7 }, "flash2")
      .to(boltFlashes[2], { duration: 0.1, opacity: 0.3 }, ">")
      .to(boltFlashes[2], { duration: 0.07, opacity: 0.7 }, ">")
      .to(boltFlashes[2], { duration: 0.1, opacity: 0 }, ">")
      .addLabel("flash3", 0.75)
      .to(boltFlashes[0], { duration: 0.1, opacity: 0.7 }, "flash3")
      .to(boltFlashes[0], { duration: 0.1, opacity: 0.3 }, ">")
      .to(boltFlashes[0], { duration: 0.07, opacity: 0.7 }, ">")
      .to(boltFlashes[0], { duration: 0.1, opacity: 0 }, ">")
      .to(boltFlashes[1], { duration: 0.1, opacity: 0.8 }, "flash3+=0.25")
      .to(boltFlashes[1], { duration: 0.1, opacity: 0.3 }, ">")
      .to(boltFlashes[1], { duration: 0.07, opacity: 0.85 }, ">")
      .to(boltFlashes[1], { duration: 0.1, opacity: 0 }, ">");
    // Bolt Paths Timeline
    this.boltPathsTimeline = gsap
      .timeline({
        paused: true,
        delay: 1.35,
        onComplete: () => {
          this.flashesTimeline.play();
        }
      })
      .to(["#topBolt", "#bottomBolt"], {
        opacity: 1,
        x: 0,
        ease: "power2.out",
        duration: 0.4,
        stagger: 0.1
      })
      .to(
        ["#topBoltStroke", "#bottomBoltStroke"],
        { opacity: 1, x: 0, ease: "power2.out", duration: 0.4, stagger: 0.1 },
        0
      )
      .to(
        ["#fadeRect1", "#fadeRect2"],
        {
          opacity: 1,
          xPercent: -100,
          duration: 0.4,
          ease: "power2.out",
          stagger: 0.1
        },
        0
      )
      .set("#topBolt", { attr: { filter: "url(#boltShadowFilter)" } }, 0.3)
      .set("#bottomBolt", { attr: { filter: "url(#boltShadowFilter)" } }, 0.4)
      .play();
    // Text Elements Tweens
    this.textsInitialTween = gsap.to(".hero-title-element", {
      y: 0,
      opacity: 1,
      duration: 0.35,
      stagger: 0.07,
      delay: 1
    });
    this.portraitTextsInitialTween = gsap.to(".hero-title-element-portrait", {
      y: 0,
      opacity: 1,
      duration: 0.35,
      stagger: 0.07,
      delay: 1
    });
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    this.flashesTimeline.pause().kill();
    this.boltPathsTimeline.pause().kill();
    this.textsInitialTween.pause().kill();
    this.portraitTextsInitialTween.pause().kill();
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      const { innerWidth, innerHeight } = window;
      const ratio = innerWidth / innerHeight;
      if (ratio > 1.2) {
        this.portrait = false;
      } else {
        this.portrait = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.top-banner-wrapper {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 5rem 20px 2rem;
  align-items: stretch;
  text-align: center;
  justify-content: center;

  .svg-container {
    display: none;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;

    svg {
      min-height: 100vh;
      height: auto;
      width: auto;
      position: absolute;
      right: 0;

      #textContainer {
        transform: translateY(200px);
      }
      &.portrait {
        right: unset;
        left: 50%;
        transform: translateX(-50%);
        .portrait-text-container {
          display: inline;
        }
      }
      &.portrait .text-container,
      .portrait-text-container {
        display: none;
      }
      .hero-title-element,
      .hero-title-element-portrait {
        transform: translateY(50px);
        opacity: 0;
      }
      .bolt-flash {
        opacity: 0;
      }
      .vert-line {
        opacity: 0.35;
      }
      #topBolt,
      #topBoltStroke,
      #bottomBolt,
      #bottomBoltStroke {
        transform: translateX(250px);
      }
    }
  }

  .mouse-container {
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 3.5rem;
    padding-bottom: 1rem;
    margin-top: 2rem;

    img {
      height: 100%;
    }
  }

  strong {
    font-weight: 800;
    font-size: 12px;
    line-height: 1.25;
    text-align: center;
    letter-spacing: 0.52em;
    text-transform: uppercase;
    margin: 0 0 32px;
    color: #5a4988;
  }

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 1.2;
    color: white;
    margin: 0 0 24px;

    span {
      color: #00b7e8;
    }
  }

  p {
    color: white;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 500;
    color: #8472b5;
    opacity: 0.5;
  }

  button {
    margin-top: 1em;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.25;
    color: white;
    background: #00b7e8;
    padding: 18px 45px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .top-banner-wrapper {
    height: 100vh;
    display: block;
    padding: 0;

    .landing-container {
      display: none;
    }

    .svg-container {
      display: block;
    }

    .mouse-container {
      position: absolute;
      margin-top: 0;
    }
  }
}
</style>
