import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSocialSharing from "vue-social-sharing";
import vWow from "./plugins/Wow";
import VueLazyload from "vue-lazyload";
import VueScrollTo from "vue-scrollto";

import {
  Layout,
  Button,
  Spin,
  Card,
  Divider,
  Col,
  Row,
  Table,
  Form,
  InputNumber,
  Tag,
  Menu,
  Icon,
  Avatar,
  Input,
  Badge,
  Dropdown,
  Pagination,
  Select,
  Space,
  Modal,
  Upload,
  FormModel,
  Switch,
  Skeleton,
  AutoComplete,
  Drawer
} from "ant-design-vue";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "verte/dist/verte.css";
import "./assets/css/stype.css";
import "./assets/css/animate.min.css";

Vue.component(Layout.name, Layout);
Vue.component("a-layout-sider", Layout.Sider);
Vue.component("a-layout-header", Layout.Header);
Vue.component("a-layout-content", Layout.Content);
Vue.component("a-layout-footer", Layout.Footer);
Vue.component(Button.name, Button);
Vue.component(Spin.name, Spin);
Vue.component(Card.name, Card);
Vue.component(Divider.name, Divider);
Vue.component(Col.name, Col);
Vue.component(Row.name, Row);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Tag.name, Tag);
Vue.component(Menu.name, Menu);
Vue.component("a-menu-item", Menu.Item);
Vue.component(Icon.name, Icon);
Vue.component(Avatar.name, Avatar);
Vue.component(Input.name, Input);
Vue.component(Badge.name, Badge);
Vue.component(Dropdown.name, Dropdown);
Vue.component(FormModel.name, FormModel);
Vue.component("a-input-password", Input.Password);

Vue.use(Drawer);
Vue.use(Table);
Vue.use(Select);
Vue.use(Modal);
Vue.use(Space);
Vue.use(Upload);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Switch);
Vue.use(Skeleton);
Vue.use(Pagination);
Vue.use(AutoComplete);

Vue.use(VueSocialSharing);
Vue.use(vWow);
Vue.use(VueLazyload);
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
