var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"joining-the-challenge landing-container"},[_c('div',{staticClass:"images"},[_c('div',{staticClass:"image rb",class:[{ active: _vm.isActive(1) }],on:{"mouseover":function () { return _vm.select(1); },"mouseleave":function () { return _vm.mouseleave(); }}},[_vm._m(0),(_vm.isActive(1))?_c('div',{staticClass:"info-user-desktop-only user-info-first"},[_vm._m(1),_c('h3',[_vm._v("@Bluebird")]),_c('p',[_vm._v("Great job! @Bluebird like your video challenge")])]):_vm._e()]),_c('div',{staticClass:"image desktop-only left",class:[{ active: _vm.isActive(2) }],on:{"mouseover":function () { return _vm.select(2); },"mouseleave":function () { return _vm.mouseleave(); }}},[_c('img',{attrs:{"src":require("@/assets/images/joining-the-challenges-2.jpg")}}),(_vm.isActive(2))?_c('div',{staticClass:"user-info-second"},[_vm._m(2),_vm._m(3),_c('star-rating',{attrs:{"star-size":10,"rating":5,"show-rating":false,"border-width":4,"read-only":true,"border-color":"#d8d8d8","rounded-corners":true,"star-points":[
            23,
            2,
            14,
            17,
            0,
            19,
            10,
            34,
            7,
            50,
            23,
            43,
            38,
            50,
            36,
            34,
            46,
            19,
            31,
            17
          ]}})],1):_vm._e()]),_c('div',{staticClass:"image desktop-only rr",class:[{ active: _vm.isActive(0) }],on:{"mouseover":function () { return _vm.select(0); },"mouseleave":function () { return _vm.mouseleave(); }}},[_c('img',{attrs:{"src":require("@/assets/images/joining-the-challenges-3.jpg")}}),(_vm.isActive(0))?_c('div',{staticClass:"info-user-desktop-only user-info-third"},[_vm._m(4),_vm._m(5)]):_vm._e()])]),_c('div',{staticClass:"section-wrapper"},[_vm._m(6),_c('div',{staticClass:"sections"},[_c('div',{staticClass:"section",class:[{ active: _vm.isActive(0) }],on:{"mouseover":function () { return _vm.select(0); },"mouseleave":function () { return _vm.mouseleave(); }}},[_c('h4',[_vm._v("Challenge your friends")]),_c('div',[_vm._v(" Download the app and start inviting your friends and family to the most exciting challenges platform. Invite your worthy opponents directly from the app! ")])]),_c('div',{staticClass:"section",class:[{ active: _vm.isActive(1) }],on:{"mouseover":function () { return _vm.select(1); },"mouseleave":function () { return _vm.mouseleave(); }}},[_c('h4',[_vm._v("Debate trending topics")]),_c('div',[_vm._v(" Get the dialog started by challenging your friends to trending topics. Whethere it's sports or politics, we welcome all friendly challenges. ")])]),_c('div',{staticClass:"section",class:[{ active: _vm.isActive(2) }],on:{"mouseover":function () { return _vm.select(2); },"mouseleave":function () { return _vm.mouseleave(); }}},[_c('h4',[_vm._v("Build a reputation")]),_c('div',[_vm._v(" Build a reputation by winning and voting on challenges. Got what it takes to get on a hot streak? ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',{staticClass:"rb"},[_c('source',{attrs:{"srcset":require("@/assets/images/joining-the-challenge-1-desktop.jpg"),"media":"(min-width: 768px)"}}),_c('img',{attrs:{"src":require("@/assets/images/joining-the-challenge-1-mobile.jpg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info__img"},[_c('img',{attrs:{"src":require("@/assets/images/avatar_circle_2.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('img',{attrs:{"src":require("@/assets/images/avatar_cicle.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("@Jane Cooper")]),_c('p',[_vm._v("@yellowfish")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info__img"},[_c('img',{attrs:{"src":require("@/assets/images/avatar_circle_3.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticStyle:{"color":"#978fad"}},[_c('span',{staticStyle:{"font-weight":"bold","color":"#2f205b"}},[_vm._v("Kristy Fox")]),_vm._v(" is a "),_c('span',{staticStyle:{"color":"#2f205b"}},[_vm._v("winner")]),_vm._v(" in the challenge "),_c('span',{staticStyle:{"color":"#2f205b"}},[_vm._v("Democrats vs Republicans")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('h3',[_vm._v("Joining the Challenge!")])])}]

export { render, staticRenderFns }