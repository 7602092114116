<template>
  <base-modal
    :title="'Delete User'"
    :modalVisible="modalVisible"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    @closeModal="$emit('closeModal')"
    :width="'480px'"
  >
    <template v-slot:modal-content>
      <div class="delete-info">
        <div>Are you sure you want to delete</div>
        <div class="delete-summary">
          <a-avatar :size="50" class="user-avatar" :src="userInfo.avatar">{{
            userInfo.name.slice(0, 2)
          }}</a-avatar>
          <div>{{ userInfo.name }}</div>
        </div>
        <div>
          <a-button
            class="delete-button"
            @click="onDeleteUser"
            :loading="isDeleting"
            >Yes, Delete</a-button
          >
          <a-button class="no-delete-button" @click="$emit('closeModal')"
            >No, Don't Delete</a-button
          >
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { userApi } from "../../apis";
import BaseModal from "../base/BaseModal.vue";
import { message } from "ant-design-vue";

export default {
  metaInfo() {
    return {
      title: "Delete User Modal"
    };
  },
  name: "DeleteUserModal",
  props: ["modalVisible", "maskStyle", "userInfo", "dialogStyle"],
  components: {
    BaseModal
  },
  data() {
    return {
      isDeleting: false
    };
  },
  methods: {
    async onDeleteUser() {
      this.isDeleting = true;
      const { success } = await userApi.deleteUser(this.userInfo.id);
      this.isDeleting = false;
      if (success) {
        message.success("User deleted successfully");
        this.$emit("closeModal");
        this.$router.replace("/admin/users");
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.delete-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 350px;
  > div:nth-child(1) {
    .gilroy(14px, @main-dark);
  }
}

.delete-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  .gilroy-bold(26px, @main-dark);
}

.delete-button {
  .red-button(127px);
  margin-right: 5px;
}

.no-delete-button {
  .secondary-button(135px);
  width: 135px;
  margin-left: 5px;
}
</style>
