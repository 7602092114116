<template>
  <base-modal
    :title="'Challenge Video'"
    :closable="true"
    :modalVisible="modalVisible"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    :width="''"
    @closeModal="$emit('closeVideoModal')"
  >
    <template v-slot:modal-content>
      <div class="video__container">
        <div @click.prevent.stop='selectVideo(index)' class='video__box'  v-for='(url, index) in urlList' :key='index'>
          <video  controls width="350" height="350">
            <source :src="url" />
          </video>
          <div :class='[{"selected": index == selectedVideo}, "video__checked"]'>&#10003;</div>
        </div>
      </div>
        
      <div class="button-container">
        <a-button class="button_submit" @click="submit" :loading="loading"
          >Submit</a-button
        >
      </div>
        
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../base/BaseModal.vue";

export default {
  metaInfo() {
    return {
      title: "Challenge List Video Modal"
    };
  },
  name: "ChallengeListVideoModal",
  props: ["urlList", "modalVisible", "collapsed"],
  components: {
    BaseModal
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft
      };
    },
    dialogStyle() {
      return {
        left: 0
      };
    }
  },
  data() {
    return {
      selectedVideo: null,
      loading: false,
    }
  },
  methods: {
    async selectVideo(index) {
      this.selectedVideo = index
    },

    async submit() {
      this.$emit('selectVideo', this.urlList[this.selectedVideo]);
    }
  }
};
</script>

<style lang="less" scoped>
.button-container {
  text-align: center
}
.button_submit {
  width: 157px;
  height: 48px;
  background-color: #2f4568;
  letter-spacing: 0.01em;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  border: none;
  margin-left: 20px;
}
.video__checked {
  
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  background: #f4f6f7;
  
  border-radius: 50%;
  padding: 4px 9px;
  color: black;
}

.video__checked.selected {
 background: #2f205b !important;
 color: white;
}

.video__box {
  padding-top: 50px;
  background: black;
  position: relative;
}
.video__container {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
</style>
