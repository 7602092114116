<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-col class="form__label" :xs="24" :md="8">
        <span>Default Challenge Duration</span>
      </a-col>
      <a-col :xs="16" :sm="12" :md="6" :lg="4">
        <base-select-dropdown
          :valueList="duration.options"
          :defaultValue="{ key: duration.challengeDuration }"
          @selected="onSelect"
        >
        </base-select-dropdown>
      </a-col>
    </a-row>

    <a-row type="flex" justify="space-between">
      <a-col class="form__label" :xs="24" :md="8">
        <span>Video Length</span>
      </a-col>
      <a-col :xs="16" :sm="12" :md="6" :lg="4">
        <a-input v-model="duration.videoLength" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import BaseSelectDropdown from "../base/BaseSelectDropdown.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Challenge Duration"
    };
  },
  name: "ChallengeDuration",
  components: {
    BaseSelectDropdown
  },
  computed: {
    ...mapGetters(["duration"]),
    ...mapGetters(["videoLength"])
    
    
  },
  methods: {
    ...mapActions({
      modifyActiveDuration: "MODIFY_ACTIVE_DURATION"
    }),
    onSelect(selected) {
      this.modifyActiveDuration(selected.key);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-row-flex {
  margin-bottom: 20px;
 
}

.form__label {
  display: flex;
  align-items: center;
  .gilroy(14px, rgba(47, 69, 104, 0.5));
}
</style>
