import { authApi, settingApi } from "../../apis";
import { authAction } from "../actions";
import { authMutation } from "../mutations";
import router from "../../router";
import { base } from "../../configs";
import { message } from "ant-design-vue";
import { setCookie } from "../../helpers";
import localForage from "localforage";
const initialState = {
  user: {},
  loginLoading: false
};
export const state = { ...initialState };
export const actions = {
  async [authAction.LOGIN_ADMIN](context, body) {
    context.commit(authMutation.FETCH_LOGIN_MUTATION);
    const { data, success } = await authApi.login(body);
    context.commit(authMutation.CHANGE_STATE_FETCH_LOGIN_MUTATION);
    if (success) context.commit(authMutation.UPDATE_ADMIN_INFO, data);
  }
};

export const mutations = {
  [authMutation.FETCH_LOGIN_MUTATION](state) {
    state.loginLoading = true;
  },
  [authMutation.CHANGE_STATE_FETCH_LOGIN_MUTATION](state) {
    state.loginLoading = false;
  },
  [authMutation.UPDATE_ADMIN_INFO](state, data) {
    if (data.hash != base.hash) {
      message.error("Unauthorized!");
      return;
    }
    setCookie({ key: "a_d", value: data.hash, days: 30 });
    localStorage.setItem("youvme_token", data.accessToken);
    localForage.setItem("youvme_token", data.accessToken);
    localStorage.setItem("user", JSON.stringify(data.user));
    localForage
      .getItem("token_firebase_new")
      .then(token => {
        if (token) {
          settingApi.updateWebKeyToken({
            token
          });
          localForage.setItem("youvme_token_firebase", token);
        }
      })
      .catch(err => console.log(err));

    state.user = data.user;
    router.push("/admin");
  }
};

const getters = {
  user(state) {
    return state.user;
  },
  loginLoading(state) {
    return state.loginLoading;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
