<template>
  <a-row :gutter="32">
    <a-col
      v-for="overview in overviewList"
      :key="overview.name"
      class="gutter-row"
      :lg="{ span: 6 }"
      :md="{ span: 8 }"
      :sm="{ span: 12 }"
      :xs="{ span: 24 }"
    >
      <OverviewCard
        :name="overview.name"
        :value="overview.value"
        :label="overview.label"
        :status="overview.status"
        :change="overview.change"
        :bgColor="overview.bgColor"
      />
    </a-col>
  </a-row>
</template>

<script>
import OverviewCard from "./OverviewCard.vue";
export default {
  metaInfo() {
    return {
      title: "Overview Card List"
    };
  },
  name: "OverviewCardList",
  props: ["overviewStats"],
  components: {
    OverviewCard
  },
  data() {
    return {
      extraData: {
        user: {
          label: "New Users",
          bgColor: "rgba(16,156,241, 0.1)"
        },
        challenge: {
          label: "New Challenges",
          bgColor: "rgba(247,104,91,0.1)"
        },
        vote: {
          label: "New Votes",
          bgColor: "rgba(247,104,91,0.1)"
        },
        comments: {
          label: "New Comments",
          bgColor: "rgba(248,186,93,0.1)"
        }
      }
    };
  },
  computed: {
    overviewList() {
      return Object.entries(this.overviewStats).map(([key, field]) => ({
        name: key,
        value: field.count,
        label: this.extraData[key].label,
        status: (field.percent > 0) | !field.percent ? "up" : "down",
        change: field.percent || 0,
        bgColor: this.extraData[key].bgColor
      }));
    }
  }
};
</script>

<style lang="scss" scoped></style>
