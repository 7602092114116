import { base } from "../configs";
import { message } from "ant-design-vue";
import axios from "axios";

const client = axios.create({
  baseURL: base.backendUrl,
  timeout: 300000
});
const request = ({
  method,
  url,
  data,
  params,
  headers,
  responseType,
  body,
  notRequiredToken,
  ...rest
}) => {
  const onSuccess = response => {
    return response.data;
  };
  const onError = error => {
    if (error.response) {
      message.error(error.response.data.error || error.response.data.data);
      return {
        message: error.response.data.error || error.response.data.data,
        success: false
      };
    }
    message.error(error.message);
    return { message: error.message, success: false };
  };

  return client({
    method,
    headers: {
      'Access-Control-Allow-Origin': '*',
      ...(localStorage.getItem("youvme_token") && !notRequiredToken
        ? { Authorization: `Bearer ${localStorage.getItem("youvme_token")}` }
        : {}),
      ...headers
    },
    url,
    params,
    data,
    body,
    ...(responseType ? { responseType } : {}),
    ...rest
  })
    .then(onSuccess)
    .catch(onError);
};

export default request;
