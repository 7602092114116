<template>
  <base-modal
    :title="'Delete Category'"
    :modalVisible="modalVisible"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    @closeModal="$emit('closeDeleteModal')"
    :width="'480px'"
  >
    <template v-slot:modal-content>
      <div class="summary">
        <div class="summary__question--text">
          Are you sure you want to delete
        </div>
        <div class="summary__container">
          <a-avatar
            v-if="category.icon"
            :size="50"
            class="user-avatar"
            :src="category.icon"
          >
          </a-avatar>
          <div class="summary__element--text">#{{ category.title }}</div>
        </div>
        <div>
          <a-button
            class="button__state--secondary"
            @click="onDeleteCategory"
            :loading="isDeleting"
            >Yes, Delete</a-button
          >
          <a-button
            class="button__state--red"
            @click="$emit('closeDeleteModal')"
            >No, Don't Delete</a-button
          >
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../base/BaseModal.vue";
import { categoryApi } from "../../apis";
import { message } from "ant-design-vue";
import { mapActions } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Category Delete Modal"
    };
  },
  name: "CategoryDeleteModal",
  props: ["category", "collapsed", "modalVisible"],
  components: {
    BaseModal
  },
  data() {
    return {
      isDeleting: false
    };
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      const marginTop = "60px";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft,
        "margin-top": marginTop
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "4%" : "8.5%"
      };
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: "FETCH_CATEGORIES"
    }),
    async onDeleteCategory() {
      this.isDeleting = true;
      const { success } = await categoryApi.deleteCategory(this.category.id);
      this.isDeleting = false;
      if (success) {
        message.success("Category deleted successfully");
        await this.fetchCategories();
        this.$emit("closeDeleteModal");
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.button__state--secondary {
  .secondary-button(127px);
}

.button__state--red {
  .red-button(135px);
  margin-left: 15px;
}

.summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 350px;
}

.summary__element--text {
  .gilroy-bold(26px, @main-dark);
  left: 30px;
}

.summary__question--text {
  .gilroy(14px, @main-dark);
}

.summary__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container__icon {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: @blue;
  margin-right: 0px;
  > img {
    width: 40%;
    height: 40%;
  }
}
</style>
