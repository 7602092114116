<template>
  <a-row :gutter="32">
    <a-col
      v-for="challenge in challenges"
      :key="challenge._id"
      class="gutter-row"
      :lg="{ span: 6 }"
      :md="{ span: 8 }"
      :sm="{ span: 12 }"
      :xs="{ span: 24 }"
    >
      <base-case-card
        :status="challenge.status.toLowerCase()"
        :caseId="challenge._id"
        :categoryId="challenge.category ? challenge.category._id : 'all'"
        :ownerAvatar="
          challenge.owner.userId ? challenge.ownerAvatar : null
        "
        :ownerName="
          challenge.owner.userId ? challenge.owner.userId.username : ''
        "
        :opponentAvatar="
          challenge.opponent.userId ? challenge.opponentAvatar : null
        "
        :opponentName="
          challenge.opponent.userId ? challenge.opponent.userId.username : ''
        "
        :title="challenge.title"
        :ownerWon="challenge.owner.status && challenge.owner.status === 'WON'"
      >
        <template v-slot:card-subtitle>
          <div class="card-info">
            <span
              >Voted:
              {{ challenge.owner.votes + challenge.opponent.votes }}</span
            >
          </div>
        </template>
      </base-case-card>
    </a-col>
  </a-row>
</template>

<script>
import BaseCaseCard from "../base/BaseCaseCard.vue";

export default {
  metaInfo() {
    return {
      title: "Challenge List"
    };
  },
  name: "ChallengeList",
  props: ["challenges"],
  components: {
    BaseCaseCard
  }
};
</script>

<style lang="less" scoped></style>
