<template>
  <div>
 
    <a-form-model ref="editChallengeForm" :model="form" :rules="rules">
      <a-row :gutter="48">
        <a-col :sm="24" :md="12">
          <base-form-item :prop="'title'" :label="'Case Title'">
            <template v-slot:form-input>
              <a-input v-model="form.title" />
            </template>
          </base-form-item>
        </a-col>

        <a-col :sm="24" :md="6">
          <base-form-item :label="'Case Category'">
            <template v-slot:form-input>
              <a-select v-model="categoryOnChange" :options="formatCategory"> </a-select>
            </template>
          </base-form-item>
        </a-col>
        


      </a-row>
      <a-row :gutter="48">
        <a-col :sm="24" :md="12" class="user-avatar-name">
          <div class="challenger-role">Owner</div>
          <ChallengeUser ref="challengeUserOwner" :type="'Owner'" />
          
        </a-col>
        <a-col :sm="24" :md="12" class="user-avatar-name">
          <div class="challenger-role">Challenges</div>
          <ChallengeUser ref="challengeUserOpponent" :type="'Opponent'" />
        </a-col>
        
    
      </a-row>
      

      <div style="text-align: center">
        <a-button class="button__state--secondary" @click="onSubmit" :loading="loading"
          >Create Challege</a-button
        >
      </div>
    </a-form-model>
    
     
  </div> 
</template>

<script>
import BaseFormItem from "../base/BaseFormItem.vue";
import mixin from '@/mixins/myMixins.js'
import { mapGetters } from "vuex";
import ChallengeUser from "../challengeDetail/ChallengeUser.vue";
import { challengeApi } from '../../apis';
import { message } from "ant-design-vue";

export default {
  mixins: [mixin],
  metaInfo() {
    return {
      title: "Challenge Edit Modal"
    };
  },
  name: "ChallengeNew",
  props: [
    "modalVisible",
    "collapsed",
    "challengeId",
    "title",
    "duration",
    "maxDuration",
    "owner",
    "opponent",
    "status"
  ],
  components: {
    ChallengeUser,
    BaseFormItem
  },
  computed: {
    ...mapGetters(["categoryData"]),
    formatCategory() {
      let p = this.categoryData.map(category => ({
        label: category.title,
        value: category.id
      }));
      return p.filter(category => category.value != 'all');
    }
  },
  data() {
    return {
      categoryOnChange: null,
      categoriesList: [],
      loading: false,
      ownerDetail: null,
      opponentDetail: null,
      form: {
        title: this.title,
        duration: this.formattedDuration,
      },
  
      rules: {
        title: [
          {
            required: true,
            message: "Case title cannot be empty",
            trigger: "change"
          }
        ],
      }
    };
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.$refs.editChallengeForm.validate(async valid => {
        this.loading = true;
        let ownerDetectSubmit = await this.$refs.challengeUserOwner.addUserForm();
        let opponentDetectSubmit = await this.$refs.challengeUserOpponent.addUserForm();
   
        if(valid && ownerDetectSubmit == 'success' && opponentDetectSubmit == 'success') {
          let fetchStatusOwner = await this.$refs.challengeUserOwner.onSubmit();
          let fetchStatusOpponent = await this.$refs.challengeUserOpponent.onSubmit();
          if(!fetchStatusOwner || !fetchStatusOpponent)  {
            this.loading = false;
            return;
          }
          let formSubmit = {
            "title": this.form.title,
            "category": this.categoryOnChange,
            "owner": {
              "userExpression": fetchStatusOwner.userExpression,
              "videoUrl": fetchStatusOwner.videoUrl,
              "userId": fetchStatusOwner.userId,
              thumbnail: fetchStatusOwner.thumbnail,
            },
            "opponent": {
              "userExpression": fetchStatusOpponent.userExpression,
              "videoUrl": fetchStatusOpponent.videoUrl,
              "userId": fetchStatusOpponent.userId,
              thumbnail: fetchStatusOpponent.thumbnail,
            },
            "hashtags": [
              "daily",
              "cuisine"
            ]
          }

          const { success } = await challengeApi.challengesAdminCreate(formSubmit);
          if(success) {
            message.success("Challenge created successfully");
            this.$router.push('/admin/challenges');
          }
          this.loading = false;
          return;
         
        } else {
          message.error("User created unsuccessfully");
          this.loading = false;
        }
        
      }) 
    },

   
   
  },
  mounted() {
    if(this.categoryData) {
      this.categoryOnChange = this.categoryData[1].id;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixins.less";
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.button__state--secondary {
  .secondary-button(146px);
}


.text-right {
  text-align: right;
}

.challenger-role {
  .gilroy(16px, @main-dark);
  box-sizing: border-box;
  margin-bottom: 20px;
}

.margin-left-auto {
  margin-left: auto;
}

.button-play {
  height: 38px;
  border-radius: 5px;
  background-color: rgba(0, 183, 232, 0.05);
  border: none;
  .poppins-medium(12px, @blue);
  &:hover,
  &:focus {
    border: none;
  }
}
.button-reset {
  position: absolute;
  z-index: 2;
  right: 5px;
  bottom: -10px;
  .function-button(100px);
}

.ant-row {
  box-sizing: border-box;
  margin-bottom: 20px;
}

.user-name {
  .poppins-bold(14px, @blue);
  box-sizing: border-box;
  margin-left: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  > .ant-btn:nth-child(3) {
    .red-button(166px);
    margin-left: 20px;
  }
  > .ant-btn:nth-child(1) {
    .primary-button(166px);
  }
  > .ant-btn:nth-child(2) {
    .secondary-button(157px);

    margin-left: 20px;
  }
}

.ant-divider {
  position: absolute;
  z-index: 2;
  height: 80%;
  width: 1px;
  background-color: @table-gray;
  opacity: 0.3;
  left: 49%;
}

@media screen {
  @media (max-width: 768px) {
    .ant-divider {
      display: none !important;
    }
  }
}

.ant-form-explain {
  position: absolute;
  z-index: 2;
}

.user-avatar-name {
  margin-bottom: 15px;
}
</style>
  