<template>
  <a-table
    :columns="columns"
    :data-source="tableData"
    :pagination="false"
    :loading="loading"
    :customRow="customRow"
  >
    <span
      slot="challenge"
      slot-scope="text, record"
      class="table__cell--blue"
      >{{ record.challenge }}</span
    >
    <span slot="complain" slot-scope="text, record" class="table__cell--dark">{{
      record.complain
    }}</span>

    <!-- <span slot="user" slot-scope="text, record" class="table__cell--blue">{{
      record.user
    }}</span> -->
    <span
      slot="challenge_user"
      slot-scope="text, record"
      class="table__cell--blue"
      >{{ record.challenge_user }}</span
    >
    <span
      slot="status"
      slot-scope="text, record"
      class="capitalize"
      :class="record.status"
      >{{ record.status }}</span
    >
  </a-table>
</template>

<script>
import { mapGetters } from "vuex";

const columns = [
  {
    title: "Challenges",
    key: "challenge",
    dataIndex: "challenge",
    scopedSlots: { customRender: "challenge" },
    ellipsis: true,
    width: "20%"
  },
  {
    title: "Reports",
    dataIndex: "count",
    key: "count",
    scopedSlots: { customRender: "count" },
    ellipsis: true,
    width: "10%"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
    ellipsis: true,
    width: "15%"
  },
  {
    title: "Challenge",
    dataIndex: "challenge_user",
    key: "challenge_user",
    scopedSlots: { customRender: "challenge_user" },
    ellipsis: true,
    width: "25%"
  },
  {
    title: "Category",
    key: "category",
    dataIndex: "category",
    ellipsis: true,
    width: "10%"
  },
  {
    title: "Date",
    key: "date",
    dataIndex: "date",
    ellipsis: true,
    width: "15%"
  }
];
export default {
  metaInfo() {
    return {
      title: "Flag Table"
    };
  },
  name: "FlagTable",
  props: ["tableData", "loading"],
  data() {
    return {
      columns
    };
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: () => {
            record.challenge &&
              this.$router.push(
                `/admin/flags/${record.key}?challengeId=${record.challengeId}`
              );
          }
        }
      };
    }
  },
  computed: {
    ...mapGetters(["flagChallengesData"])
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.table__cell--dark {
  .poppins-medium(14px, @main-dark);
  letter-spacing: 0.01em;
}

.table__cell--blue {
  .poppins-bold(13px, @blue);
  letter-spacing: 0.01em;
}

.capitalize {
  text-transform: capitalize;
}

.suspend {
  color: @red;
}

.pending {
  color: @warning;
}

.ignore {
  color: @green;
}
</style>
