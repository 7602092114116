<template>
  <div class="chart-container">
    <div class="chart-name">Categories</div>
    <BarChart
      :max="maxValue"
      :maxColumn="maxColumn"
      :labels="chartLabels"
      :data="chartData"
      :dataName="'Challenges'"
    />
  </div>
</template>

<script>
import BarChart from "./BarChart.vue";
export default {
  metaInfo() {
    return {
      title: "Categories Chart"
    };
  },
  name: "CategoriesChart",
  props: ["challengeStats"],
  components: {
    BarChart
  },
  computed: {
    chartLabels() {
      return this.challengeStats.map(stat => stat.title);
    },
    chartData() {
      return this.challengeStats.map(stat => stat.totalChallenge);
    },
    maxValue() {
      return Math.max(...this.chartData);
    },
    maxColumn() {
      return this.chartData.findIndex(data => data === this.maxValue);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.chart-container {
  background-color: white;
  border-radius: 4px;
  padding: 30px;
}

.chart-name {
  .poppins-medium(16px, @main-dark);
  margin-bottom: 30px;
  line-height: 24px;
}
</style>
