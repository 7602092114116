import Firebase from "firebase";
import "firebase/firebase-messaging";
import "firebase/analytics";
import "firebase/firestore";
import Vue from "vue";
const app = Firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_STORAGE_BUCKET,
  storageBucket: process.env.VUE_APP_PROJECT_ID,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
});
Firebase.analytics();
Vue.prototype.$analytics = Firebase.analytics();
if (Firebase.messaging.isSupported()) {
  const messageFirebase = Firebase.messaging();
  messageFirebase.onMessage(payload => {
    if (payload?.notification) {
      new Notification(payload?.notification?.title, {
        body: payload?.notification?.body
      });
    }
  });
}
export { app };
