import { request } from "../helpers";

export const fetchCategory = async () =>
  request({
    method: "GET",
    url: "/categories"
  });

export const getCategory = async categoryId =>
  request({
    method: "GET",
    url: `/categories/${categoryId}`
  });

export const createCategory = async payload =>
  request({
    method: "POST",
    url: "/categories",
    data: payload
  });

export const updateCategory = async (categoryId, payload) =>
  request({
    method: "PUT",
    url: `/categories/${categoryId}`,
    data: payload
  });

export const deleteCategory = async categoryId =>
  request({
    method: "DELETE",
    url: `/categories/${categoryId}`
  });
