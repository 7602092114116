<template>
  <div>
    <a-row type="flex" class="header-container">
      <a-col>
        <div class="header__headline--font">Challenges</div>
        <div class="header__description--font">
          Select a category below to view challenges
        </div>
      </a-col>
      <a-col style="margin-left: auto">
        <router-link :to="{name: 'NewChallenge'}">
          <a-button class="button__state--secondary"
            >New Challege</a-button
          >
        </router-link>
      </a-col>
    </a-row>
    <a-spin :spinning="categoryLoading"></a-spin>
    <ChallengeCategoryList v-if="!categoryLoading" />
  </div>
</template>

<script>
import ChallengeCategoryList from "../components/challenges/ChallengeCategoryList.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Challenges"
    };
  },
  name: "Challenges",
  components: {
    ChallengeCategoryList
  },
  methods: {
    ...mapActions({
      fetchCategories: "FETCH_CATEGORIES"
    })
  },
  computed: {
    ...mapGetters(["categoryLoading"])
  },
  created() {
    this.fetchCategories();
  }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";

.button__state--secondary {
  .secondary-button(146px);
}
</style>
