<template>
  <a-select label-in-value @change="handleChange" :defaultValue="defaultValue">
    <a-select-option
      v-for="value in valueList"
      :key="value.key"
      :value="value.key"
    >
      {{ value.label }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Base Select Dropdown"
    };
  },
  name: "BaseSelectDropdown",
  props: ["valueList", "defaultValue"],
  methods: {
    handleChange(selected) {
      this.$emit("selected", selected);
    }
  }
};
</script>

<style lang="less">
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-select-arrow {
  color: @blue;
}

.ant-select {
  .gilroy(14px, @main-dark);
  width: 100%;
  height: 48px;
}

.ant-select-selection {
  height: 100%;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.ant-select-dropdown-menu-item {
  .poppins-medium(12px, @blue);
  text-transform: capitalize;
}
</style>
