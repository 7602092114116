<template>
  <div>
    <div
      v-for="account in accounts"
      :key="account.name"
      class="account__container"
    >
      <a-row type="flex">
        <a-col flex="90px" class="brand__icon">
          <img
            :src="
              require(`../../assets/images/${account.name.toLowerCase()}.svg`)
            "
            alt="brand-icon"
          />
        </a-col>
        <a-col class="brand__info" flex="auto">
          <div class="brand__info--title">{{ account.name }}</div>
          <!-- <div class="brand__info--subTitle">
            {{ account.message }}
          </div> -->
        </a-col>
      </a-row>
      <a-row :gutter="32">
        <a-col :md="24" :lg="12">
          <base-form-item :label="'Account SID'">
            <template v-slot:form-input>
              <a-input v-model="account.account" /> </template
          ></base-form-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <base-form-item :label="'Authentication Token'">
            <template v-slot:form-input>
              <a-input v-model="account.token" /> </template
          ></base-form-item>
        </a-col>
      </a-row>

      <a-row v-if='account.name == "TWILIO"' :gutter="32">
        <a-col :md="24" :lg="12">
          <base-form-item :label="'SMS requests per phone number per day'">
            <template v-slot:form-input>
              <a-input v-model="rateLimit.rateLimitPerPhoneNumber" /> </template
          ></base-form-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <base-form-item :label="'SMS messages per IP per day '">
            <template v-slot:form-input>
              <a-input v-model="rateLimit.rateLimitPerIP" /> </template
          ></base-form-item>
        </a-col>
      </a-row>


    </div>
  </div>
</template>

<script>
import BaseFormItem from "../base/BaseFormItem.vue";
import { mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Linked Account"
    };
  },
  components: { BaseFormItem },
  name: "LinkedAccount",
  computed: {
    ...mapGetters(["accounts", "rateLimit"])
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.brand__icon {
  height: 60px;
  > img {
    height: 100%;
  }
}

.brand__info {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.brand__info--title {
  .gilroy(18px, @main-dark);
}

.brand__info--subTitle {
  .gilroy(14px, rgba(47, 69, 104, 0.5));
}

.account__container {
  box-sizing: border-box;
  margin-bottom: 30px;
}
</style>
