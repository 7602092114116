import { render, staticRenderFns } from "./OverviewCardList.vue?vue&type=template&id=289d4308&scoped=true&"
import script from "./OverviewCardList.vue?vue&type=script&lang=js&"
export * from "./OverviewCardList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289d4308",
  null
  
)

export default component.exports