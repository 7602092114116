<template>
  <a-row
    :gutter="24"
    type="flex"
    :style="{
      width: '100%'
    }"
  >
    <a-col flex="50px">
      <a-avatar :size="40" :src="comment.avatar">{{
        comment.fullName.slice(0, 2)
      }}</a-avatar>
    </a-col>
    <a-col class="comment-wrapper">
      <a-row type="flex" justify="space-between">
        <a-col>
          <router-link :to="`/admin/users/${comment.userId}`"
            ><span class="comment-name">{{
              comment.fullName
            }}</span></router-link
          >
          <span class="comment-date">{{ convertTime }}</span>
        </a-col>
        <!-- <a-col class="comment-delete" @click="removeComment">Delete</a-col> -->
      </a-row>
      <p class="comment-content">{{ comment.message }}</p>
      <!-- <a-divider type="horizontal"></a-divider> -->
    </a-col>
  </a-row>
</template>

<script>
import moment from "moment";
import { removeCommentChallenge } from "../../helpers";

export default {
  metaInfo() {
    return {
      title: "Challenge Comment Item"
    };
  },
  name: "ChallengeCommentItem",
  props: ["comment", "challengeId"],
  computed: {
    convertTime() {
      return moment(
        this.comment.createAt,
        "YYYY-MM-DDTHH:mm:ss.SSSSZ"
      ).fromNow();
    }
  },
  methods: {
    async removeComment() {
      await removeCommentChallenge({
        challengeId: this.challengeId,
        commentRef: this.comment.userId,
        key: this.comment.key
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.comment-wrapper {
  margin-right: 10px;
  width: calc(100% - 80px);
}

.comment-name {
  .poppins-bold(14px, @blue);
  box-sizing: border-box;
  margin-right: 15px;
}

.comment-date {
  .gilroy(14px, @dark);
  letter-spacing: -0.03em;
  opacity: 0.3;
}

.comment-delete {
  .poppins-light(13px, @blue);
  letter-spacing: 0.01em;
  cursor: pointer;
}

.comment-content {
  .gilroy(14px, @dark);
  letter-spacing: -0.03em;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(211, 209, 209);
}

@media screen {
  @media (max-width: 450px) {
    .ant-avatar {
      display: none;
    }
  }
}
</style>
