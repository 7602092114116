<template>
  <div>
    <a-row :gutter="32">
      <a-col
        v-for="challenge in challenges"
        :key="challenge.id"
        class="gutter-row"
        :lg="{ span: 6 }"
        :md="{ span: 8 }"
        :sm="{ span: 12 }"
        :xs="{ span: 24 }"
      >
        <base-case-card
          :status="challenge.status.toLowerCase()"
          :caseId="challenge.id"
          :categoryId="challenge.category ? challenge.category._id : 'all'"
          :ownerAvatar="
            challenge.owner.userId ? challenge.ownerAvatar : null
          "
          :ownerName="
            challenge.owner.userId ? challenge.owner.userId.username : ''
          "
          :opponentAvatar="
            challenge.opponent.userId ? challenge.opponentAvatar : null
          "
          :opponentName="
            challenge.opponent.userId ? challenge.opponent.userId.username : ''
          "
          :title="challenge.title"
          :ownerWon="challenge.owner.status && challenge.owner.status === 'WON'"
        >
          <template v-slot:card-subtitle>
            <div class="card-info">
              <span>vs.</span>
              <span>{{
                challenge.opponent.userId
                  ? challenge.opponent.userId.username
                  : ""
              }}</span>
            </div>
          </template>
        </base-case-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import BaseCaseCard from "../base/BaseCaseCard.vue";

export default {
  metaInfo() {
    return {
      title: "User Cases"
    };
  },
  name: "UserCases",
  props: ["challenges"],
  components: {
    BaseCaseCard
  }
};
</script>

<style lang="less">
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.card-info {
  > span:nth-child(1) {
    .gilroy(15px, @dark);
    letter-spacing: -0.03em;
    opacity: 0.5;
    box-sizing: border-box;
    margin-right: 10px;
  }
  > span:nth-child(2) {
    .poppins-bold(14px, @blue);
    letter-spacing: 0.01em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
  }
}
</style>
