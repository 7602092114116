<script>
import { Line } from "vue-chartjs";

export default {
  metaInfo() {
    return {
      title: "Line Chart"
    };
  },
  extends: Line,
  props: ["data", "labels"],
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: "Challenges",
            data: this.data,
            fontColor: "red",
            backgroundColor: "transparent",
            borderColor: "#109CF1",
            borderWidth: 4,
            pointBackgroundColor: "#4CB5F5",
            pointRadius: 8,
            pointBorderWidth: 4,
            pointBorderColor: "white",
            pointHoverRadius: 8,
            pointHoverBorderWidth: 4,
            pointHoverBorderColor: "white",
            lineBackgroundColor: "red"
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                padding: 15,
                fontSize: 13,
                fontFamily: "Gilroy-Medium",
                fontColor: "rgba(47,69,104,0.5)"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 8],
                color: "#E6E6E6",
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                padding: 30,
                fontSize: 13,
                fontFamily: "Poppins, sans-serif",
                fontColor: "#707683"
              }
            }
          ]
        }
      }
    );
  }
};
</script>
