<template>
  <div>
    <a-spin :spinning="challengeLoading"></a-spin>
    <div v-if="!challengeLoading">
      <a-row type="flex" justify="space-between">
        <a-col :span="12">
          <a-button
            class="back-button"
            @click="$router.push('/admin/challenges')"
            ><a-icon type="left" />
            Back to categories
          </a-button>
          <span>{{ challengeCount }}</span>
          <span>Challenges</span>
        </a-col>
        <a-col>
          <a-button @click="showDropdown = !showDropdown"
            ><span>{{ this.chosenStatus }}</span> <a-icon type="down"
          /></a-button>
          <transition name="slide">
            <a-menu v-if="showDropdown">
              <a-menu-item
                v-for="status in statusList"
                :key="status"
                @click="setStatus(status)"
                >{{ status }}</a-menu-item
              >
            </a-menu>
          </transition>
        </a-col>
      </a-row>
      <ChallengeList :challenges="newChallenges" />
    </div>
    <a-row type="flex" justify="end" style="margin-top: 30px">
      <a-pagination
        v-if="challengeCount > 0"
        :current="currentPage"
        :page-size="12"
        :total="challengePageCount * 12"
        @change="onChange"
      ></a-pagination>
    </a-row>
  </div>
</template>

<script>
import ChallengeList from "../components/challengeByCategory/ChallengeList.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Challenges By Category"
    };
  },
  name: "ChallengesByCategory",
  components: {
    ChallengeList
  },
  data() {
    return {
      statusList: [
        "all",
        "active",
        "ended",
        "pending",
        "cancelled",
        "suspended",
        "deleted"
      ],
      showDropdown: false,
      chosenStatus: "all challenges",
      currentPage: 1,
      category: this.$route.params.category
    };
  },
  methods: {
    ...mapActions({
      fetchChallengeByCategory: "FETCH_CHALLENGES_BY_CATEGORY",
      fetchChallenges: "FETCH_CHALLENGES"
    }),
    setStatus(status) {
      this.chosenStatus = status;
      this.showDropdown = false;
      this.fetchChallengeByCategory({
        categoryId: this.$route.params.category,
        params: {
          status:
            (this.chosenStatus !== "all") &
            (this.chosenStatus !== "all challenges")
              ? this.chosenStatus.toUpperCase()
              : null,
          page: 1,
          limit: 12
        }
      });
      this.currentPage = 1;
    },
    onChange(currentPage) {
      this.currentPage = currentPage;
      this.fetchChallengeByCategory({
        categoryId: this.category,
        params: {
          status:
            (this.chosenStatus !== "all") &
            (this.chosenStatus !== "all challenges")
              ? this.chosenStatus.toUpperCase()
              : null,
          page: currentPage,
          limit: 12
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      "challenges",
      "challengePageCount",
      "challengeLoading",
      "challengeCount"
    ]),
    newChallenges() {
      return this.challenges;
    }
    
  },
  created() {
    this.fetchChallengeByCategory({
      categoryId: this.$route.params.category,
      params: {
        page: 1,
        limit: 12
      }
    });
  }
};
</script>

<style lang="less" scoped>
@import "../styles/fonts.less";
@import "../styles/colors.less";

.info--text {
  .gilroy(24px, @main-dark);
  letter-spacing: 0.01em;
}

.back-button {
  width: 185px;
  height: 48px;
  background-color: rgba(47, 69, 104, 0.05);
  border-radius: 5px;
  .poppins-medium(12px, @main-dark);
  letter-spacing: 0.01em;
  border: none;
  &:hover,
  &:focus {
    border: none;
  }
}

.ant-row-flex {
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 30px;
}

.ant-col:nth-child(1) {
  display: flex;
  align-items: center;
  > span:nth-child(2) {
    .gilroy(24px, @blue);
    margin-left: 20px;
  }
  > span:nth-child(3) {
    .gilroy(24px, @main-dark);
    margin-left: 8px;
  }
}

.ant-col:nth-child(2) {
  display: flex;
  align-items: center;
  > .ant-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    height: 38px;
    width: 168px;
    box-sizing: border-box;
    border-radius: 5px;
    .poppins-light(12px, @dark-blue);
    border: 1px solid #ebeff2;
    &:hover,
    &:focus {
      border: 1px solid #ebeff2;
    }
    > .anticon {
      color: @blue;
    }
  }
}

.ant-menu {
  position: absolute;
  z-index: 3;
  width: 150px;
  left: 2px;
  top: 58px;
  border-radius: 5px;
}

.ant-menu-item {
  margin: 0px 0px !important;
  .poppins-light(12px, @dark-blue);
  text-transform: capitalize;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s linear;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-300px);
}
</style>
