import { settingApi } from "../../apis";
import { settingMutation } from "../mutations";
import { settingAction } from "../actions";
import { message } from "ant-design-vue";

const initialState = {
  settings: null,
  notifications: null,
  flagSetting: null,
  commentSetting: null,
  accounts: null,
  duration: null,
  settingLoading: false,
  settingSaving: false,
  rateLimit: null
};

export const state = { ...initialState };

export const actions = {
  async [settingAction.FETCH_SETTINGS](context) {
    context.state.settingLoading = true;
    const { data, success } = await settingApi.fetchSettings();
    context.state.settingLoading = false;
    if (success) {
      context.commit(settingMutation.SET_SETTING_MUTATION, data);
      context.commit(
        settingMutation.SET_NOTIFICATION_MUTATION,
        data.notifications
      );
      context.commit(settingMutation.SET_FLAGSETTING_MUTATION, data.flags);
      context.commit(settingMutation.SET_ACCOUNT_MUTATION, data.accounts);
      context.commit(settingMutation.SET_RATE_LIMIT, {rateLimitPerPhoneNumber: data.rateLimitPerPhoneNumber, rateLimitPerIP: data.rateLimitPerIP});

      context.commit(settingMutation.SET_DURATION_MUTATION, {
        challengeDuration: data.challengeDuration,
        videoLength: data.videoLength,
        dataChallengeDuration: data.dataChallengeDuration
      });
      context.commit(
        settingMutation.SET_COMMENTSETTING_MUTATION,
        data.comments
      );
    }
  },
  [settingAction.MODIFY_ACCOUNTS](context, payload) {
    context.commit(settingMutation.SET_ACCOUNT_MUTATION, payload);
  },
  [settingAction.MODIFY_ACTIVE_DURATION](context, payload) {
    context.commit(settingMutation.SET_ACTIVE_DURATION_MUTATION, payload);
  },
  async [settingAction.SAVE_CHANGES]({ state }) {
    const payload = {
      notifications: state.notifications,
      accounts: state.accounts,
      flags: state.flagSetting,
      comments: state.commentSetting,
      challengeDuration: state.duration.challengeDuration,
      videoLength: +state.duration.videoLength,
      rateLimitPerPhoneNumber: +state.rateLimit.rateLimitPerPhoneNumber,
      rateLimitPerIP: +state.rateLimit.rateLimitPerIP,
    };
    state.settingSaving = true;
    const { success } = await settingApi.updateSettings(payload);
    state.settingSaving = false;
    if (success) message.success("Change saved successfully");
  }
};

export const mutations = {
  [settingMutation.SET_RATE_LIMIT](state, settings) {
    state.rateLimit = settings;
  },
  [settingMutation.SET_NOTIFICATION_MUTATION](state, notifications) {
    state.notifications = notifications;
  },
  [settingMutation.SET_FLAGSETTING_MUTATION](state, flags) {
    state.flagSetting = flags;
  },
  [settingMutation.SET_ACCOUNT_MUTATION](state, accounts) {
    state.accounts = accounts;
  },
  [settingMutation.SET_DURATION_MUTATION](state, duration) {
    state.duration = {
      videoLength: duration.videoLength,
      challengeDuration: duration.challengeDuration,
      options: Object.entries(duration.dataChallengeDuration).map(option => {
        return {
          key: option[0],
          label: option[1].name
        };
      })
    };
  },
  [settingMutation.SET_ACTIVE_DURATION_MUTATION](state, active) {
    state.duration.challengeDuration = active;
  },
  [settingMutation.SET_COMMENTSETTING_MUTATION](state, comment) {
    state.commentSetting = comment;
  }
};

const getters = {
  rateLimit(state) {
    return state.rateLimit;
  },
  settings(state) {
    return state.settings;
  },
  notifications(state) {
    return state.notifications;
  },
  accounts(state) {
    return state.accounts;
  },
  flagSetting(state) {
    return state.flagSetting;
  },
  duration(state) {
    return state.duration;
  },
  settingLoading(state) {
    return state.settingLoading;
  },
  settingSaving(state) {
    return state.settingSaving;
  },
  commentSetting(state) {
    return state.commentSetting;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
