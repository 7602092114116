<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-col>
        Automatically suspended challenges after it's been flagged
        <a-input v-model="flagSetting.suspendChallenge.value"></a-input> times
      </a-col>
      <a-col>
        <a-switch v-model="flagSetting.suspendChallenge.status"></a-switch>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between">
      <a-col>
        Block user after their video have been flagged and suspended more than
        <a-input v-model="flagSetting.blockUserViaFlags.value"></a-input>
        times in
        <a-input v-model="flagSetting.blockUserViaFlags.time"></a-input> days
      </a-col>
      <a-col>
        <a-switch v-model="flagSetting.blockUserViaFlags.status"></a-switch>
      </a-col>
    </a-row>
    <!-- <a-row type="flex" justify="space-between">
      <a-col>
        Block user if their challenges have been suspended more than
        <a-input v-model="flagSetting.blockUserViaChallenge.value"></a-input>
        times in
        <a-input v-model="flagSetting.blockUserViaChallenge.time"></a-input>
        days
      </a-col>
      <a-col>
        <a-switch v-model="flagSetting.blockUserViaChallenge.status"></a-switch>
      </a-col>
    </a-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Flags Control"
    };
  },
  name: "FlagsControl",
  computed: {
    ...mapGetters(["flagSetting"])
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-row-flex {
  margin-bottom: 20px;
  > .ant-col:nth-child(1) {
    .gilroy(14px, rgba(47, 69, 104, 0.5));
    letter-spacing: -0.03em;
  }
  > .ant-col:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1115px) {
  .ant-row-flex {
    > .ant-col:nth-child(1) {
      width: 100%;
    }
    > .ant-col:nth-child(2) {
      justify-content: flex-start;
      margin-top: 10px;
    }
  }
}

.ant-switch {
  width: 25px !important;
  height: 18px;
  background-color: #f7fbff;
  border: 1px solid rgba(44, 24, 140, 0.07);
}

.ant-switch::after {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  top: -2.5px;
  left: -1px;
}

.ant-switch-checked::after {
  margin-left: 0px;
  left: 105%;
}

.ant-switch-checked {
  background-color: @green;
}

.ant-input {
  width: 48px;
  margin: 0px 10px;
  text-align: center;
}
</style>
