import { analysisApi } from "../../apis";
import { analysisAction } from "../actions";
import { analysisMutation } from "../mutations";

const initialState = {
  overviewStats: null,
  dashboardStats: null,
  challengeStats: null,
  overviewLoading: false,
  statsLoading: false
};

export const state = { ...initialState };

export const actions = {
  async [analysisAction.FETCH_OVERVIEW_STATS](context, payload) {
    context.state.overviewLoading = true;
    const { data, success } = await analysisApi.getOverviewStats(payload);
    context.state.overviewLoading = false;
    if (success) {
      context.commit(analysisMutation.SET_OVERVIEW_STATS_MUTATION, data);
    }
  },
  async [analysisAction.FETCH_DASHBOARD_STATS](context) {
    context.state.statsLoading = true;
    const { data, success } = await analysisApi.getDashboardStats();
    context.state.statsLoading = false;
    if (success) {
      context.commit(analysisMutation.SET_DASHBOARD_STATS_MUTATION, data);
    }
  },
  async [analysisAction.FETCH_CHALLENGE_STATS](context) {
    const { data, success } = await analysisApi.getChallengeStats();
    if (success) {
      context.commit(analysisMutation.SET_CHALLENGE_STATS_MUTATION, data);
    }
  }
};

export const mutations = {
  [analysisMutation.SET_OVERVIEW_STATS_MUTATION](state, stats) {
    state.overviewStats = stats;
  },
  [analysisMutation.SET_DASHBOARD_STATS_MUTATION](state, stats) {
    state.dashboardStats = stats;
  },
  [analysisMutation.SET_CHALLENGE_STATS_MUTATION](state, stats) {
    state.challengeStats = stats;
  }
};

const getters = {
  overviewStats(state) {
    return state.overviewStats;
  },
  dashboardStats(state) {
    return state.dashboardStats;
  },
  challengeStats(state) {
    return state.challengeStats;
  },
  overviewLoading(state) {
    return state.overviewLoading;
  },
  statsLoading(state) {
    return state.statsLoading;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
