<template>
<div>
  <base-modal
    :title="'Edit Challenge'"
    :modalVisible="modalVisible"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    @closeModal="handleClose"
    :width="'70%'"
  >
    <template v-slot:modal-content>
      <a-form-model ref="editChallengeForm" :model="form" :rules="rules">
        <a-row :gutter="48">
          <a-col :sm="24" :md="12">
            <base-form-item :prop="'title'" :label="'Case Title'">
              <template v-slot:form-input>
                <a-input v-model="form.title" />
              </template>
            </base-form-item>
          </a-col>
          <a-col :sm="24" :md="6">
            <base-form-item :prop="'duration'" :label="'Case Duration'">
              <template v-slot:form-input>
                <a-input
                  v-model="form.duration"
                  placeholder="HH:MM:SS"
                  readOnly
                  :disabled="
                    status == 'ACTIVE' || status == 'PENDING' ? false : true
                  "
                /><a-button
                  v-if="!hasReset"
                  class="button-reset"
                  @click="onReset"
                  :disabled="
                    status == 'ACTIVE' || status == 'PENDING' ? false : true
                  "
                  >Reset Timer</a-button
                >
                <a-button
                  v-else
                  class="button-reset"
                  @click="onUndo"
                  :disabled="
                    status == 'ACTIVE' || status == 'PENDING' ? false : true
                  "
                  >Undo</a-button
                >
              </template>
            </base-form-item>
          </a-col>

          <a-col :sm="24" :md="6">
            <base-form-item :label="'Case Status'">
              <template v-slot:form-input>
                <a-select v-model="statusOnChange" :options="statusList"> </a-select>
              </template>
            </base-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="48">
          <a-col :sm="24" :md="12" class="user-avatar-name">
            <div class="user-avatar-name">
              <a-avatar :size="40" :src="urlAvatarOwner">LP</a-avatar>

             

              <a-select
                style="width: 250px; margin-left: 8px"
                show-search
                v-model="owner.userId._id"
                :options="users"
                @search="fetchUser"
                :filter-option="false"
                @focus="onFocus"
                @popupScroll="onScrolling"
                :disabled="status != 'PENDING'"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </div>

            <base-form-item :prop="'challengerView'" :label="'Challenger View'">
              <template v-slot:form-input>
                <a-input v-model="form.challengerView" />
              </template>
            </base-form-item>
            
            <base-form-item :label="'Challenger video'">
              <template v-slot:form-input>
                <a-row type="flex" align="middle" v-if="owner.videoUrl">
                  <a-col flex="40px">
                    <img src="../../assets/images/mov.svg" alt="mov logo" />
                  </a-col>
                  <a-col class="file-container  hover-opacity">
                    <div v-if="!owner.videoUrl" class="file-name">File Name</div>
                    <div v-else class="file-name truncate" style="width: 250px" :title='owner.videoUrl.split("/").pop()'>{{ owner.videoUrl.split("/").pop() }}</div>
                    <!-- <div>
                      <span class="file-size">File size</span>
                      <span class="button-delete">Delete</span>
                    </div> -->
                  </a-col>
                  <a-col flex="auto" class="align-end text-right" style="margin-right: 8px">
                    <a-button class="button-play" @click="openVideoModal(owner.videoUrl)"
                      >Play Video</a-button
                    >
                  </a-col>
                  <a-col style="margin-right: 8px">
                    <a-button class="button-play" @click="uploadVideoModal('owner')"
                      >Upload Video</a-button
                    >
                  </a-col>
                  <a-col>
                    <a-button class="button-play" @click="getListVideo('owner')"
                      >Get From List</a-button
                    >
                  </a-col>
                </a-row>
                <a-row type="flex" align="middle" v-else>
                  <a-col >
                    <a-button class="button-play" @click="uploadVideoModal('owner')"
                      >Upload Video</a-button
                    >
                  </a-col>
                </a-row>

                <a-row type="flex" style='margin-top: 8px'>
                  <a-col flex="40px" v-if='urlVideo'>
                    <video  width="320" height="240" controls>
                      <source :src="urlVideo">
                    </video>
                    <!-- <img style='width: 50px' :src="urlVideo" alt="mov logo" /> -->
                  </a-col>
                  
                </a-row>
              </template>
            </base-form-item>

        


          </a-col>
          <a-divider type="vertical"></a-divider>
          <a-col :sm="24" :md="12" class="user-avatar-name">
            <div class="user-avatar-name">
              <a-avatar :size="40" :src="urlAvatarOpponent">PL</a-avatar>
              <!-- <span class="user-name">{{ opponent.userId.username }}</span> -->
              <a-select
                style="width: 250px; margin-left: 8px"
                show-search
                v-model="opponent.userId._id"
                :options="users"
                @focus="onFocus"
                @search="fetchUser"
                :filter-option="false"
                :disabled="status != 'PENDING'"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </div>

            <base-form-item
              :prop="'opponentView'"
              :label="'Opponent View'"
              :id="'opponent-view'"
            >
              <template v-slot:form-input>
                <a-input v-model="form.opponentView" />
              </template>
            </base-form-item>
            <base-form-item :label="'Challenger video'">
              <template v-slot:form-input>
                <a-row type="flex" align="middle" v-if="opponent.videoUrl">
                  <a-col flex="40px">
                    <img src="../../assets/images/mov.svg" alt="mov logo" />
                  </a-col>
                  <a-col class="file-container  hover-opacity" @click="openVideoModal(opponent.videoUrl)">
                    <div v-if="!opponent.videoUrl" class="file-name">File Name</div>
                    <div v-else class="file-name truncate" style="width: 250px">{{ opponent.videoUrl.split("/").pop() }}</div>
                    <!-- <div>
                      <span class="file-size">File size</span>
                      <span class="button-delete">Delete</span>
                    </div> -->
                  </a-col>
                  <a-col flex="auto" class="align-end text-right" style="margin-right: 8px">
                    <a-button class="button-play" @click="openVideoModal(opponent.videoUrl)"
                      >Play Video</a-button
                    >
                  </a-col>
                  <a-col style="margin-right: 8px">
                    <a-button class="button-play" @click="uploadVideoModal('opponent')"
                      >Upload Video</a-button
                    >
                  </a-col>
                  <a-col>
                    <a-button class="button-play" @click="getListVideo('opponent')"
                      >Get From List</a-button
                    >
                  </a-col>
                </a-row>
                <a-row type="flex" align="middle" v-else>
                  <a-col>
                    <a-button class="button-play" @click="uploadVideoModal('opponent')"
                      >Upload Video</a-button
                    >
                  </a-col>
                </a-row>

                <a-row type="flex" style='margin-top: 8px'>
                  <a-col flex="40px" v-if='urlVideoOpponent'>
                    <video  width="320" height="240" controls>
                      <source :src="urlVideoOpponent">
                    </video>
                    <!-- <img style='width: 50px' :src="urlVideo" alt="mov logo" /> -->
                  </a-col>
                  
                </a-row>
              </template>
            </base-form-item>
          </a-col>
          
        </a-row>

        <div class="button-container">
          <a-button
            v-if="status == 'EXPIRED' || status == 'ENDED'"
            @click="restartChallenge()"
            >Restart Challenge</a-button
          >
          <a-button @click="onSubmit" :loading="loading"
            >Save Challege</a-button
          >
          <a-button @click="deleteChallenge" :loading="loading"
            >Delete Challenge</a-button
          >
        </div>
      </a-form-model>
      <input style='display: none' id="fileSelect" type="file" accept="video/mp4,video/x-m4v,video/*" multiple="" @change="onSelectFile" />
      <input style='display: none' id="fileSelectOpponent" type="file" accept="video/mp4,video/x-m4v,video/*" multiple="" @change="onSelectFile" />
    </template>
  </base-modal>
  <ChallengeListVideoModal
    :urlList="videoList"
    :modalVisible="videoModalVisible"
    :collapsed="collapsed"
    @closeVideoModal="videoModalVisible = false"
    @selectVideo="onChangeVideoUrl"
  />
</div>
</template>

<script>
import BaseModal from "../base/BaseModal.vue";
import BaseFormItem from "../base/BaseFormItem.vue";
import { challengeApi } from "../../apis";
import { getDuration, getSeconds } from "../../utilities/getDuration";
import { message } from "ant-design-vue";
import { debounce } from "lodash";
import { userApi } from "@/apis";
import ChallengeListVideoModal from "./ChallengeListVideoModal.vue";
import mixin from '@/mixins/myMixins.js'

export default {
  mixins: [mixin],
  metaInfo() {
    return {
      title: "Challenge Edit Modal"
    };
  },
  name: "ChallengeEditModal",
  props: [
    "modalVisible",
    "collapsed",
    "challengeId",
    "title",
    "duration",
    "maxDuration",
    "owner",
    "opponent",
    "status"
  ],
  components: {
    ChallengeListVideoModal,
    BaseModal,
    BaseFormItem
  },
  data() {
    let validateDuration = (rule, value, callback) => {
      if (!value.match(/^\d*:\d\d:\d\d$/)) {
        callback(new Error("Please input the right format HH:MM:SS"));
      } else {
        callback();
      }
    };
    return {
      currentTypeSelectList: '',
      videoList: [],
      videoModalVisible: false,
      isLoadingVideo: false,
      videoOwner: '',
      videoOpponent: '',
      currentUpload: null,
      statusOnChange: '',
      statusList: [
        {
          value: "PENDING",
          label: "Pending"
        },
        {
          value: "ACTIVE",
          label: "Active"
        },
        {
          value: "ENDED",
          label: "Ended"
        },
        {
          value: "BLOCKED",
          label: "Blocked"
        }
      ],
      isLoading: false,
      limit: 500,
      loadMore: true,
      currentPage: 1,
      fetching: false,
      users: [],
      loading: false,
      hasReset: false,
      form: {
        title: this.title,
        duration: this.formattedDuration,
        challengerView: this.owner.userExpression,
        opponentView: this.opponent.userExpression
          ? this.opponent.userExpression
          : ""
      },
      rules: {
        title: [
          {
            required: true,
            message: "Case title cannot be empty",
            trigger: "change"
          }
        ],
        duration: [
          {
            validator: validateDuration,
            trigger: "change"
          }
        ],
        challengerView: [
          {
            required: true,
            message: "User's expression cannot be empty",
            trigger: "change"
          }
        ],
        opponentView: {}
      }
    };
  },
  watch: {
    status: {
      deep: true,
      handler(value) {
        this.statusOnChange = value
      }
    }
  },
  computed: {
    urlAvatarOwner() {
      let index = this.users.findIndex(res => res.value == this.owner.userId._id)
      
      if(index > -1)
        return this.users[index].avatarnew
      return ''
    },
    urlAvatarOpponent() {
      let index = this.users.findIndex(res => res.value == this.opponent.userId._id)
      if(index > -1)
        return this.users[index].avatarnew
      return ''
    },
    urlVideo() {
      if(!this.videoOwner) return ''
      if(typeof this.videoOwner == "string") {
        return this.videoOwner
      } else {
        return URL.createObjectURL(this.videoOwner);
      }  
    },
    urlVideoOpponent() {
      if(!this.videoOpponent) return ''
      if(typeof this.videoOpponent == "string") {
        return this.videoOpponent
      } else {
        return URL.createObjectURL(this.videoOpponent);
      }
    },

    
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      const marginTop = "60px";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft,
        "margin-top": marginTop
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "2.5%" : "8.5%"
      };
    },
    formattedDuration() {
      return getDuration(this.duration);
    }
  },
  methods: {
    async onChangeVideoUrl(url) {
      if(this.currentTypeSelectList == 'owner') {
        this.videoOwner = '';
        this.videoModalVisible = false;
        setTimeout(() => {
          this.videoOwner = url;
        }, 300)
        
      } else {
        this.videoOpponent = url;
        this.videoModalVisible = false;
      }
    },

    async getListVideo(type) {
      let userId = type == 'owner' ? this.owner.userId._id : this.opponent.userId._id;
      const { data, success } = await challengeApi.listVideos(userId);

      if(success) {
        this.currentTypeSelectList = type == 'owner' ? 'owner' : 'opponent';
        let p = data.map(async el => {
          let getAvatar = await this.getAvarta(el, 'VIDEO_CHALLENGE');
          return getAvatar.success ? getAvatar.data : '';
        });
        Promise.all(p)
        .then(res => {
          this.videoList = res;
          this.videoModalVisible = true;
        })
        
      }


      
    },
    async onFocus() {
      this.currentPage = 0;
      this.initList('');
    },
    async onSelectFile(e) {
      this.isLoadingVideo = true;
      let files = [...(e.target.files)];
      if(this.currentUpload == 'owner') {
        this.videoOwner = null
        setTimeout(() => {
          this.videoOwner = files[0];
        }, 300)
      }
      else {
        this.videoOpponent = null
        setTimeout(() => {
          this.videoOpponent = files[0];
        }, 300)
      } 
      setTimeout(() => {
        this.isLoadingVideo = false;
      }, 100);
    },
    async uploadVideoModal(type) {
      this.currentUpload = type;
      if(type == "owner")
        document.getElementById('fileSelect').click()
      else 
        document.getElementById('fileSelectOpponent').click()
    },
    async openVideoModal(videoUrl) {
      this.$emit('openVideoModal', videoUrl);
    },
    async handleClose() {
      this.$emit('closeModal');
      // setTimeout(() => {
      //   this.statusOnChange = this.status;
      // }, 300);
    },
    async onScrolling(event) {
      const { target } = event;
      if (
        target.scrollHeight - (target.scrollTop + target.offsetHeight) <
        200
      ) {
        if (this.loadMore && !this.fetching) {
          this.currentPage += 1;
          this.handleSearch();
        }
      }
    },
    fetchUser: debounce(function(val) {
      this.initList(val);
    }, 300),
    async initList() {
      // this.users = [];
      // this.handleSearch(input);
    },

    async handleSearch(keyword = "") {
      if (this.fetching) return;
      this.fetching = true;
      const { data: users, success: userSuccess } = await userApi.searchUser({
        keyword: keyword,
        page: this.currentPage,
        limit: this.limit
      });

      if (userSuccess) {
        if (users.length < this.limit) {
          this.loadMore = false;
        }
        let p = users.map(async el => {
          let getAvatar = await this.getAvarta(el.avatar);
          return {
            value: el.id,
            label: el.username,
            avatar: el.avatar,
            avatarnew: getAvatar ? getAvatar.data : ''
            
          };
        });

        Promise.all(p)
        .then(res => {
          this.users = res;
        })
        
        this.fetching = false;
      } else {
        this.fetching = false;
      }
    },
    async restartChallenge() {
      challengeApi.restartChallenge(this.challengeId).then(res => {
        if (res.success) {
          message.success("Restart challenge successful.");
          this.$emit("submitEditForm");
        }
      });
    },

    onReset() {
      this.form.duration = getDuration(this.maxDuration);
      this.hasReset = true;
    },
    onUndo() {
      this.form.duration = this.formattedDuration;
      this.hasReset = false;
    },
    onSubmit(e) {
      e.preventDefault();
      this.$refs.editChallengeForm.validate(async valid => {

        if (valid) {
          this.loading = true;
          if(this.videoOwner) {
            if(typeof this.videoOwner == "string") {
              dataOwner  = {
                fileName: this.videoOwner
              }
              
            } else {
              var { data: dataOwner, success: successUploadOwner } = await challengeApi.presignedUpload({ "type": "VIDEO", "extension": "mp4"})
              if(successUploadOwner) {
                await challengeApi.uploadFile(dataOwner.url, this.videoOwner);
              }
              
            }
          }
          if(this.videoOpponent) {
            if(typeof this.videoOpponent == "string") {
              dataOpponent  = {
                fileName: this.videoOpponent
              }
            } else {
              var { data: dataOpponent, success: successUploadOpponent } = await challengeApi.presignedUpload({ "type": "VIDEO", "extension": "mp4"})
              if(successUploadOpponent) {
                await challengeApi.uploadFile(dataOpponent.url, this.videoOpponent)
              }
            }
          }
          if(this.status == "PENDING")
            await challengeApi.updateUserChallenge(
              this.challengeId,
              {
                owner: {
                  userId: this.owner.userId._id,
                  videoUrl: dataOwner?.fileName ? dataOwner.fileName : this.owner.videoUrl.split('?')[0]
                },
                opponent: {
                  userId: this.opponent.userId._id,
                  videoUrl: dataOpponent?.fileName ? dataOpponent.fileName : this.opponent.videoUrl.split('?')[0]
                },
              }
            );
          
          const { success } = await challengeApi.updateChallenge(
            this.challengeId,
            {
              status: this.statusOnChange,
              title: this.form.title,
              owner: {
                userExpression: this.form.challengerView,
                
              },
              opponent: {
                userExpression: this.form.opponentView,
              },
              resetDuration: getSeconds(this.form.duration) === this.maxDuration
            }
          );

          await challengeApi.updateStatus(
            this.challengeId,
            {
              status: this.statusOnChange,
            }
          );


          this.loading = false;
          if (success) {
            message.success("Challenge edited successfully");
            this.$emit("submitEditForm");
          }
        } else {
          return false;
        }
      });
    },
    async deleteChallenge() {
      this.loading = true;
      const { success } = await challengeApi.deleteChallenge(this.challengeId);
      this.loading = false;
      if (success) {
        message.success("Challenge deleted successfully");
        this.$router.replace("/challenges/all");
      }
    }
  },
  mounted() {
    
    this.statusOnChange = this.status
    this.form.duration = this.formattedDuration;
    this.handleSearch();
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixins.less";
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
.text-right {
  text-align: right;
}

.margin-left-auto {
  margin-left: auto;
}

.button-play {
  height: 38px;
  border-radius: 5px;
  background-color: rgba(0, 183, 232, 0.05);
  border: none;
  .poppins-medium(12px, @blue);
  &:hover,
  &:focus {
    border: none;
  }
}
.button-reset {
  position: absolute;
  z-index: 2;
  right: 5px;
  bottom: -10px;
  .function-button(100px);
}

.ant-row {
  box-sizing: border-box;
  margin-bottom: 20px;
}

.user-name {
  .poppins-bold(14px, @blue);
  box-sizing: border-box;
  margin-left: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  > .ant-btn:nth-child(3) {
    .red-button(166px);
    margin-left: 20px;
  }
  > .ant-btn:nth-child(1) {
    .primary-button(166px);
  }
  > .ant-btn:nth-child(2) {
    .secondary-button(157px);

    margin-left: 20px;
  }
}

.ant-divider {
  position: absolute;
  z-index: 2;
  height: 80%;
  width: 1px;
  background-color: @table-gray;
  opacity: 0.3;
  left: 49%;
}

@media screen {
  @media (max-width: 768px) {
    .ant-divider {
      display: none !important;
    }
  }
}

.ant-form-explain {
  position: absolute;
  z-index: 2;
}

.user-avatar-name {
  margin-bottom: 15px;
}
</style>
