import { userApi } from "../../apis";
import { userAction } from "../actions";
import { userMutation } from "../mutations";
import getJoinedDate from "./../../utilities/getJoinedDate";
import { challengeApi } from "../../apis";

const initialState = {
  users: [],
  pageCount: 0,
  userLoading: false
};

export const state = { ...initialState };

export const actions = {
  async [userAction.FETCH_USERS] (context, params) {
    context.state.userLoading = true;
    const { data, success, paginate } = await userApi.fetchUser(params);
    

    



    context.state.userLoading = false;
    if (success) {
      const getAvarta = (avatarLink, type = 'USER_AVATAR') => {
        if(!avatarLink) return null
        let split = [];
        split =  avatarLink.split('/');
        let form = {
          type,
          "fileName": split[split.length -1]
        }
        return challengeApi.presignedDownload(form);
      };
  
      let p = data.map(async (user) => {
        let avatarNew = await getAvarta(user.avatar);
        return {
          ...user,
          avatarNew: avatarNew,
        };
      });
      Promise.all(p)
      .then(res => {
        context.commit(userMutation.SET_USER_MUTATION, res);
      })
      const pageCount = Math.ceil(paginate.total / 10);
      context.commit(userMutation.SET_USER_PAGE_MUTATION, pageCount);
    }
  },
  async [userAction.FETCH_BLOCK_USERS] (context, params) {
    context.state.userLoading = true;
    const { data, success, paginate } = await userApi.fetchBlockUser(params);
    context.state.userLoading = false;
    if (success) {
      context.commit(userMutation.SET_USER_MUTATION, data);
      const pageCount = Math.ceil(paginate.total / parseInt(params.limit || 10));
      context.commit(userMutation.SET_USER_PAGE_MUTATION, pageCount);
    }
  }
};

export const mutations = {
  [userMutation.SET_USER_MUTATION] (state, users) {
    state.users = users;
  },
  [userMutation.SET_USER_PAGE_MUTATION] (state, pageCount) {
    state.pageCount = pageCount;
  }
};

const getters = {
  users (state) {
    return state.users;
  },
  pageCount (state) {
    return state.pageCount;
  },
  userLoading (state) {
    return state.userLoading;
  },
  userData (state) {
    return state.users.map((user, index) => {
      return {
        id: user._id,
        key: index + 1,
        name: user.username,
        activeChallenges: 0,
        totalComments: user.configurations.totalComments,
        totalChallenges: 0,
        totalVotes: user.configurations.totalVotes,
        role: user.roles.toLowerCase(),
        dateJoined: getJoinedDate(user.created_at),
        avatar: user.avatar,
        avatarNew: user.avatarNew
      };
    });
  },
  blockUserData (state) {
    return state.users;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
