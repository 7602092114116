<template>
  <base-icon :viewBox="'0 0 16 15'">
    <template v-slot:icon-name>
      <path
        class="st0"
        :class="{ active: active }"
        d="M9.3,0.9H6.7C6.3,0.9,6,1.2,6,1.6v12.1c0,0.4,0.3,0.7,0.7,0.7h2.7c0.4,0,0.7-0.3,0.7-0.7V1.6
	C10,1.2,9.7,0.9,9.3,0.9z"
      />
      <path
        class="st0"
        :class="{ active: active }"
        d="M3.3,8.3H0.7C0.3,8.3,0,8.6,0,9v4.7c0,0.4,0.3,0.7,0.7,0.7h2.7c0.4,0,0.7-0.3,0.7-0.7V9C4,8.6,3.7,8.3,3.3,8.3z
	"
      />
      <path
        class="st0"
        :class="{ active: active }"
        d="M15.3,5h-2.7C12.3,5,12,5.3,12,5.6v8.1c0,0.4,0.3,0.7,0.7,0.7h2.7c0.4,0,0.7-0.3,0.7-0.7V5.6
	C16,5.3,15.7,5,15.3,5z"
      />
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "../base/BaseIcon.vue";
export default {
  name: "Users",
  props: ["active"],
  components: {
    BaseIcon
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";

.st0 {
  fill: #454176;
}

.active {
  fill: @blue;
}
</style>
