<template>
  <base-modal
    :title="'Share this challenge to different social media platforms'"
    :width="'1000px'"
    :closable="true"
    :modalVisible="modalVisible"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    @closeModal="$emit('closeShareModal')"
  >
    <template v-slot:modal-content>
      <a-row :gutter="24">
        <a-col
          v-for="network in networks"
          :key="network.network"
          :span="6"
          class="media__container"
        >
          <ShareNetwork
            :network="network.network"
            :url="sharing.url"
            :style="{ backgroundColor: network.color }"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitterUser="sharing.twitterUser"
          >
            <i :class="network.icon"></i>
            <span>{{ network.name }}</span>
          </ShareNetwork></a-col
        >
      </a-row>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../base/BaseModal.vue";

export default {
  metaInfo() {
    return {
      title: "Challenge Share Modal"
    };
  },
  name: "ChallengeShareModal",
  props: ["modalVisible", "challenge", "collapsed"],
  components: {
    BaseModal
  },
  data() {
    return {
      sharing: {
        url: "undefined",
        title:
          "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description:
          'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "vuejs,vite,javascript"
      },
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "far fah fa-lg fa-envelope",
          color: "#333333"
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2"
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fah fa-lg fa-linkedin",
          color: "#007bb5"
        },
        {
          network: "pinterest",
          name: "Pinterest",
          icon: "fab fah fa-lg fa-pinterest",
          color: "#bd081c"
        },
        {
          network: "quora",
          name: "Quora",
          icon: "fab fah fa-lg fa-quora",
          color: "#a82400"
        },
        {
          network: "reddit",
          name: "Reddit",
          icon: "fab fah fa-lg fa-reddit-alien",
          color: "#ff4500"
        },
        {
          network: "skype",
          name: "Skype",
          icon: "fab fah fa-lg fa-skype",
          color: "#00aff0"
        },
        {
          network: "sms",
          name: "SMS",
          icon: "far fah fa-lg fa-comment-dots",
          color: "#333333"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2"
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          color: "#25d366"
        }
      ]
    };
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "2.5%" : "8.5%"
      };
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.share-network-facebook,
.share-network-email,
.share-network-linkedin,
.share-network-pinterest,
.share-network-quora,
.share-network-reddit,
.share-network-skype,
.share-network-sms,
.share-network-twitter,
.share-network-whatsapp {
  text-decoration: none;
  color: white;
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 60%;
  .gilroy(14px, @white);
  > span {
    margin-left: 10px;
  }
}

.media__container {
  height: 60px;
}
</style>
