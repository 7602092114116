<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    :viewBox="viewBox"
  >
    <slot name="icon-name"></slot>
  </svg>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Base Icon"
    };
  },
  name: "BaseIcon",
  props: ["viewBox"]
};
</script>

<style lang="less" scoped></style>
