import { categoryAction } from "../actions";
import { categoryMutation } from "../mutations";
import { categoryApi } from "../../apis";

const initialState = {
  categories: [],
  categoryLoading: false
};

export const state = { ...initialState };

export const actions = {
  async [categoryAction.FETCH_CATEGORIES](context) {
    context.state.categoryLoading = true;
    const { data, success } = await categoryApi.fetchCategory();
    context.state.categoryLoading = false;
    if (success) context.commit(categoryMutation.SET_CATEGORY_MUTATION, data);
  }
};

export const mutations = {
  [categoryMutation.SET_CATEGORY_MUTATION](state, categories) {
    state.categories = categories;
  }
};

const getters = {
  categories(state) {
    return state.categories;
  },
  categoryLoading(state) {
    return state.categoryLoading;
  },
  allChallengeCount() {
    let count = 0;
    state.categories.forEach(category => {
      count += category.totalChallenge;
    });
    return count;
  },
  categoryData(state, getters) {
    return [
      {
        id: "all",
        title: "All",
        subTitle: "Click here to view all challenges",
        alternativeIcon: "all-challenges-icon",
        iconBgColor: "#E0F6FD",
        challengeNum: getters.allChallengeCount,
        status: true
      }
    ].concat(
      state.categories.map(category => ({
        id: category._id,
        title: category.title,
        subTitle: category.description,
        icon: category.icon || null,
        challengeNum: category.totalChallenge,
        iconBgColor: category.background,
        status: category.status
      }))
    );
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
