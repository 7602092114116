<template>
  <div>
    <a-spin :spinning="loading"></a-spin>
    <div v-if="userInfo">
      <div v-if="!isEditing">
        <UserSummary
          :userInfo="{ ...userInfo }"
          :collapsed="collapsed"
          :loading="loading"
          @openEditForm="isEditing = true"
        />
        <div>
          <a-row type="flex" justify="space-between" class="case-summary">
            <a-col :sm="12" :md="6">
              <span class="user-cases">User Cases</span>
              <a-badge :count="challengeCount" showZero></a-badge>
            </a-col>
            <a-col :sm="6" :md="3">
              <base-select-dropdown
                @selected="onSelect"
                :valueList="statusList"
                :defaultValue="{ key: 'all' }"
              ></base-select-dropdown>
            </a-col>
          </a-row>
          <a-spin :spinning="challengeLoading"></a-spin>
          <UserCases v-if="!challengeLoading" :challenges="challenges" />
          <a-row type="flex" justify="end" style="margin-top: 30px">
            <a-pagination
              :current="currentPage"
              :page-size="12"
              :total="challengePageCount * 12"
              @change="onChange"
            ></a-pagination>
          </a-row>
        </div>
      </div>
      <EditUserForm
        :userInfo="{ ...userInfo }"
        @closeEditForm="isEditing = false"
        @submitEditForm="onSubmit"
        v-else
      />
    </div>
  </div>
</template>

<script>
import UserSummary from "../components/userProfile/UserSummary.vue";
import UserCases from "../components/userProfile/UserCases.vue";
import EditUserForm from "../components/userProfile/EditUserForm.vue";
import BaseSelectDropdown from "../components/base/BaseSelectDropdown.vue";
import { userApi } from "../apis";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: "User Profile"
    };
  },
  name: "UserProfile",
  props: ["collapsed"],
  components: {
    UserSummary,
    UserCases,
    EditUserForm,
    BaseSelectDropdown,
  },
  data() {
    return {
      isEditing: false,
      loading: false,
      userInfo: null,
      currentPage: 1,
      statusList: [
        { key: "all", label: "All" },
        { key: "active", label: "Active" },
        { key: "pending", label: "Pending" },
        { key: "ended", label: "Ending" },
      ],
      chosenStatus: "all",
    };
  },
  computed: {
    ...mapGetters([
      "challenges",
      "challengePageCount",
      "challengeCount",
      "challengeLoading",
    ]),
  },
  methods: {
    ...mapActions({
      getUserChallenges: "FETCH_CHALLENGES_BY_USER",
    }),
    async getUser(userId) {
      this.loading = true;
      const { data: userInfo, success: doneInfo } = await userApi.getUser(
        userId
      );
      this.loading = false;
      if (doneInfo) {
        this.userInfo = userInfo;
      }
    },
    onChange(current) {
      this.currentPage = current;
      this.getUserChallenges({
        userId: this.$route.params.id,
        params: {
          page: current,
          limit: 12,
        },
      });
    },
    onSelect(selected) {
      this.chosenStatus = selected.key;
      this.getUserChallenges({
        userId: this.$route.params.id,
        params: {
          status:
            (this.chosenStatus !== "all") & (this.chosenStatus !== "all cases")
              ? this.chosenStatus.toUpperCase()
              : null,
          page: 1,
          limit: 12,
        },
      });
      this.currentPage = 1;
    },
    async onSubmit() {
      this.isEditing = false;
      await this.getUser(this.$route.params.id);
      await this.getUserChallenges({
        userId: this.$route.params.id,
        params: {
          page: 1,
          limit: 12,
        },
      });
    },
  },
  created() {
    this.getUser(this.$route.params.id);
    this.getUserChallenges({
      userId: this.$route.params.id,
      params: {
        page: 1,
        limit: 12,
      },
    });
  },
};
</script>

<style lang="less">
@import "../styles/fonts.less";
@import "../styles/colors.less";

.noti--text {
  .gilroy(24px, @dark-blue);
  letter-spacing: 0.01em;
}

.case-summary {
  margin-bottom: 20px;
  box-sizing: border-box;
  > .ant-col:nth-child(1) {
    display: flex;
    align-items: center;
  }
  > .ant-col:nth-child(2) {
    height: 45px;
  }
}

.user-cases {
  .gilroy(16px, @main-dark);
  box-sizing: border-box;
  margin-right: 10px;
}

.ant-badge-count {
  .poppins-bold(12px, @white);
  background-color: @dark !important;
}
</style>
