var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{staticClass:"user-info",attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"flex":"100px"}},[_c('a-avatar',{attrs:{"src":_vm.userInfo.avatar,"size":65}},[_vm._v(_vm._s(_vm.userInfo.username.slice(0, 2))+" ")])],1),_c('a-col',{attrs:{"flex":"auto"}},[_c('div',[_vm._v(_vm._s(_vm.userInfo.username))]),_c('div',[(_vm.userInfo.fullName && _vm.userInfo.fullName !== ' ')?_c('span',{staticClass:"user-info-detail"},[_vm._v(_vm._s(_vm.userInfo.fullName))]):_vm._e(),_c('span',{staticClass:"user-info-detail"},[_vm._v("Followers: "+_vm._s(_vm.userInfo.followers.length))]),_c('span',{staticClass:"user-info-detail"},[_vm._v("Following: "+_vm._s(_vm.userInfo.following.length))]),_c('span',{staticClass:"user-info-detail"},[_vm._v("Following Categories: "+_vm._s(_vm.userInfo.favoriteCategory.length))]),_c('span',{staticClass:"user-info-detail"},[_vm._v(_vm._s(_vm.userInfo.email))])])]),_c('a-col',[_c('a-button',{staticClass:"block-user",on:{"click":function($event){_vm.blockUserModal = true}}},[_vm._v("Block User")]),_c('a-button',{staticClass:"edit-user",on:{"click":function($event){return _vm.$emit('openEditForm')}}},[_vm._v("Edit User")]),_c('a-button',{staticClass:"button-state--secondary",on:{"click":function($event){_vm.changePasswordModal = true}}},[_vm._v("Change password")]),(_vm.userInfo.status == 'ACTIVE')?_c('a-button',{staticClass:"delete-user",on:{"click":function($event){_vm.modalVisible = true}}},[_vm._v("Delete User")]):_vm._e()],1),_c('DeleteUserModal',{attrs:{"modalVisible":_vm.modalVisible,"maskStyle":_vm.maskStyle,"dialogStyle":_vm.dialogStyle,"userInfo":{
        id: _vm.userInfo._id,
        name: _vm.userInfo.username,
        avatar: _vm.userInfo.avatar
      }},on:{"closeModal":function($event){_vm.modalVisible = false}}}),_c('ChangePasswordModal',{attrs:{"modalVisible":_vm.changePasswordModal,"maskStyle":_vm.maskStyle,"dialogStyle":_vm.dialogStyle,"userInfo":{
        id: _vm.userInfo._id,
        name: _vm.userInfo.username,
        avatar: _vm.userInfo.avatar
      }},on:{"closeModal":function($event){_vm.changePasswordModal = false}}}),_c('BlockUserModal',{attrs:{"modalVisible":_vm.blockUserModal,"maskStyle":_vm.maskStyle,"dialogStyle":_vm.dialogStyle,"loading":_vm.loading,"userId":_vm.userInfo._id},on:{"closeModal":function($event){_vm.blockUserModal = false}}})],1),_c('a-row',{staticClass:"user-summary",attrs:{"type":"flex","justify":"space-around","align":"middle"}},[_c('a-col',{attrs:{"lg":"3","md":"6"}},[_c('div',[_vm._v("Total Cases")]),_c('div',[_vm._v(_vm._s(_vm.userInfo.configurations.totalCase))])]),_c('a-col',{attrs:{"lg":"3","md":"6"}},[_c('div',[_vm._v("Total Won")]),_c('div',[_vm._v(_vm._s(_vm.userInfo.configurations.totalWon))])]),_c('a-col',{attrs:{"lg":"3","md":"6"}},[_c('div',[_vm._v("Total Lost")]),_c('div',[_vm._v(_vm._s(_vm.userInfo.configurations.totalLost))])]),_c('a-col',{attrs:{"lg":"3","md":"6"}},[_c('div',[_vm._v("Total Votes")]),_c('div',[_vm._v(_vm._s(_vm.userInfo.configurations.totalVotes))])]),_c('a-col',{attrs:{"lg":"3","md":"6"}},[_c('div',[_vm._v("Favourite Category")]),_c('div',[_vm._v(_vm._s(_vm.favoriteCategory))])]),_c('a-col',{attrs:{"lg":"3","md":"6"}},[_c('div',[_vm._v("Date Joined")]),_c('div',[_vm._v(_vm._s(_vm.joinedDate))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }