<template>
  <div>
    <a-row type="flex" justify="space-between" class="row-title">
      <a-col :xs="24" :xl="14">
        <div class="challenge-title">
          {{ challengeInfo.title }}
        </div>
        <div class="challenge-info">
          <span class="challenge-info-detail"
            >Challenge ID: {{ challengeInfo._id }}</span
          >
          <span class="challenge-info-detail"
            ><span>Status: </span
            ><span class="status" :class="challengeInfo.status.toLowerCase()">{{
              challengeInfo.status.toLowerCase()
            }}</span></span
          >
          <span class="challenge-info-detail"
            >Views: {{ challengeInfo.views }}</span
          >
          <span class="challenge-info-detail"
            >Shares: {{ challengeInfo.shares }}</span
          >
        </div>
      </a-col>
      <a-col>
        <slot name="button-group"> </slot>
      </a-col>
    </a-row>
    <slot name="summary"></slot>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Challenge Summary"
    };
  },
  name: "ChallengeSummary",
  props: ["challengeInfo"]
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.row-title {
  box-sizing: border-box;
  margin-bottom: 30px;
  > .ant-col {
    height: auto;
    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
    }
  }
}

// @media screen and (max-width: ;) {

// }

.challenge-title {
  .header__headline--font();
  margin-bottom: 15px;
}

.challenge-info {
  letter-spacing: -0.03em;
  line-height: 18px;
}

.challenge-info-detail {
  .gilroy(15px, rgba(47, 69, 104, 0.5));
  box-sizing: border-box;
  margin-right: 25px;
  > .status {
    text-transform: capitalize;
  }
}

@media screen and (max-width: 1200px) {
  .row-title {
    > .ant-col {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 750px) {
  .challenge-info-detail {
    display: block;
    margin-bottom: 10px;
  }
}

.active {
  .gilroy(15px, @green);
}

.pending {
  .gilroy(15px, @warning);
}

.deleted {
  .gilroy(15px, "#333");
}

.ended {
  .gilroy(15px, @red);
}

.cancelled {
  .gilroy(15px, #f00aa3);
}
</style>
