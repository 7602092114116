<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="loading"
      :customRow="customRow"
    >
      <!-- :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 50
      }" -->
      <span slot="avatar" slot-scope="text, record">
        <a-avatar :src="record.avatarNew ? record.avatarNew.data : record.avatar" :size="37">{{
          record.name.slice(0, 2)
        }}</a-avatar>
        <span class="username">{{ record.name }}</span>
      </span>
    </a-table>
    <a-row type="flex" justify="end" style="margin-top: 30px">
      <a-pagination :total="pageCount * 10" @change="onChange"></a-pagination>
    </a-row>
  </div>
</template>

<script>
const columns = [
  {
    title: "Username",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "avatar" },
    ellipsis: true,
    width: "25%"
  },
  {
    title: "Active Challenges",
    dataIndex: "activeChallenges",
    key: "activeChallenges",
    ellipsis: true,
    width: "15%"
  },
  {
    title: "Total Comments",
    dataIndex: "totalComments",
    key: "totalComments",
    ellipsis: true,
    width: "13%"
  },
  {
    title: "Total Challenges",
    key: "totalChallenges",
    dataIndex: "totalChallenges",
    ellipsis: true,
    width: "13%"
  },
  {
    title: "Total Votes",
    key: "totalVotes",
    dataIndex: "totalVotes",
    ellipsis: true,
    width: "10%"
  },
  {
    title: "Role",
    key: "role",
    dataIndex: "role",
    ellipsis: true
  },
  {
    title: "Date Joined",
    key: "dateJoined",
    dataIndex: "dateJoined",
    ellipsis: true
  }
];

export default {
  metaInfo() {
    return {
      title: "User Table"
    };
  },
  name: "UserTable",
  props: ["tableData", "pageCount", "loading"],
  data() {
    return {
      columns,
      selectedRowKeys: []
    };
  },
  methods: {
    // onSelectChange(selectedRowKeys) {
    //   this.selectedRowKeys = selectedRowKeys;
    // },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.$router.push(`/admin/users/${record.id}`);
          }
        }
      };
    },
    async onChange(current) {
      this.$emit("paginate", current);
    }
  }
};
</script>

<style lang="less">
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-table-thead > tr > th {
  background-color: @white;
  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  // &:not(:nth-child(1)) {
  .ant-table-header-column {
    letter-spacing: 0.01em;
    .poppins-medium(13px, @dark-blue);
    opacity: 0.5;
  }
  // }
}

.ant-table-tbody > tr {
  box-shadow: 0px 17px 20px rgba(50, 50, 50, 0.04);
  > td {
    background-color: @white;
    height: 64px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    .poppins-light(13px, @table-gray);
    letter-spacing: 0.01em;
    &:nth-child(7) {
      text-transform: capitalize;
    }
  }
}

.username {
  margin-left: 5px;
  .poppins-medium(14px, @main-dark);
  letter-spacing: 0.01em;
}
</style>
