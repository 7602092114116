<template>
  <a-modal
    :visible="modalVisible"
    :title="title"
    centered
    :footer="null"
    :maskStyle="maskStyle"
    :destroyOnClose="true"
    :closable="closable"
    :dialog-style="dialogStyle"
    :width="width"
    @cancel="$emit('closeModal')"
  >
    <template slot="closeIcon">
      <a-icon type="close"></a-icon>
    </template>
    <div class="ld_form contact_form_modal">
      <a-form-model
        ref="landingPageForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit="onSubmit"
        class="landing_page_form"
      >
        <a-row type="flex" :gutter="16">
          <a-col :md="12" :xs="24">
            <base-form-item :prop="'name'" :label="'Name'">
              <template v-slot:form-input>
                <a-input v-model="form.name" placeholder="What's your name?" />
              </template>
            </base-form-item>
          </a-col>
          <a-col :md="12" :xs="24">
            <base-form-item :prop="'email'" :label="'Email'">
              <template v-slot:form-input>
                <a-input
                  v-model="form.email"
                  placeholder="What's your email?"
                />
              </template>
            </base-form-item>
          </a-col>
        </a-row>
        <a-row type="flex">
          <base-form-item :prop="'body'" :label="'Your Message'">
            <template v-slot:form-input>
              <TextArea v-model="form.body" placeholder="Message" :rows="8" />
            </template>
          </base-form-item>
        </a-row>
        <a-row type="flex" justify="left" class="submit-button-container">
          <a-button
            id="send-newletter-button"
            html-type="submit"
            :loading="loadingBtn"
          >
            Send
          </a-button>
          <a-button class="cancel-send-contact" @click="$emit('closeModal')">
            Cancel
          </a-button>
        </a-row>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import BaseFormItem from "../../components/base/BaseFormItem.vue";
import { Input } from "ant-design-vue";
const { TextArea } = Input;
import { landingPage } from "../../apis";
import { message } from "ant-design-vue";

export default {
  metaInfo() {
    return {
      title: "Contact Form"
    };
  },
  name: "ContactForm",
  props: [
    "title",
    "modalVisible",
    "maskStyle",
    "closable",
    "width",
    "dialogStyle"
  ],
  components: {
    BaseFormItem,
    TextArea
  },
  data() {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      loadingBtn: false,
      form: {
        name: "",
        email: "",
        body: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "Your name cannot be empty",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change"
          },
          {
            pattern: /\S+@\S+\.\S+/,
            message: "Please type in a valid email address"
          }
        ],
        body: [
          {
            required: true,
            message: "Message cannot be empty",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.$refs.landingPageForm.validate(async valid => {
        if (valid) {
          this.loadingBtn = true;
          landingPage
            .sendNewsleter({
              name: this.form.name,
              email: this.form.email,
              message: this.form.body
            })
            .then(() => {
              message.success("Thank You! Your submission has been received.");
              this.loadingBtn = false;
              this.$emit("closeModal");
            });
        }
      });
    }
  }
};
</script>

<style lang="less">
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-modal {
  border-radius: 10px !important;
}

.ant-modal-header {
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
  height: 80px;
  padding: 24px 24px;
  background: @main-dark;
  .ant-modal-title {
    color: white;
  }
}

.ant-modal-content {
  border-radius: 10px;
  .ant-modal-close-x {
    background: white;
    color: @main-dark;
  }
}

.ant-modal-close,
.ant-modal-close-x {
  background-color: rgba(47, 69, 104, 0.05);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  top: 12px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-title {
  .gilroy(24px, @main-dark);
  display: flex;
  align-items: center;
}

.contact_form_modal {
  .ant-form-item {
    width: 100%;
    textarea {
      resize: none;
    }
  }

  #send-newletter-button {
    height: 50px;
    padding: 0px 70px;
    background: #00a6d4;
    color: #fff;
    margin-right: 20px;
  }
  .cancel-send-contact {
    height: 50px;
    border: none;
    color: @main-dark;
    font-weight: bold;
  }
}
</style>
