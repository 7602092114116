import { request } from "../helpers";

export const fetchChallenge = async params =>
  request({
    method: "GET",
    url: "/challenges",
    params: params
  });

export const getChallenge = async challengeId =>
  request({
    method: "GET",
    url: `/challenges/${challengeId}`
  });

export const getChallengeByCategory = async (categoryId, params) =>
  request({
    method: "GET",
    url:
      categoryId === "all"
        ? "challenges"
        : `/challenges/category/${categoryId}`,
    params: params
  });

export const getChallengeByUser = async (userId, params) =>
  request({
    method: "GET",
    url: `/challenges/user/${userId}`,
    params: params
  });

export const createChallenge = async payload =>
  request({
    method: "POST",
    url: "/challenges",
    data: payload
  });

export const updateChallenge = async (challengeId, payload) =>
  request({
    method: "PUT",
    url: `/challenges/${challengeId}`,
    data: payload
  });

export const deleteChallenge = async challengeId =>
  request({
    method: "DELETE",
    url: `/challenges/${challengeId}`
  });

export const searchChallenge = async params =>
  request({
    method: "GET",
    url: "/challenges/search",
    params
  });


export const unsuspend = async challengeId => request({
  method: "POST",
  url: "/challenges/unsuspend",
  data: { challengeId }
});

export const restartChallenge = async challengeId => request({
  method: "POST",
  url: "/challenges/restart",
  data: { challengeId }
});

export const cloneChallenge = async challengeId => request({
  method: "POST",
  url: "/challenges/clone",
  data: { challengeId }
});

export const presignedUpload = async (params) => request({
  method: "POST",
  url: "/challenges/presigned-upload",
  data: { ...params }
});


export const uploadFile = async (link, file) => request({
  method: "PUT",
  url: link,
  data: file,
  notRequiredToken: true,
});

export const updateStatus = async (challengeId, status) =>
  request({
    method: "PUT",
    url: `/challenges/status/${challengeId}`,
    data: status
  });

export const updateUserChallenge = async (challengeId, params) =>
  request({
    method: "PUT",
    url: `/challenges/participant/${challengeId}`,
    data: params
  });

export const listVideos = async challengeId =>
  request({
    method: "GET",
    url: `/challenges/list-video/${challengeId}`,
  });

export const presignedDownload = async params =>
  request({
    method: "POST",
    url: `/challenges/presigned-download`,
    data: params
  });


export const challengesAdminCreate = async (params) => request({
    method: "POST",
    url: "/challenges/admin/create",
    data: { ...params }
  });
