import { request } from "../helpers";

export const fetchFlags = async params =>
  request({
    method: "GET",
    url: "/flags",
    params: params
  });

export const getFlag = async ({flagId,challengeId}) =>
  request({
    method: "GET",
    url: `/flags/${flagId}`,
    params:{challengeId}
  });

export const updateFlag = async (flagId, status) =>
  request({
    method: "PUT",
    url: `/flags/${flagId}`,
    data: {
      status
    }
  });
