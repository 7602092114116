<template>
  <base-icon :viewBox="'0 0 15 15'">
    <template v-slot:icon-name>
      <circle
        class="st0"
        :class="{ active0: active }"
        cx="7.5"
        cy="7.5"
        r="6.8"
      />
      <circle
        class="st0"
        :class="{ active0: active }"
        cx="7.5"
        cy="7.5"
        r="3.8"
      />
      <circle
        class="st1"
        :class="{ active1: active }"
        cx="7.5"
        cy="7.5"
        r="1.5"
      />
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "../base/BaseIcon.vue";
export default {
  name: "Users",
  props: ["active"],
  components: {
    BaseIcon
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";

.st0 {
  fill: none;
  stroke: #454176;
  stroke-width: 1.4;
}

.active0 {
  stroke: @blue;
}
.st1 {
  fill: #454176;
}
.active1 {
  fill: @blue;
}
</style>
