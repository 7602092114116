<template>
  <div class="joining-the-challenge landing-container">
    <div class="images">
      <div
        class="image rb"
        :class="[{ active: isActive(1) }]"
        @mouseover="() => select(1)"
        @mouseleave="() => mouseleave()"
      >
        <picture class="rb">
          <source
            srcset="@/assets/images/joining-the-challenge-1-desktop.jpg"
            media="(min-width: 768px)"
          />
          <img src="@/assets/images/joining-the-challenge-1-mobile.jpg" />
        </picture>
        <div class="info-user-desktop-only user-info-first" v-if="isActive(1)">
          <div class="user-info__img">
            <img src="@/assets/images/avatar_circle_2.png" />
          </div>
          <h3>@Bluebird</h3>
          <p>Great job! @Bluebird like your video challenge</p>
        </div>
      </div>
      <div
        class="image desktop-only left"
        :class="[{ active: isActive(2) }]"
        @mouseover="() => select(2)"
        @mouseleave="() => mouseleave()"
      >
        <img src="@/assets/images/joining-the-challenges-2.jpg" />
        <div class="user-info-second" v-if="isActive(2)">
          <div class=""><img src="@/assets/images/avatar_cicle.png" /></div>
          <div>
            <h3>@Jane Cooper</h3>
            <p>@yellowfish</p>
          </div>
          <star-rating
            :star-size="10"
            :rating="5"
            :show-rating="false"
            :border-width="4"
            :read-only="true"
            border-color="#d8d8d8"
            :rounded-corners="true"
            :star-points="[
              23,
              2,
              14,
              17,
              0,
              19,
              10,
              34,
              7,
              50,
              23,
              43,
              38,
              50,
              36,
              34,
              46,
              19,
              31,
              17
            ]"
          ></star-rating>
        </div>
      </div>
      <div
        class="image desktop-only rr"
        :class="[{ active: isActive(0) }]"
        @mouseover="() => select(0)"
        @mouseleave="() => mouseleave()"
      >
        <img src="@/assets/images/joining-the-challenges-3.jpg" />
        <div class="info-user-desktop-only user-info-third" v-if="isActive(0)">
          <div class="user-info__img">
            <img src="@/assets/images/avatar_circle_3.png" />
          </div>
          <p style="color: #978fad">
            <span style="font-weight: bold; color: #2f205b">Kristy Fox</span> is
            a <span style="color: #2f205b">winner</span> in the challenge
            <span style="color: #2f205b">Democrats vs Republicans</span>
          </p>
        </div>
      </div>
    </div>
    <div class="section-wrapper">
      <div class="head">
        <h3>Joining the Challenge!</h3>
      </div>
      <div class="sections">
        <div
          class="section"
          :class="[{ active: isActive(0) }]"
          @mouseover="() => select(0)"
          @mouseleave="() => mouseleave()"
        >
          <h4>Challenge your friends</h4>
          <div>
            Download the app and start inviting your friends and family to the
            most exciting challenges platform. Invite your worthy opponents
            directly from the app!
          </div>
        </div>
        <div
          class="section"
          :class="[{ active: isActive(1) }]"
          @mouseover="() => select(1)"
          @mouseleave="() => mouseleave()"
        >
          <h4>Debate trending topics</h4>
          <div>
            Get the dialog started by challenging your friends to trending
            topics. Whethere it's sports or politics, we welcome all friendly
            challenges.
          </div>
        </div>
        <div
          class="section"
          :class="[{ active: isActive(2) }]"
          @mouseover="() => select(2)"
          @mouseleave="() => mouseleave()"
        >
          <h4>Build a reputation</h4>
          <div>
            Build a reputation by winning and voting on challenges. Got what it
            takes to get on a hot streak?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  metaInfo() {
    return {
      title: "Joining The Challenge"
    };
  },
  components: {
    StarRating
  },
  data() {
    return {
      activeSection: 0,
      timeOut: null,
      mouseover: false
    };
  },
  mounted() {
    this.timeOut = setInterval(() => {
      if (!this.mouseover) {
        switch (this.activeSection) {
          case 2:
            this.activeSection = 0;
            break;
          default:
            this.activeSection = this.activeSection + 1;
            break;
        }
      }
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timeOut);
    this.timeOut = null;
  },
  methods: {
    isActive(id) {
      return this.activeSection === id;
    },
    select(id) {
      this.mouseover = true;
      this.activeSection = id;
    },
    mouseleave() {
      this.mouseover = false;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../styles/colors.less";

.joining-the-challenge {
  > img {
    width: 100%;
  }

  .section-wrapper {
    padding: 0 24px;
  }

  .head {
    margin: 60px 0 50px;

    h3 {
      font-weight: 800;
      font-size: 32px;
      color: #2f205b;
      line-height: 1.2;
      letter-spacing: 0.0025em;
    }
  }

  .sections {
    margin: 0 0 60px;
    .section {
      margin: 0 0 40px;
      color: #978fad;
      h4 {
        font-size: 26px;
        margin: 0 0 24px;
        font-weight: 800;
        font-family: Gilroy;
        color: #2f205b;
      }
    }
  }

  .desktop-only,
  .info-user-desktop-only,
  .user-info-second {
    display: none;
  }

  .images {
    picture,
    img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .joining-the-challenge .head {
    h3 {
      font-size: 44px;
    }
  }
  .joining-the-challenge {
    display: flex;
    padding: 100px 0;
    align-items: center;
    justify-content: center;

    .section-wrapper {
      flex: 1;
      margin-right: 25px;
      max-width: 400px;

      .section {
        transition: all 0.2s ease-in-out;
        opacity: 0.5;
        padding-left: 25px;
        cursor: pointer;
        border-left: 2px solid transparent;

        h4 {
          opacity: 0.2;
        }

        &.active {
          opacity: 1;
          h4 {
            opacity: 1;
          }

          border-color: #00b7e8;
        }
      }
    }

    .images {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      justify-content: center;
      justify-items: center;
      gap: 20px;

      .image {
        // overflow: hidden;
        opacity: 0.2;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        position: relative;

        &.active {
          opacity: 1;
        }
      }

      img {
        border-radius: 8px;
        width: 200px;
        display: block;
      }

      .left {
        grid-row: ~"1/3";
        grid-column: ~"1/2";
        margin-top: 200px;
      }

      .rt {
        grid-row: ~"1/1";
        grid-column: ~"2/2";
      }

      .rb {
        grid-row: ~"2/2";
        grid-column: ~"2/2";
      }
    }

    .desktop-only,
    .info-user-desktop-only,
    .user-info-second {
      display: block;
    }

    .info-user-desktop-only {
      padding: 15px 15px;
      width: 185px;
      background: #fefefe;
      border: 1px solid #ebeff7;
      box-sizing: border-box;
      box-shadow: 0px 20px 31px rgba(47, 32, 91, 0.05),
        inset 0px 0px 0px 3px #ffffff;
      border-radius: 5px;
      img {
        width: 40px;
        margin-top: -35px;
      }

      h3 {
        font-size: 14px;
        color: @main-dark;
        font-style: normal;
        font-weight: bold;
      }
      p {
        font-size: 10px;
      }
    }
    .user-info-first {
      position: absolute;
      left: -100px;
      bottom: -50px;
    }

    .user-info-second {
      position: absolute;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      padding: 15px 15px;
      width: 280px;
      background: #fefefe;
      border: 1px solid #ebeff7;
      box-sizing: border-box;
      box-shadow: 0px 20px 31px rgba(47, 32, 91, 0.05),
        inset 0px 0px 0px 3px #ffffff;
      border-radius: 5px;
      img {
        width: 40px;
      }
      h3 {
        font-size: 14px;
        color: @main-dark;
        font-style: normal;
        font-weight: bold;
        margin: 0px;
      }
      p {
        font-size: 10px;
      }
    }

    .user-info-third {
      position: absolute;
      left: -160px;
      top: 50px;
    }
  }
}
</style>
