<template>
  <div class="get-app-wrapper landing-container">
    <div class="top">
      <h3>Challenge your friends!</h3>
      <h3 class="blue">Brag about your victory</h3>
    </div>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1440 949"
      :class="{ landscape }"
    >
      <image
        overflow="visible"
        width="348"
        height="754"
        id="imageOne"
        opacity="0"
        xlink:href="@/assets/images/frame-one-three.jpg"
        transform="matrix(1.0013 0 0 1.0013 544.5646 94.2198)"
      />
      <rect
        id="completePhoneOverlay"
        x="542"
        y="-660"
        display="inline"
        opacity="0"
        fill="#323232"
        width="358.5"
        height="743.5"
      />
      <g id="firstFrame">
        <!-- 717.6 -->
        <rect
          id="bottomControlsOverlay"
          x="535.5"
          y="836"
          display="inline"
          opacity="0"
          fill="#323232"
          width="369"
          height="118"
        />
        <g id="controls" opacity="0" display="inline">
          <g id="recordButton">
            <circle
              fill="none"
              stroke="#FFFFFF"
              stroke-width="2.3143"
              cx="720"
              cy="776.6"
              r="25.8"
            />
            <path
              fill="#ED3454"
              d="M720,758.8L720,758.8c9.8,0,17.7,7.9,17.7,17.7l0,0c0,9.8-7.9,17.7-17.7,17.7l0,0
				c-9.8,0-17.7-7.9-17.7-17.7l0,0C702.3,766.8,710.2,758.8,720,758.8z"
            />
          </g>
          <path
            id="rotationButton"
            fill="#FFFFFF"
            d="M613,778.6h1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.3-0.5,0.1-0.7l-2-2.7 c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.1-0.7,0.1l-2,2.7c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.5,0.5h1c0,1,0.3,2.1,0.8,3 c0.8,1.4,2.1,2.4,3.7,2.8c1.5,0.4,3.2,0.2,4.5-0.6c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4c-0.9,0.5-2,0.7-3,0.4 c-1-0.3-1.9-0.9-2.4-1.9C613.2,780,613,779.3,613,778.6z M621.1,777.6H620c-0.1,0-0.2,0-0.3,0.1c-0.2,0.2-0.3,0.5-0.1,0.7l2,2.7 c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.5,0.1,0.7-0.1l2-2.7c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.2-0.5-0.5-0.5h-0.9c0-1-0.3-2-0.8-2.9 c-0.8-1.4-2.1-2.4-3.6-2.8c-1.5-0.4-3.1-0.2-4.6,0.6c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4c0.9-0.5,2-0.7,3-0.4 c1,0.3,1.9,0.9,2.4,1.9C620.9,776.2,621.1,776.9,621.1,777.6z M610,768.6l1-2.2c0.5-1.1,1.6-1.8,2.7-1.8h3.1h3.4 c1.2,0,2.3,0.7,2.7,1.8l1,2.2h3c2.2,0,4,1.8,4,4v12c0,2.2-1.8,4-4,4h-20c-2.2,0-4-1.8-4-4v-12c0-2.2,1.8-4,4-4H610z"
          />
        </g>
        <rect
          id="topTextOverlay_1_"
          x="537.5"
          y="87.6"
          display="none"
          opacity="0.75"
          fill="#323232"
          width="369"
          height="152.5"
        />
        <text
          id="frameOneTitle"
          x="647"
          y="185"
          display="inline"
          opacity="0"
          fill="#FFFFFF"
          font-family="Gilroy-Semibold"
          font-size="18"
        >
          Record your video
        </text>
      </g>
      <g id="secondFrame">
        <text
          id="cancelBtn"
          opacity="0"
          transform="matrix(1 0 0 1 691.2436 770.5732)"
          display="inline"
          fill="#FFFFFF"
          font-family="Gilroy-Semibold"
          font-size="18"
        >
          Cancel
        </text>
        <g id="reviewBtn" opacity="0" display="inline">
          <path
            id="btnframe"
            fill="#FFFFFF"
            d="M847,702.6H597c-8.3,0-15-6.8-15-15v-30c0-8.3,6.8-15,15-15h250c8.3,0,15,6.8,15,15v30 C862,695.8,855.3,702.6,847,702.6z"
          />
          <text
            transform="matrix(1 0 0 1 662.7861 677.7905)"
            fill="#2F205B"
            font-family="Gilroy-Semibold"
            font-size="18"
          >
            Review Video
          </text>
        </g>
        <text
          id="editTitle"
          opacity="0"
          transform="matrix(1 0 0 1 616.5156 221.4821)"
          display="inline"
          fill="#FFFFFF"
          font-family="Gilroy-Semibold"
          font-size="28"
        >
          Edit Your Video
        </text>
        <text
          id="editText"
          transform="matrix(1 0 0 1 574.4955 266.3524)"
          display="inline"
          opacity="0"
          fill="#FFFFFF"
          font-family="Gilroy-Medium"
          letter-space="1"
          font-size="16"
        >
          <tspan x="0" y="0">
            Slide left and right on your video to trim
          </tspan>
          <tspan x="22" y="18">
            the start and the end of your video
          </tspan>
        </text>
        <g id="scisors" opacity="0" display="inline">
          <path
            fill="#FFFFFF"
            d="M726,374.8l7.1-7.1c2.7-2.7,3.2-6.9,1.3-10.2L722.8,371L726,374.8z"
          />
          <path
            fill="#FFFFFF"
            d="M724.1,376.7l-3.2-3.5l-5.5,6.4c-0.9-0.5-1.9-0.8-3-0.8c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2 s6.2-2.8,6.2-6.2c0-0.8-0.2-1.6-0.5-2.3L724.1,376.7z M712.5,388.9c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9 c2.2,0,3.9,1.8,3.9,3.9C716.4,387.1,714.7,388.9,712.5,388.9z"
          />
          <path
            fill="#FFFFFF"
            d="M731.6,378.8c-1.1,0-2.1,0.3-3,0.8l-18.9-22.1c-2,3.3-1.5,7.5,1.3,10.2l14.9,14.9 c-0.3,0.7-0.5,1.5-0.5,2.3c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2C737.7,381.6,735,378.8,731.6,378.8z M731.6,388.9 c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9c2.2,0,3.9,1.8,3.9,3.9C735.5,387.1,733.7,388.9,731.6,388.9z"
          />
        </g>
        <g id="timeline" opacity="0" display="inline">
          <g id="dots">
            <circle fill="#FFFFFF" cx="594.2" cy="547.3" r="2.7" />
            <circle fill="#FFFFFF" cx="669.6" cy="547.3" r="2.7" />
            <circle fill="#FFFFFF" cx="745.8" cy="547.3" r="2.7" />
            <circle fill="#FFFFFF" cx="822.1" cy="547.3" r="2.7" />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="603.2"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="609.5"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="615.8"
              cy="547.3"
              r="1.8"
            />
            <circle opacity="0.1" fill="#FFFFFF" cx="622" cy="547.3" r="1.8" />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="628.3"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="634.6"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="640.9"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="647.2"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="653.4"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="659.7"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="679.5"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="685.7"
              cy="547.3"
              r="1.8"
            />
            <circle opacity="0.1" fill="#FFFFFF" cx="692" cy="547.3" r="1.8" />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="698.3"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="704.6"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="710.8"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="717.1"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="723.4"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="729.7"
              cy="547.3"
              r="1.8"
            />
            <circle opacity="0.1" fill="#FFFFFF" cx="736" cy="547.3" r="1.8" />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="754.8"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="761.1"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="767.3"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="773.6"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="779.9"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="786.2"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="792.5"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="798.7"
              cy="547.3"
              r="1.8"
            />
            <circle opacity="0.1" fill="#FFFFFF" cx="805" cy="547.3" r="1.8" />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="811.3"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="831.9"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="838.2"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="844.5"
              cy="547.3"
              r="1.8"
            />
            <circle
              opacity="0.1"
              fill="#FFFFFF"
              cx="850.8"
              cy="547.3"
              r="1.8"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 586.3599 568.9773)"
            fill="#FFFFFF"
            font-family="Gilroy-Medium"
            font-size="12"
          >
            00
          </text>
          <text
            transform="matrix(1 0 0 1 662.3973 568.9773)"
            fill="#FFFFFF"
            font-family="Gilroy-Medium"
            font-size="12"
          >
            05
          </text>
          <text
            transform="matrix(1 0 0 1 739.7569 568.9773)"
            fill="#FFFFFF"
            font-family="'Gilroy-Medium'"
            font-size="12"
          >
            10
          </text>
          <text
            transform="matrix(1 0 0 1 816.5159 568.9773)"
            fill="#FFFFFF"
            font-family="Gilroy-Medium"
            font-size="12"
          >
            15
          </text>
        </g>
        <image
          display="inline"
          opacity="0"
          overflow="visible"
          width="255"
          height="76"
          id="timelineFrames"
          xlink:href="@/assets/images/video-timeline.jpg"
          transform="matrix(1 0 0 1 594.5323 459.3556)"
        />
        <g
          class="timelineBtn"
          opacity="0"
          id="leftTimelineBtn"
          display="inline"
        >
          <path
            fill="#2F205B"
            d="M569.9,466.6c0-4,3.2-7.2,7.2-7.2h15.4v76h-15.4c-4,0-7.2-3.2-7.2-7.2V466.6z"
          />
          <path
            fill="#FFFFFF"
            d="M580.8,486.5L580.8,486.5c0.5,0,0.9,0.4,0.9,0.9v19c0,0.5-0.4,0.9-0.9,0.9l0,0c-0.5,0-0.9-0.4-0.9-0.9v-19 C579.9,486.9,580.3,486.5,580.8,486.5z"
          />
        </g>
        <g
          class="timelineBtn"
          opacity="0"
          id="rightTimelineBtn"
          display="inline"
        >
          <path
            fill="#2F205B"
            d="M874.2,528.1c0,4-3.2,7.2-7.2,7.2h-15.4v-76h15.4c4,0,7.2,3.2,7.2,7.2V528.1z"
          />
          <path
            fill="#FFFFFF"
            d="M863.3,508.2L863.3,508.2c-0.5,0-0.9-0.4-0.9-0.9v-19c0-0.5,0.4-0.9,0.9-0.9l0,0c0.5,0,0.9,0.4,0.9,0.9v19 C864.2,507.8,863.8,508.2,863.3,508.2z"
          />
        </g>
      </g>
      <g id="thirdFrame">
        <g id="progressTrack" display="inline" opacity="0">
          <text
            transform="matrix(1 0 0 1 569.2756 686.9373)"
            fill="#FFFFFF"
            font-family="Gilroy-Medium"
            font-size="12"
          >
            0:14
          </text>
          <text
            transform="matrix(1 0 0 1 842.3536 686.9373)"
            fill="#FFFFFF"
            font-family="Gilroy-Medium"
            font-size="12"
          >
            -1:23
          </text>
          <path
            opacity="0.3"
            fill="#FFFFFF"
            d="M571.5,700.6h297c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2h-297 c-1.1,0-2-0.9-2-2l0,0C569.5,701.5,570.4,700.6,571.5,700.6z"
          />
          <path
            fill="#FFFFFF"
            d="M571.5,700.6h223c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2h-223c-1.1,0-2-0.9-2-2l0,0 C569.5,701.5,570.4,700.6,571.5,700.6z"
          />
        </g>
        <g id="thirdFrameCancelBtn" opacity="0">
          <circle fill="#ED3454" cx="600.5" cy="765.6" r="31" />
          <path
            fill="#FFFFFF"
            d="M611,755c-0.6-0.6-1.6-0.6-2.2,0l-8.3,8.3l-8.3-8.3c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2 l8.3,8.3l-8.3,8.3c-0.6,0.6-0.6,1.6,0,2.2c0.3,0.3,0.6,0.5,1.1,0.5c0.5,0,0.8-0.2,1.1-0.5l8.3-8.3l8.3,8.3 c0.3,0.3,0.8,0.5,1.1,0.5c0.3,0,0.8-0.2,1.1-0.5c0.6-0.6,0.6-1.6,0-2.2l-8.3-8.3l8.3-8.3C611.7,756.6,611.7,755.7,611,755z"
          />
        </g>
        <g id="thirdFrameAcceptBtn" opacity="0">
          <circle fill="#00B7E8" cx="839.5" cy="765.6" r="31" />
          <path
            fill="#FFFFFF"
            d="M847.4,757.1L835.8,769l-4.2-4.3c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.6 c-0.3,0.4-0.5,0.8-0.5,1.3c0,0.5,0.2,1,0.5,1.3l5.5,5.7c0.3,0.4,0.8,0.6,1.3,0.6c0.5,0,1-0.2,1.3-0.6l12.9-13.2 c0.3-0.4,0.5-0.8,0.5-1.3c0-0.5-0.2-1-0.5-1.3c-0.3-0.4-0.8-0.5-1.3-0.6C848.2,756.6,847.7,756.7,847.4,757.1z"
          />
        </g>
        <text
          id="saveVideoText"
          opacity="0"
          transform="matrix(1 0 0 1 665.8705 759.3555)"
          fill="#FFFFFF"
          font-family="Gilroy-Semibold"
          font-size="20"
        >
          <tspan x="0" y="0">
            Do you like
          </tspan>
          <tspan x="2.1" y="24">
            the video?
          </tspan>
        </text>
      </g>
      <image
        overflow="visible"
        width="349"
        height="729"
        id="frameFour"
        opacity="0"
        xlink:href="@/assets/images/frame-four.jpg"
        transform="matrix(1 0 0 1 902.0352 97.8556)"
      />
      <image
        overflow="visible"
        width="348"
        height="729"
        id="frameFive"
        opacity="0"
        xlink:href="@/assets/images/frame-five.jpg"
        transform="matrix(1.0077 0 0 1.0027 901.6647 95.8556)"
      />
      <!-- Base Image -->
      <image
        overflow="visible"
        width="1440"
        height="949"
        id="baseImage"
        xlink:href="@/assets/images/create-challenge-base.png"
        transform="matrix(0.9999 0 0 0.9999 0 0)"
        @load="imageLoaded"
      />
    </svg>
    <div class="app-link">
      <download-app />
      <div class="bottom">
        YOUvME Android app coming soon to the Google Play Store
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import DownloadApp from "./DownloadApp.vue";

export default {
  metaInfo() {
    return {
      title: "Get App"
    };
  },
  components: {
    DownloadApp
  },
  data() {
    return {
      landscape: false
    };
  },
  mounted() {
    this.frameOneTimeline = gsap
      .timeline({ paused: true })
      .to("#completePhoneOverlay", {
        opacity: 0.75,
        y: 156,
        duration: 0.25,
        ease: "power2.out"
      })
      .to(
        "#bottomControlsOverlay",
        { opacity: 0.75, y: -118.4, duration: 0.25, ease: "power2.out" },
        0
      )
      .to(
        "#frameOneTitle",
        { opacity: 1, duration: 0.25, ease: "power2.out" },
        0.2
      )
      .to("#controls", { opacity: 1, duration: 0.25, ease: "power2.out" }, 0.2);

    this.frameTwoTimeline = gsap
      .timeline({ paused: true })
      .to("#frameOneTitle", { opacity: 0, duration: 0.25, ease: "power2.out" })
      .to("#controls", { opacity: 0, duration: 0.25, ease: "power2.out" }, 0)
      .to(
        "#bottomControlsOverlay",
        { y: 1, duration: 0.25, ease: "power2.out" },
        0.2
      )
      .to(
        "#completePhoneOverlay",
        { y: 748, duration: 0.4, ease: "power2.out" },
        0.2
      )
      .to("#editTitle", { opacity: 1, duration: 0.25 }, 0.45)
      .to("#editText", { opacity: 1, duration: 0.25 }, 0.55)
      .to("#scisors", { opacity: 0.35, duration: 0.25 }, 0.65)
      .to(
        ["#timeline", "#timelineFrames", ".timelineBtn"],
        { opacity: 1, duration: 0.25 },
        0.75
      )
      .to("#reviewBtn", { opacity: 1, duration: 0.25 }, 0.85)
      .to("#cancelBtn", { opacity: 1, duration: 0.25 }, 0.85);

    this.frameThreeTimeline = gsap
      .timeline({ paused: true })
      .to("#editTitle", { opacity: 0, duration: 0.25 })
      .to("#editText", { opacity: 0, duration: 0.25 }, 0.1)
      .to("#scisors", { opacity: 0, duration: 0.25 }, 0.15)
      .to(
        ["#timeline", "#timelineFrames", ".timelineBtn"],
        { opacity: 0, duration: 0.25 },
        0.2
      )
      .to("#reviewBtn", { opacity: 0, duration: 0.25 }, 0.25)
      .to("#cancelBtn", { opacity: 0, duration: 0.25 }, 0.25)
      .to(
        "#completePhoneOverlay",
        { y: 1316, duration: 0.35, ease: "power2.out" },
        0.2
      )
      .to("#progressTrack", { opacity: 1, duration: 0.25 }, 0.4)
      .to(
        ["#thirdFrameCancelBtn", "#saveVideoText", "#thirdFrameAcceptBtn"],
        { opacity: 1, duration: 0.25, stagger: 0.1 },
        0.5
      );

    this.frameFourTimeline = gsap
      .timeline({ paused: true })
      .to("#frameFour", { duration: 0.4, x: 545, ease: "power2.out" })
      .to("#thirdFrame", { duration: 0.4, x: -349, ease: "power2.out" }, 0)
      .to("#imageOne", { duration: 0.4, x: 188, ease: "power2.out" }, 0)
      .set("#progressTrack", { opacity: 0 })
      .set(["#thirdFrameCancelBtn", "#saveVideoText", "#thirdFrameAcceptBtn"], {
        opacity: 0
      })
      .set("#thirdFrame", { x: 0 })
      .set("#completePhoneOverlay", { y: 0, opacity: 0 })
      .set("#imageOne", { x: 903 });

    this.frameFiveTimeline = gsap
      .timeline({ paused: true })
      .to("#frameFive", { duration: 0.4, ease: "power2.out", x: 545 })
      .to("#frameFour", { duration: 0.4, x: 188, ease: "power2.out" }, 0)
      .set("#frameFour", { x: 903 })
      .to(
        "#imageOne",
        { x: 544.5646, duration: 0.4, ease: "power2.out" },
        "+=2"
      )
      .to("#frameFive", { x: 188, duration: 0.4, ease: "power2.out" }, "<")
      .set("#frameFive", { x: 903 });
    //
    this.mainTimeline = gsap
      .timeline({ paused: true, repeat: -1 })
      .add(this.frameOneTimeline.paused(false))
      .add(this.frameTwoTimeline.paused(false), "+=2.5")
      .add(this.frameThreeTimeline.paused(false), "+=2.5")
      .add(this.frameFourTimeline.paused(false), "+=2.5")
      .add(this.frameFiveTimeline.paused(false), "+=2.5");
    //
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    this.mainTimeline.kill();
    this.frameOneTimeline.kill();
    this.frameTwoTimeline.kill();
    this.frameThreeTimeline.kill();
    this.frameFourTimeline.kill();
    this.frameFiveTimeline.kill();
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    imageLoaded() {
      gsap.set(["#imageOne", "#frameFour", "#frameFive"], { opacity: 1 });
      this.mainTimeline.play();
    },
    resizeHandler() {
      const { innerWidth, innerHeight } = window;
      this.landscape = innerHeight > innerWidth;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
.get-app-wrapper {
  padding: 50px 0;

  .top {
    padding: 0 5px;

    h3 {
      color: @main-dark;
      text-align: center;
      font-size: 60px;
      font-weight: 800;
      line-height: 1.2;
      margin: 0;
    }

    .blue {
      margin: 8px 0 20px;
      color: #00b7e8;
    }
  }

  .app-link {
    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 0 0;
    }

    svg {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    .bottom {
      margin: 25px 0 0;
      color: #2f205b;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      font-weight: 500;
    }
  }

  .image > picture img {
    width: 100%;
  }

  .img-wrapper-challenge {
    position: relative;
    .img-slide-item-challenge {
      position: absolute;
      top: 10%;
      left: 38%;
      img {
        width: 86%;
      }
    }
  }

  svg {
    width: auto;
    margin-left: 0;
  }

  svg.landscape {
    width: 220%;
    margin-left: -60%;
  }
}

@media screen and (max-width: 768px) {
  .get-app-wrapper {
    .top {
      h3 {
        font-size: 32px;
        line-height: 1.2;
      }
    }
  }
  .img-wrapper-challenge {
    .img-slide-item-challenge {
      position: absolute;
      top: 10%;
      left: 38% !important;
      img {
        width: 46% !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .img-wrapper-challenge {
    .img-slide-item-challenge {
      position: absolute;
      top: 10%;
      left: 38% !important;
      img {
        width: 39% !important;
      }
    }
  }
}
</style>
