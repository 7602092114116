<template>
  <base-modal
    :title="'Change password'"
    :modalVisible="modalVisible"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    @closeModal="$emit('closeModal')"
    :width="'480px'"
  >
    <template v-slot:modal-content>
      <a-form-model
        ref="addUserForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit="onSubmit"
      >
        <base-form-item :prop="'password'" :label="'New password'">
          <template v-slot:form-input>
            <a-input
              v-model="form.password"
              type="password"
              placeholder="Password"
            />
          </template>
        </base-form-item>
        <base-form-item :prop="'confirmPassword'" :label="'Confirm password'">
          <template v-slot:form-input>
            <a-input
              v-model="form.confirmPassword"
              type="password"
              ref="password"
              placeholder="Confirm password"
            />
          </template>
        </base-form-item>
        <a-row type="flex" justify="center" class="submit-button-container"
          ><a-button
            id="change-password-button"
            html-type="submit"
            :loading="isSubmitting"
          >
            Submit
          </a-button></a-row
        >
      </a-form-model>
    </template>
  </base-modal>
</template>

<script>
import { message } from "ant-design-vue";
import { userApi } from "../../apis";
import BaseFormItem from "../base/BaseFormItem.vue";
import BaseModal from "../base/BaseModal.vue";

export default {
  metaInfo() {
    return {
      title: "Delete User Modal"
    };
  },
  name: "DeleteUserModal",
  props: ["modalVisible", "maskStyle", "userInfo", "dialogStyle"],
  components: {
    BaseModal,
    BaseFormItem
  },
  data() {
    return {
      isSubmitting: false,
      wrapperCol: { span: 24 },
      labelCol: { span: 24 },
      form: {
        password: "",
        confirmPassword: ""
      },
      rules: {
        password: [
          {
            required: true,
            message: "Password cannot be empty",
            trigger: "change"
          },
          {
            min: 8,
            message: "Password must be in range 8-24",
            trigger: "change"
          },
          {
            max: 24,
            message: "Password must be in range 8-24",
            trigger: "change"
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "Comfirm Password cannot be empty",
            trigger: "change"
          },
          {
            min: 8,
            message: "Password must be in range 8-24",
            trigger: "change"
          },
          {
            max: 24,
            message: "Password must be in range 8-24",
            trigger: "change"
          },
          {
            validator: this.confirmPassword
          }
        ]
      }
    };
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.$refs.addUserForm.validate(async valid => {
        if (valid) {
          this.isSubmitting = true;
          userApi
            .changePassword({
              userId: this.userInfo.id,
              password: this.form.password
            })
            .then(() => {
              this.isSubmitting = false;
              this.$emit("closeModal");
              message.success("Change password successful.");
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$emit("closeModal");
              message.error(err.message);
            });
        }
      });
    },
    confirmPassword(rule, value, callback) {
      if (value && value != this.form.password) {
        return callback("Two passwords that you enter is inconsistent!");
      }
      return callback();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.delete-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 350px;
  > div:nth-child(1) {
    .gilroy(14px, @main-dark);
  }
}

.delete-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  .gilroy-bold(26px, @main-dark);
}

.delete-button {
  .red-button(127px);
  margin-right: 5px;
}

.no-delete-button {
  .secondary-button(135px);
  width: 135px;
  margin-left: 5px;
}
#change-password-button {
  .secondary-button(115px);
}
</style>
