<template>
  <div id="app">
    <a-layout
      id="components-layout-demo-fixed-sider"
      style="min-height: 100vh"
      v-if="$route.meta.layoutDefault"
    >
      <admin-layout>
        <SideBar @toggleSidebar="onToggle" :activeRoute="activeRoute" />
        <a-layout
          :style="
            sidebarCollappsed
              ? { marginLeft: '80px', marginTop: '60px' }
              : { marginLeft: '256px', marginTop: '60px' }
          "
          class="main-layout"
        >
          <Header :sidebarCollapsed="sidebarCollappsed" />
          <a-layout-content id="my-main-content">
            <router-view
              :key="$route.fullPath"
              :collapsed="sidebarCollappsed"
            />
          </a-layout-content>
        </a-layout>
      </admin-layout>
    </a-layout>
    <router-view
      :key="$route.fullPath"
      :collapsed="sidebarCollappsed"
      v-if="!$route.meta.layoutDefault"
    />
  </div>
</template>
<script>
import firebase from "firebase";
import SideBar from "./components/SideBar.vue";
import TheHeader from "./components/TheHeader.vue";
import { version } from "../package.json";
import { isPWA } from "./helpers/";
import AdminLayout from "./views/layouts/AdminLayout.vue";
export default {
  components: { SideBar, Header: TheHeader, AdminLayout },
  data() {
    return {
      sidebarCollappsed: false,
      activeRoute: ""
    };
  },
  methods: {
    onToggle() {
      this.sidebarCollappsed = !this.sidebarCollappsed;
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(value) {
        this.activeRoute = value.path.substring(1);
      }
    }
  },
  beforeMount() {
    const page_path = location.pathname + location.search;
    firebase.analytics().setCurrentScreen(page_path);
    firebase.analytics().logEvent("page_view");
    firebase.analytics().logEvent("screen_view", {
      app_name: isPWA() ? "pwa" : "web",
      screen_name: page_path,
      app_version: version
    });
  },
  mounted() {
    this.activeRoute = this.$route.path.substring(1);
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.$analytics.setUserId(user.uid);
        this.$analytics.setUserProperties({
          account_type: "Basic"
        });
      }
    });
  }
};
</script>

<style lang="less" scoped>
.ant-layout {
  background-color: #f8f8f9;
}

.ant-layout-content {
  margin: 20px 30px;
}

@media screen and (max-width: 460px) {
  .ant-layout-content {
    margin: 10px 10px;
  }
}

@transition: margin-left 0.1s linear;

.main-layout {
  transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  -webkit-transition: @transition;
}
</style>
