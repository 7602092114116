<template>
  <div class="challenge-wrapper">
    <!-- <div class="test" ref="test" /> -->
    <VueSlickCarousel
      :arrows="false"
      :dots="false"
      :autoplay="true"
      :adaptive-height="true"
    >
      <picture>
        <source
          srcset="@/assets/images/challenges-1-desktop.jpg"
          media="(min-width: 768px)"
        />
        <img src="@/assets/images/challenges-1-mobile.jpg" />
      </picture>
      <picture>
        <source
          srcset="@/assets/images/challenges-2-desktop.jpg"
          media="(min-width: 768px)"
        />
        <img src="@/assets/images/challenges-2-mobile.jpg" />
      </picture>
      <picture>
        <source
          srcset="@/assets/images/challenges-3-desktop.jpg"
          media="(min-width: 768px)"
        />
        <img src="@/assets/images/challenges-3-mobile.jpg" />
      </picture>
      <picture>
        <source
          srcset="@/assets/images/challenges-4-desktop.jpg"
          media="(min-width: 768px)"
        />
        <img src="@/assets/images/challenges-4-mobile.jpg" />
      </picture>
      <picture>
        <source
          srcset="@/assets/images/challenges-5-desktop.jpg"
          media="(min-width: 768px)"
        />
        <img src="@/assets/images/challenges-5-mobile.jpg" />
      </picture>
    </VueSlickCarousel>
    <!-- <div class="clouds-container" ref="cloudsContainer" /> -->
  </div>
</template>

<script>
import { gsap } from "gsap";
import VueSlickCarousel from "vue-slick-carousel";
import * as Clouds from "@/helpers/clouds-generator/index";

// Clouds Settings
const cloudsSettings = [
  {
    type: "app",
    speedRotateFireFlies: 1.0,
    speedAnimation: 1.5,
    cloudVisible: true,
    particleVisble: false
    // backgroundColor: "0x2B235D"
  },
  {
    type: "smoke",
    smokeOpacity: 0.2,
    smokeColor: "0x823e98"
  },
  {
    cloudNumber: 15,
    areaX: 500,
    areaY: 500,
    areaZ: 500
  }
];
const cloudsAssets = [
  {
    type: "texture",
    name: "smoke",
    path: "../../assets/images/",
    url: "smoke.png"
  }
  // {
  //   type: "texture",
  //   name: "firefliesV2",
  //   path: "../../assets/images/",
  //   url: "particle-test-3.png"
  // },
];

export default {
  metaInfo() {
    return {
      title: "Challenge Slides"
    };
  },
  components: {
    VueSlickCarousel
  },
  mounted() {
    /* this.clouds = null; */
    /* this.initSmoke(); */
  },
  beforeDestroy() {
    /* this.clouds.stopAnimation(); */
  },
  methods: {
    initSmoke() {
      const _cloudsContainer = this.$refs.cloudsContainer;
      setTimeout(() => {
        if (_cloudsContainer.clientHeight < 200) {
          this.initSmoke();
        } else {
          this.clouds = new Clouds.App(
            this.$refs.cloudsContainer,
            cloudsAssets,
            cloudsSettings
          );
          this.clouds.init();
          gsap.to(_cloudsContainer, { duration: 0.4, opacity: 1 });
        }
      }, 150);
    }
  }
};
</script>
<style lang="less" scoped>
.challenge-wrapper {
  position: relative;
  picture,
  img {
    width: 100%;
  }

  .clouds-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }
}
</style>
