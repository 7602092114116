import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export const getDuration = value => {
  return moment.duration(value, "seconds").format("hh:mm:ss");
};

export const getSeconds = value => {
  return moment.duration(value).asSeconds();
};

export const getReadableDuration = value => {
  const duration = getDuration(value).split(":");
  return `${duration[0] || 0} Hrs ${duration[1] || "00"} mins ${duration[2] || "00"} secs`;
};
