<template>
  <div class="admin-layout-wrapper">
    <slot v-if="check" />
  </div>
</template>
<script>
import { checkAuthentication } from "../../helpers";
export default {
  metaInfo() {
    return {
      title: "Admin Layout"
    };
  },
  name: "AdminLayout",
  beforeCreate() {
    if (!checkAuthentication()) {
      this.$router.push("/login");
      return;
    }
  },
  data() {
    return {
      check: checkAuthentication()
    };
  }
};
</script>
<style lang="less" scoped>
.admin-layout-wrapper {
  width: 100%;
}
</style>
