<template>
  <div class="description-video landing-container">
    <video
      src="@/assets/videos/video.mp4"
      :autoplay="true"
      :loop="true"
      muted
      :controls="false"
    />
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Description Video"
    };
  }
};
</script>
<style lang="less" scoped>
.description-video {
  video {
    width: 100%;
  }
}
</style>
