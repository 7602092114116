<template>
  <base-modal
    :title="'Add Category'"
    :closable="true"
    :width="'480px'"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    :modalVisible="modalVisible"
    @closeModal="$emit('closeAddModal')"
  >
    <template v-slot:modal-content>
      <a-row type="flex" justify="space-between" class="image-input">
        <a-col>
          <a-avatar :size="56" icon="user" :src="displayedIcon" />
        </a-col>
        <a-col>
          <a-input
            type="file"
            id="upload"
            accept="image/*"
            @change="handleUpload"
          />
          <label for="upload">
            <span role="button">
              <span>Add Photo</span>
            </span>
          </label>
        </a-col>
      </a-row>
      <a-form-model
        ref="addCategoryForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit="onSubmit"
      >
        <base-form-item :prop="'name'" :label="'Name'">
          <template v-slot:form-input>
            <a-input v-model="form.name" />
          </template>
        </base-form-item>
        <base-form-item :prop="'description'" :label="'Description'">
          <template v-slot:form-input>
            <a-input v-model="form.description" />
          </template>
        </base-form-item>
        <base-form-item :prop="'color'" :label="'Background Color'">
          <template v-slot:form-input>
            <verte
              picker="square"
              model="hex"
              v-model="formColor"
              menuPosition="top"
            ></verte>
            <div
              :style="{
                backgroundColor: formColor,
                height: '48px',
                width: '100%',
                borderRadius: '4px'
              }"
            ></div>
          </template>
        </base-form-item>
        <base-form-item :prop="'status'" :label="'Status'">
          <template v-slot:form-input>
            <base-select-dropdown
              :valueList="[
                { key: 'active', label: 'Active' },
                { key: 'inactive', label: 'Inactive' }
              ]"
              :defaultValue="{ key: 'active' }"
              @selected="onSelect"
            >
            </base-select-dropdown>
          </template>
        </base-form-item>
        <a-row type="flex" justify="center" class="row__mt--40"
          ><a-button
            class="button__state--secondary"
            html-type="submit"
            :loading="isSubmitting"
          >
            Add Category
          </a-button></a-row
        >
      </a-form-model>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../base/BaseModal.vue";
import BaseFormItem from "../base/BaseFormItem.vue";
import BaseSelectDropdown from "../base/BaseSelectDropdown.vue";
import Verte from "verte";
import { categoryApi } from "../../apis";
import { message } from "ant-design-vue";
import { mapActions } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Category Add Modal"
    };
  },
  name: "CategoryAddModal",
  props: ["collapsed", "modalVisible"],
  components: {
    BaseModal,
    BaseFormItem,
    BaseSelectDropdown,
    Verte
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        status: "active"
      },
      rules: {
        name: [
          {
            required: true,
            message: "Name cannot be empty",
            trigger: "change"
          }
        ],
        description: [
          {
            required: true,
            message: "Username cannot be empty",
            trigger: "blur"
          }
        ]
      },
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      isSubmitting: false,
      displayedIcon: null,
      formIcon: null,
      formColor: "#F5F5DC"
    };
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      const marginTop = "60px";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft,
        "margin-top": marginTop
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "4%" : "8.5%"
      };
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: "FETCH_CATEGORIES"
    }),
    handleUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.formIcon = file;
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const imageBuffer = reader.result;
          this.displayedIcon = imageBuffer;
        };
      }
    },
    onSelect(selected) {
      this.form.status = selected;
    },
    onSubmit(e) {
      e.preventDefault();
      this.$refs.addCategoryForm.validate(async valid => {
        if (valid) {
          let formData = new FormData();
          formData.append("title", this.form.name);
          formData.append("description", this.form.description);
          formData.append("status", this.form.status === "active");
          formData.append("icon", this.formIcon);
          formData.append("background", this.formColor);
          this.isSubmitting = true;
          const { success } = await categoryApi.createCategory(formData);
          this.isSubmitting = false;
          formData = null;
          if (success) {
            message.success("Category added successfully");
            await this.fetchCategories();
            this.form.name = "";
            this.form.description = "";
            this.formIcon = null;
            this.displayedIcon = null;
            this.$emit("closeAddModal");
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.image-input {
  box-sizing: border-box;
  margin-bottom: 20px;
}

#upload {
  display: none !important;
}

label {
  &:focus {
    outline: none !important;
  }
  > span[role="button"] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 124px;
    background-color: rgba(47, 69, 104, 0.05);
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.01em;
    .poppins-medium(12px, @blue);
  }
}

.user-avatar {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  object-fit: cover;
}

.button__state--secondary {
  .secondary-button(146px);
}

.row__mt--40 {
  margin-top: 40px;
}

.verte {
  position: absolute;
  top: -4px;
  left: 115px;
  z-index: 30;
  cursor: pointer !important;
}
</style>
