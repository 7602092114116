<template>
  <div class="card-container">
    <a-avatar :size="50" :style="{ 'background-color': bgColor }">
      <img
        :src="require(`../../assets/images/${name}.svg`)"
        width="20"
        height="20"
        :alt="`${name}-icon`"
      />
    </a-avatar>
    <div class="card-value">{{ value }}</div>
    <div class="card-label">{{ label }}</div>
    <div :class="status === 'up' ? 'card-change-up' : 'card-change-down'">
      <a-icon :type="status === 'up' ? 'caret-up' : 'caret-down'"></a-icon>
      <span>{{ change }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Overview Card"
    };
  },
  name: "OverviewCard",
  props: ["value", "label", "status", "change", "name", "bgColor"]
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.card-container {
  width: 100%;
  height: 215px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  background-color: @white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px;
  box-shadow: 0px 17px 20px rgba(50, 50, 50, 0.03);
}

.ant-avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.card-value {
  .poppins-bold(26px, @main-dark);
  letter-spacing: 0.01em;
  line-height: 39px;
}

.card-label {
  .poppins-light(14px, @main-dark);
  letter-spacing: 0, 01em;
  line-height: 21px;
}

.card-change-up {
  > .anticon,
  span {
    .poppins-medium(14px, @green);
    line-height: 21px;
  }
}

.card-change-down {
  > .anticon,
  span {
    .poppins-medium(14px, @red);
    line-height: 21px;
  }
}
</style>
