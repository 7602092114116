import { render, staticRenderFns } from "./CategoryDeleteModal.vue?vue&type=template&id=2426a00c&scoped=true&"
import script from "./CategoryDeleteModal.vue?vue&type=script&lang=js&"
export * from "./CategoryDeleteModal.vue?vue&type=script&lang=js&"
import style0 from "./CategoryDeleteModal.vue?vue&type=style&index=0&id=2426a00c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2426a00c",
  null
  
)

export default component.exports