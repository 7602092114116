<template>
  <base-modal
    :closable="false"
    :modalVisible="modalVisible"
    @closeModal="closeCommentModal"
    :maskStyle="maskStyle"
    :dialogStyle="dialogStyle"
    :width="'70%'"
  >
    <template v-slot:modal-content>
      <a-row type="flex" justify="space-between">
        <a-col :md="16" :xs="24" class="comment-modal-title">
          <span>{{ commentRef.username }}</span
          ><span class="comments-text">Comments</span>
        </a-col>
        <a-col :md="8" :xs="24">
          <span class="icon-container" @click="search">
            <a-icon type="search"></a-icon>
          </span>
          <a-input
            id="search"
            placeholder="Search for comments"
            style="padding-left: 40px"
            v-model="searchedValue"
          >
          </a-input>
        </a-col>
      </a-row>
      <div class="comments-container">
        <div v-if="!comments.length">No comments yet.</div>
        <div v-if="comments.length">
          <ChallengeCommentItem
            v-for="comment in comments"
            :comment="comment"
            :key="comment.key"
            :challengeId="challengeId"
          />
        </div>
      </div>
      <a-row type="flex" justify="center">
        <a-button @click="closeCommentModal" class="button-primary"
          >Close Comments</a-button
        >
      </a-row>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../base/BaseModal.vue";
import ChallengeCommentItem from "./ChallengeCommentItem.vue";
import { challengeAction } from "../../store/actions";
import { mapState } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Challenge Comments Modal"
    };
  },
  name: "ChallengeCommentsModal",
  props: ["modalVisible", "collapsed", "challengeId", "commentRef"],
  inject: ["closeCommentModal"],
  components: {
    BaseModal,
    ChallengeCommentItem
  },
  data() {
    return {
      searchedValue: ""
    };
  },
  computed: {
    maskStyle() {
      const backgroundColor = "rgba(47, 69, 104, 0.8)";
      let marginLeft = this.collapsed ? "80px" : "256px";
      return {
        "background-color": backgroundColor,
        "margin-left": marginLeft
      };
    },
    dialogStyle() {
      return {
        left: this.collapsed ? "2.5%" : "8.5%"
      };
    },
    ...mapState({
      comments: state => state.challenge.comments
    })
  },
  methods: {
    search() {
      this.searchedValue !== "" && console.log(this.searchedValue);
      this.searchedValue = "";
    }
  },
  created() {
    this.$store.dispatch(challengeAction.FETCH_CHALLENGE_COMMENTS, {
      challengeId: this.challengeId,
      commentRef: this.commentRef.userId
    });
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.icon-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgba(47, 32, 91, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  bottom: 9px;
  left: 7px;
  cursor: pointer;
}

.comment-modal-title {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  > span:nth-child(1) {
    .gilroy-bold(24px, @blue);
    margin-right: 10px;
  }
  > span:nth-child(2) {
    .gilroy(24px, @main-dark);
  }
}

.comments-container {
  height: 500px;
  box-sizing: border-box;
  margin: 30px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  .scroll-bar();
}

.button-primary {
  .primary-button(160px);
}

@media screen {
  @media (max-width: 450px) {
    .comments-text {
      display: none;
    }
  }
}
</style>
