<template>
  <base-icon :viewBox="'0 0 12 16'">
    <template v-slot:icon-name>
      <path
        class="st0"
        :class="{ active: active }"
        d="M11.3,0.5H0.7c-0.3,0-0.6,0.3-0.6,0.6v13.8c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6V9.3h9.9
	c0.3,0,0.6-0.3,0.6-0.6V1.1C11.8,0.8,11.6,0.5,11.3,0.5z"
      />
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "../base/BaseIcon.vue";
export default {
  name: "Users",
  props: ["active"],
  components: {
    BaseIcon
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";

.st0 {
  fill: #454176;
}

.active {
  fill: @blue;
}
</style>
