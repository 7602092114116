import { base } from "../configs";
import { app } from "./firebase";
import localForage from "localforage";
const db = app.database();

export const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
};

export const setCookie = ({ key, value, days }) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = key + "=" + (value || "") + expires + "; path=/";
};

export const checkAuthentication = () => {
  if (
    !localStorage.getItem("youvme_token") ||
    !getCookie("a_d") ||
    getCookie("a_d") != base.hash
  ) {
    return false;
  }

  return true;
};

/**
 * Get challenge comments from Firebase
 * @param {*} param0
 */
export const getCommentChallenge = ({ challengeId }) =>
  new Promise((resolve, reject) => {
    db.ref(`comments-${challengeId}`)
      .limitToLast(100)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()) {
          const comments = Object.entries(snapshot.val());
          const data = comments.map(comment => ({
            ...comment[1],
            key: comment[0]
          }));
          return resolve(data);
        }

        return resolve([]);
      })
      .catch(err => reject(err));
  });

export const removeCommentChallenge = ({ challengeId, commentRef, key }) => {
  console.log(challengeId, " ", commentRef, " ", key);
  const cmtRef = db.ref(`comments-${challengeId}/${commentRef}`).child(key);
  cmtRef
    .remove()
    .then(function() {
      console.log("success");
    })
    .catch(function() {
      console.log("failed");
    });
};

export const removeItemLocalForage = key => localForage.removeItem(key);
export const isPWA = () => {
  return window && window.matchMedia("(display-mode: standalone)").matches;
};
