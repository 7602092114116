<template>
  <div
    class="card-container"
    :class="{ deleted: status === 'deleted' }"
    @click="
      status !== 'deleted' &&
        $router.push(`/admin/challenges/${categoryId}/${caseId}`)
    "
  >
    <a-tag
      :color="badgeColor"
      class="case-tag"
      :class="{
        ended: (status === 'ended') & !ownerWon
      }"
      >{{ status }}</a-tag
    >
    <span
      class="case-trophy"
      v-if="status === 'ended'"
      :class="{ 'opponent-won': !ownerWon }"
    >
      <img src="../../assets/images/trophy.svg" alt="trophy" />
    </span>
    <div class="card-cover">
      <img
        v-if="ownerAvatar"
        :src="ownerAvatar"
        alt="owner-image"
        class="my-image"
      />
      <div v-else class="container__bg--gray my-image-alternative">
        <span>{{ ownerName.slice(0, 2) }}</span>
      </div>
      <ThunderIcon class="separator" />
      <img
        v-if="opponentAvatar"
        :src="opponentAvatar"
        alt="opponent-image"
        class="opponent-image"
      />
      <div v-else class="container__bg--gray opponent-image-alternative">
        <span>{{ opponentName.slice(0, 2) }}</span>
      </div>
    </div>
    <div class="card-title">
      {{ title }}
    </div>
    <div class="card-subtitle">
      <slot name="card-subtitle"></slot>
    </div>
  </div>
</template>

<script>
import ThunderIcon from "../icon/ThunderIcon.vue";
export default {
  metaInfo() {
    return {
      title: "Base Case Card"
    };
  },
  name: "BaseCaseCard",
  props: [
    "status",
    "caseId",
    "ownerAvatar",
    "opponentAvatar",
    "ownerName",
    "opponentName",
    "title",
    "categoryId",
    "ownerWon"
  ],
  components: {
    ThunderIcon
  },
  computed: {
    badgeColor() {
      switch (this.status) {
        case "pending":
          return "#FFAB00";
        case "active":
          return "#27AE60";
        case "cancelled":
          return "#f00aa3";
        case "ended":
          return "#F7685B";
        default:
          return "#333";
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.card-container {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  background-color: @white;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 17px 20px rgba(50, 50, 50, 0.03);
}

.deleted {
  cursor: auto;
}

.card-cover {
  width: 100%;
  height: 60%;
  display: flex;
}

.separator {
  position: absolute;
  z-index: 1;
  right: 48%;
  top: 7px;
  height: 52%;
}

.image-properties() {
  display: inline-block;
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.my-image {
  .image-properties();
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  align-self: flex-start;
}

.opponent-image {
  .image-properties();
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.my-image-alternative {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.opponent-image-alternative {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.container__bg--gray {
  width: 50%;
  height: 100%;
  display: flex;
  background-color: #dddddd;
  justify-content: center;
  align-items: center;
  .poppins-medium(20px, @white);
  color: white;
}
.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.1;
  line-height: 22px;
  .poppins-medium(15px, @main-dark);
}

.case-tag {
  z-index: 1;
  position: absolute;
  right: 24px;
  top: 16px;
  border-radius: 28px;
  text-transform: capitalize;
  .gilroy(12px, @white);
  letter-spacing: -0.03em;
}

.ended {
  left: 30px;
  right: inherit;
}

.case-trophy {
  z-index: 1;
  position: absolute;
  left: 25px;
  top: 10px;
  width: 40px;
  height: 40px;
  background-color: @white;
  opacity: 0.9;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.opponent-won {
  right: 25px;
  left: auto;
  border-radius: 0px 8px 0px 8px;
}

.card-subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
