<template>
  <div class="what-you-can-find-wrapper landing-container">
    <h3>What you can find in YOUvME</h3>
    <div class="sections-wrapper">
      <div
        class="section"
        v-for="section in computedSections"
        :key="section.text"
      >
        <img class="image" v-lazy="section.image" />
        <div class="content">
          <div>{{ section.text }}</div>
          <lottie
            :options="{ animationData: section.lottie }"
            :width="iconSize"
            :height="iconSize"
          />
        </div>
      </div>
    </div>
    <button class="load-more" v-if="!active" @click="open">Load More</button>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import sport from "@/assets/animations/Sports.json";
import politic from "@/assets/animations/Politics.json";
import fashion from "@/assets/animations/Fashion.json";
import work from "@/assets/animations/Work.json";
import entertainment from "@/assets/animations/Entertainment.json";
import food from "@/assets/animations/Food.json";
import love from "@/assets/animations/Love.json";
import friendsFamily from "@/assets/animations/Family.json";
import money from "@/assets/animations/Money.json";
import animals from "@/assets/animations/Animals.json";
import realEstate from "@/assets/animations/Real-Estate.json";
import technology from "@/assets/animations/Technology.json";
import cars from "@/assets/animations/Cars.json";
import travel from "@/assets/animations/Travel.json";
import talent from "@/assets/animations/Talent.json";
import comedy from "@/assets/animations/Comedy.json";
import religion from "@/assets/animations/Religion.json";

const data = [
  {
    image: require("@/assets/images/sport.jpg"),
    text: "Sports",
    lottie: sport
  },
  {
    image: require("@/assets/images/political.jpg"),
    text: "Politics",
    lottie: politic
  },
  {
    image: require("@/assets/images/fashion.jpg"),
    text: "Fashion",
    lottie: fashion
  },
  {
    image: require("@/assets/images/work.jpg"),
    text: "Work",
    lottie: work
  },
  {
    image: require("@/assets/images/entertainment.jpg"),
    text: "Entertainment",
    lottie: entertainment
  },
  {
    image: require("@/assets/images/food.jpg"),
    text: "Food",
    lottie: food
  },
  {
    image: require("@/assets/images/love.jpeg"),
    text: "Relationships",
    lottie: love
  },
  {
    image: require("@/assets/images/friends-family.jpg"),
    text: "Friends / Family",
    lottie: friendsFamily
  },
  {
    image: require("@/assets/images/money.jpg"),
    text: "Money",
    lottie: money
  },
  {
    image: require("@/assets/images/animals.jpeg"),
    text: "Animals",
    lottie: animals
  },
  {
    image: require("@/assets/images/real-estate.jpeg"),
    text: "Real Estate",
    lottie: realEstate
  },
  {
    image: require("@/assets/images/technology.jpg"),
    text: "Technology",
    lottie: technology
  },
  {
    image: require("@/assets/images/cars.jpeg"),
    text: "Cars",
    lottie: cars
  },
  {
    image: require("@/assets/images/travel.jpeg"),
    text: "Travel",
    lottie: travel
  },
  {
    image: require("@/assets/images/talent.jpg"),
    text: "Talent",
    lottie: talent
  },
  {
    image: require("@/assets/images/comedy.jpg"),
    text: "Comedy",
    lottie: comedy
  },
  {
    image: require("@/assets/images/religion.jpg"),
    text: "Religion",
    lottie: religion
  }
];
export default {
  metaInfo() {
    return {
      title: "What You Can Find"
    };
  },
  components: {
    Lottie
  },
  data() {
    return {
      sections: data,
      active: false,
      iconSize: window.innerWidth > 768 ? 60 : 30
    };
  },
  computed: {
    computedSections() {
      return this.active
        ? this.sections
        : this.sections.slice(0, window.innerWidth > 768 ? 4 : 2);
    }
  },
  methods: {
    open() {
      this.active = true;
    }
  }
};
</script>
<style lang="less" scoped>
.what-you-can-find-wrapper {
  padding: 0 24px;

  &.landing-container {
    margin-bottom: 60px;
  }

  h3 {
    font-size: 32px;
    line-height: 1.2;
    margin: 60px 0;
    font-weight: 800;
    color: #2f205b;
  }

  .section {
    position: relative;
    border-radius: 4px;
    margin: 0 0 24px;

    .image {
      width: 100%;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, transparent 0%, #6644cb 100%);
      opacity: 0.4;
      z-index: 1;
      border-radius: 4px;
    }

    .content {
      color: white;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(29, 15, 70, 0.6);
      backdrop-filter: blur(6px);
      z-index: 2;
      font-size: 16px;
      font-weight: 800;
      border-radius: 4px;
      border-top: 1px solid rgba(255, 255, 255, 0.15);

      div {
        margin: 0 !important;
      }
    }
  }

  .load-more {
    width: 100%;
    background: #00b7e8;
    border-radius: 6px;
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    color: white;
    border: none;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .what-you-can-find-wrapper {
    padding: 0 100px;
    h3 {
      font-size: 60px;
      text-align: center;
    }

    .sections-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 80px;
      row-gap: 80px;
      margin: 0 0 112px;
    }

    .section {
      .content {
        padding: 40px 32px;
        font-size: 26px;
      }
    }

    .load-more {
      width: auto;
      padding: 20px 60px;
      margin: 0 auto;
      display: block;
      font-size: 20px;
    }
  }
}
</style>
