<template>
  <a-modal
    :visible="modalVisible"
    :title="title"
    centered
    :footer="null"
    :maskStyle="maskStyle"
    :destroyOnClose="true"
    :closable="closable"
    :dialog-style="dialogStyle"
    :width="width"
    @cancel="$emit('closeModal')"
  >
    <template slot="closeIcon">
      <a-icon type="close"></a-icon>
    </template>
    <slot name="modal-content"></slot>
  </a-modal>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Base Modal"
    };
  },
  name: "BaseModal",
  props: [
    "title",
    "modalVisible",
    "maskStyle",
    "closable",
    "width",
    "dialogStyle"
  ]
};
</script>

<style lang="less">
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-modal {
  border-radius: 10px !important;
}

.ant-modal-header {
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
  height: 60px;
  padding: 24px 24px;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-close,
.ant-modal-close-x {
  background-color: rgba(47, 69, 104, 0.05);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  top: 12px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-title {
  .gilroy(24px, @main-dark);
  display: flex;
  align-items: center;
}
</style>
