<template>
  <div>
    <a-row type="flex" justify="space-between" class="image-input">
      <a-col>
        <a-avatar :size="56" icon="user" :src="displayedAvatar" />
      </a-col>
      <a-col>
        <a-input
          class="file-input"
          type="file"
          :id="`upload-${type}`"
          accept="image/*"
          @change="handleUpload"
        />
        <label :for="`upload-${type}`">
          <span role="button">
            <span>Add Photo</span>
          </span>
        </label>
      </a-col>
    </a-row>
    <a-form-model
      ref="addUserForm"
      :model="formSubmit"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <base-form-item :prop="'fullName'" :label="'Full Name'">
        <template v-slot:form-input>
          <a-input v-model="formSubmit.fullName" />
        </template>
      </base-form-item>
      <base-form-item :prop="'username'" :label="'Username'">
        <template v-slot:form-input>
          <a-input v-model="formSubmit.username" />
        </template>
      </base-form-item>
      <base-form-item :prop="'email'" :label="'Email'">
        <template v-slot:form-input>
          <a-input v-model="formSubmit.email" />
        </template>
      </base-form-item>
      <base-form-item :prop="'userExpression'" :label="'Challenger View'">
        <template v-slot:form-input>
          <a-input v-model="formSubmit.userExpression" />
        </template>
      </base-form-item>
      <base-form-item :label="`${type} video`">
        <template v-slot:form-input>
          <a-row type="flex" align="middle">
            <a-col>
              <a-input style="display: none"  v-model="displayedVideo" />
              <a-input
                class="file-input"
                type="file"
                :id="`uploadVideo-${type}`"
                accept="video/mp4,video/x-m4v,video/*"
                
                @change="handleUploadVideo"
              />
              <label :for="`uploadVideo-${type}`">
                <span role="button">
                  <span>Upload Video</span>
                </span>
              </label>
            </a-col>
          </a-row>
          <a-row class="has-error" v-show="showError" style="margin-top: 8px" type="flex" align="middle">
            <div class="ant-form-explain">Video cannot be empty</div>
          </a-row>
          <a-row type="flex" style='margin-top: 0px'>
            <a-col  flex="40px" v-if='displayedVideo'>
              <video :id="`video-${type}`" width="320" height="240" controls>
                <source :src="displayedVideo">
              </video>
            </a-col>
          </a-row>
        </template>
      </base-form-item>
   
    </a-form-model>
  </div>
</template>

<script>
import BaseFormItem from "../base/BaseFormItem.vue";
import { userApi } from "../../apis";
import { challengeApi } from "../../apis";

export default {
  metaInfo() {
    return {
      title: "Add User Form"
    };
  },
  name: "ChallengeUser",
  props: ["type"],
  components: {
    BaseFormItem
  },
  data() {
    return {
      formSubmit: {
        fullName: "",
        username: "",
        email: "",
        userExpression: "",
      },
      loading: false,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      showError: false,
      rules: {
        fullName: [
          {
            required: true,
            message: "Full Name cannot be empty",
            trigger: "change"
          }
        ],
        username: [
          {
            required: true,
            message: "Username cannot be empty",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change"
          }
        ],
        userExpression: [
          {
            required: true,
            message: "Challenger View cannot be empty",
            trigger: "change"
          }
        ],
      
        
      },
      displayedAvatar: null,
      displayedVideo: null,
      formAvatar: null,
      formVideo: null,
      isSubmitting: false
    };
  },
  
  computed: {
    // urlVideo() {
    //   if(!this.videoUpload) return ''
    //   if(typeof this.videoUpload == "string") {
    //     return this.videoUpload
    //   } else {
    //     return URL.createObjectURL(this.videoUpload);
    //   }  
    // },
  },
  methods: {
    addUserForm() {
      return new Promise((resolve) => {
        this.$refs.addUserForm.validate(async valid => {
          if(!this.formVideo) {
            this.showError = true;
          } else {
            this.showError = false;
          }
          if(valid) resolve('success');
          if(!valid) resolve('error');
          
        })
      })
    
    },

    capture() {
      return new Promise((resolve) => {
        var canvas = document.createElement('canvas');
        var video = document.getElementById(`video-${this.type}`);
        console.log(video.videoWidth)
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        console.log(canvas)
        /** Code to merge image **/
        /** For instance, if I want to merge a play image on center of existing image **/
        const playImage = new Image();
        
      
        const startX = video.videoWidth / 2 - playImage.width / 2;
        const startY = video.videoHeight / 2 - playImage.height / 2;
        canvas.getContext("2d").drawImage(playImage, startX, startY, playImage.width, playImage.height);
        

        return canvas.toBlob(async function(blob){
          const file = new File(
            [blob],
            'filename.png',
            {
              type: blob.type,
              lastModified: new Date().getTime()
            }
          )
          let { data, success: successUpload } = await challengeApi.presignedUpload({ "type": "THUMBNAIL", "extension": "png"})
          if(successUpload) {
            await challengeApi.uploadFile(data.url, file);
            resolve(data.fileName)
          }
        },'image/png');
          
      
      })
      

      /** End **/
    },

    async onSubmit() {
      let thumbnail = await this.capture();
      let formData = new FormData();
      formData.append("username", this.formSubmit.username);
      formData.append("fullName", this.formSubmit.fullName);
      formData.append("email", this.formSubmit.email);
      formData.append("createByAdmin", JSON.stringify(true));
      if (this.formAvatar) {
        formData.append("avatar", this.formAvatar);
      }
      this.isSubmitting = true;
      const { data: dataUser, success } = await userApi.createUser({
        payload: formData
      });
      this.isSubmitting = false;
      formData = null;
      if (success) {
        if(this.formVideo) {
          let { data, success: successUpload } = await challengeApi.presignedUpload({ "type": "VIDEO", "extension": "mp4"})
          if(successUpload) {
            await challengeApi.uploadFile(data.url, this.formVideo);

          }
          return {status: 'success', videoUrl: data.fileName, userId: dataUser.id, userExpression: this.formSubmit.userExpression, thumbnail};
        } else {
          return {status: 'success', userId: dataUser.id, userExpression: this.formSubmit.userExpression, thumbnail};
        }
      } else {
        return false;
      }
    },

    handleUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.formAvatar = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const imageBuffer = reader.result;
          this.displayedAvatar = imageBuffer;
        };
      }
    },

    handleUploadVideo(event) {
      this.displayedVideo = null
      setTimeout(() => {
        const file = event.target.files[0];
        this.formVideo = file;
        if (file) {
          this.displayedVideo =  URL.createObjectURL(file);
        }
      }, 300);
    },
    
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.image-input {
  box-sizing: border-box;
  margin-bottom: 20px;
}

.file-input {
  display: none !important;
}

label {
  &:focus {
    outline: none !important;
  }
  > span[role="button"] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 124px;
    background-color: rgba(47, 69, 104, 0.05);
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.01em;
    .poppins-medium(12px, @blue);
  }
}

.user-avatar {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  object-fit: cover;
}

.submit-button-container {
  box-sizing: border-box;
  margin-top: 35px;
  margin-bottom: 15px;
}

#add-user-button {
  .secondary-button(115px);
}
.button-play {
  height: 38px;
  border-radius: 5px;
  background-color: rgba(0, 183, 232, 0.05);
  border: none;
  .poppins-medium(12px, @blue);
  &:hover,
  &:focus {
    border: none;
  }
}
</style>
