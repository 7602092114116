<script>
import { Bar } from "vue-chartjs";

export default {
  metaInfo() {
    return {
      title: "Bar Chart"
    };
  },
  extends: Bar,
  props: ["max", "labels", "data", "maxColumn", "dataName"],
  data() {
    return {
      chartMaxValue:
        Math.round(
          this.max /
            10 **
              ((Math.log10((this.max ^ (this.max >> 31)) - (this.max >> 31)) |
                0) +
                1 -
                1)
        ) *
        10 **
          ((Math.log10((this.max ^ (this.max >> 31)) - (this.max >> 31)) | 0) +
            1 -
            1) *
        2
    };
  },
  mounted() {
    this.addPlugin({
      id: "horizontalLine",
      afterDraw: function(chart) {
        if (typeof chart.config.options.lineAt != "undefined") {
          var lineAt = chart.config.options.lineAt;
          var ctxPlugin = chart.chart.ctx;
          var xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
          var yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
          if (yAxe.min != 0) return;

          ctxPlugin.strokeStyle = "#109CF1";
          ctxPlugin.fillStyle = "#109CF1";
          ctxPlugin.font = '12px "Poppins", sans-serif';
          ctxPlugin.beginPath();
          lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
          lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top;
          ctxPlugin.fillText("Max", xAxe.right - 40, lineAt - 10);

          ctxPlugin.moveTo(xAxe.left - 14, lineAt);
          ctxPlugin.lineTo(xAxe.right, lineAt);
          ctxPlugin.setLineDash([8, 8]);

          ctxPlugin.stroke();
        }
      }
    });
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.dataName,
            backgroundColor: this.data.map((d, index) => {
              return index === this.maxColumn ? "#00B7E8" : "#e5e5e5";
            }),
            data: this.data,
            barThickness: 15
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        legend: {
          display: false
        },
        lineAt: this.max,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: true
              },
              ticks: {
                padding: 15,
                fontSize: 13,
                fontFamily: "Gilroy-Medium",
                fontColor: "rgba(47,69,104,0.5)"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 8],
                color: "#E6E6E6",
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                max: this.chartMaxValue,
                padding: 30,
                fontSize: 13,
                fontFamily: "Poppins, sans-serif",
                fontColor: "#707683"
              }
            }
          ]
        }
      }
    );
  }
};
</script>
