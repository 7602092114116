import { challengeAction } from "../actions";
import { challengeMutation } from "../mutations";
import { challengeApi } from "../../apis";
import { getCommentChallenge } from "../../helpers";

const initialState = {
  challenges: [],
  challengePageCount: 0,
  challengeCount: 0,
  challengeLoading: false,
  comments: [],
  commentLoading: false
};

export const state = { ...initialState };

export const actions = {
  async [challengeAction.FETCH_CHALLENGES](context, params) {
    context.state.challengeLoading = true;
    const { data, success, paginate } = await challengeApi.fetchChallenge(
      params
    );
    context.state.challengeLoading = false;
    if (success) {


      let p = data.map(async res => {
        let getAva = async (avatarLink) => {
          if(!avatarLink) return null
          let split = [];
          if(res.owner.userId) {
            split =  avatarLink.split('/');
          }

          let form = {
            "type": "USER_AVATAR",
            "fileName": split[split.length -1]
          }
          return challengeApi.presignedDownload(form);
        }
        let owner = await getAva(res.owner.userId && res.owner.userId.avatar);
        let opponent = await getAva(res.opponent.userId && res.opponent.userId.avatar);
        return {
          ...res,
          ownerAvatar: owner && owner.success ? owner.data : '',
          opponentAvatar: opponent && opponent.success ? opponent.data : '',
        }
      })

      Promise.all(p)
      .then(res => {
        context.commit(challengeMutation.SET_CHALLENGE_MUTATION, res);
      })
      
      context.commit(
        challengeMutation.SET_CHALLENGE_PAGE_MUTATION,
        Math.ceil(paginate.total / 12)
      );
      context.commit(challengeMutation.SET_CHALLENGE_COUNT, paginate.total);
    }
  },
  async [challengeAction.FETCH_CHALLENGES_BY_CATEGORY](context, payload) {
    if (payload.categoryId === "all") {
      return context.dispatch("FETCH_CHALLENGES", payload.params);
    }
    context.state.challengeLoading = true;
    const {
      data,
      success,
      paginate
    } = await challengeApi.getChallengeByCategory(
      payload.categoryId,
      payload.params
    );
    context.state.challengeLoading = false;
    if (success) {
      
      context.commit(challengeMutation.SET_CHALLENGE_MUTATION, data);
      context.commit(
        challengeMutation.SET_CHALLENGE_PAGE_MUTATION,
        Math.ceil(paginate.total / 12)
      );
      context.commit(challengeMutation.SET_CHALLENGE_COUNT, paginate.total);
    }
  },
  async [challengeAction.FETCH_CHALLENGES_BY_USER](context, payload) {
    context.state.challengeLoading = true;
    const { data, success, paginate } = await challengeApi.getChallengeByUser(
      payload.userId,
      payload.params
    );
    context.state.challengeLoading = false;
    if (success) {
      let p = data.map(async res => {
        let getAva = async (avatarLink) => {
          if(!avatarLink) return null
          let split = [];
          if(res.owner.userId) {
            split =  avatarLink.split('/');
          }

          let form = {
            "type": "USER_AVATAR",
            "fileName": split[split.length -1]
          }
          return challengeApi.presignedDownload(form);
        }
        let owner = await getAva(res.owner.userId && res.owner.userId.avatar);
        let opponent = await getAva(res.opponent.userId && res.opponent.userId.avatar);
        return {
          ...res,
          ownerAvatar: owner && owner.success ? owner.data : '',
          opponentAvatar: opponent && opponent.success ? opponent.data : '',
        }
      })

      Promise.all(p)
      .then(res => {
        context.commit(challengeMutation.SET_CHALLENGE_MUTATION, res);
      })
      
      context.commit(
        challengeMutation.SET_CHALLENGE_PAGE_MUTATION,
        Math.ceil(paginate.total / 12)
      );
      context.commit(challengeMutation.SET_CHALLENGE_COUNT, paginate.total);
    }
  },
  async [challengeAction.FETCH_CHALLENGE_COMMENTS](context, payload) {
    context.state.commentLoading = true;
    const comments = await getCommentChallenge({
      challengeId: payload.challengeId,
      commentRef: payload.commentRef
    });
    context.state.commentLoading = false;
    context.commit(challengeMutation.SET_CHALLENGE_COMMENTS, comments);
  }
};

export const mutations = {
  [challengeMutation.SET_CHALLENGE_MUTATION](state, challenges) {
    state.challenges = challenges;
  },
  [challengeMutation.SET_CHALLENGE_PAGE_MUTATION](state, challengePageCount) {
    state.challengePageCount = challengePageCount;
  },
  [challengeMutation.SET_CHALLENGE_COUNT](state, challengeCount) {
    state.challengeCount = challengeCount;
  },
  [challengeMutation.SET_CHALLENGE_COMMENTS](state, comments) {
    state.comments = comments;
  }
};

const getters = {
  challenges(state) {
    return state.challenges;
  },
  challengeLoading(state) {
    return state.challengeLoading;
  },
  challengeCount(state) {
    return state.challengeCount;
  },
  challengePageCount(state) {
    return state.challengePageCount;
  },
  flagChallenges(state) {
    return state.flagChallenges;
  },
  comments(state) {
    return state.comments;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
