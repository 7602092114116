<template>
  <a-row :gutter="32">
    <a-col
      class="gutter-row"
      v-for="category in categoryData"
      :key="category.id"
      :lg="{ span: 6 }"
      :md="{ span: 8 }"
      :sm="{ span: 12 }"
      :xs="{ span: 24 }"
    >
      <category-card
        :title="category.title"
        :subTitle="category.subTitle"
        :iconBgColor="category.iconBgColor"
        :challengeNum="category.challengeNum"
        :categoryId="category.id"
        :icon="category.icon"
        :status="category.status"
      >
        <template v-slot:icon>
          <div v-if="!category.icon">
            <img src="../../assets/images/package.svg" alt="app-icon" />
          </div>
          <img v-else :src="category.icon" />
        </template>
      </category-card>
    </a-col>
  </a-row>
</template>

<script>
import CategoryCard from "./CategoryCard.vue";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: "Challenge List"
    };
  },
  name: "ChallengeList",
  components: {
    CategoryCard
  },
  computed: {
    ...mapGetters(["categoryData", "categoryLoading"])
  }
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
}
</style>
