<template>
  <div id="youvme-lading-page">
    <div class="ld_header_wrap">
      <fixed-header :threshold="100">
        <div class="ld_header">
          <div class="container landing-container">
            <div class="row-header-top">
              <router-link to="/">
                <img
                  class="youvme_logo"
                  alt="Youvme"
                  :src="require('../assets/images/youvme.svg')"
                />
              </router-link>
              <a-button
                class="menu-mobile-btn"
                @click="showDrawer"
                :icon="menuIcon"
              />
              <ul class="desktop-menu">
                <li>
                  <a class="page_link_ft" v-scroll-to="'#browse-challenge'"
                    >How does it Work?</a
                  >
                </li>
                <li>
                  <a class="page_link_ft" v-scroll-to="'#get-app'"
                    >Creating a Challenge</a
                  >
                </li>
                <li>
                  <a class="page_link_ft" v-scroll-to="'#joining-the-challenge'"
                    >App Features</a
                  >
                </li>
                <li>
                  <a class="page_link_ft" v-scroll-to="'#what-you-can-find'"
                    >Browse Categories</a
                  >
                </li>
                <li>
                  <button
                    class="btn-get-the-app"
                    v-scroll-to="'#download-application'"
                  >
                    Get the APP
                  </button>
                </li>
              </ul>
              <a-drawer
                class="menu-mobile-wapper"
                placement="left"
                :closable="false"
                :visible="visible"
                @close="onClose"
              >
                <div class="nav_ft">
                  <a class="page_link_ft" v-scroll-to="'#browse-challenge'"
                    >How does it Work?</a
                  >
                  <a class="page_link_ft" v-scroll-to="'#get-app'"
                    >Creating a Challenge</a
                  >
                  <a class="page_link_ft" v-scroll-to="'#joining-the-challenge'"
                    >App Features</a
                  >
                  <a class="page_link_ft" v-scroll-to="'#what-you-can-find'"
                    >Browse Categories</a
                  >
                  <button
                    class="btn-get-the-app"
                    v-scroll-to="'#download-application'"
                  >
                    Get the APP
                  </button>
                </div>
              </a-drawer>
            </div>
          </div>
        </div>
      </fixed-header>
      <top-banner />
    </div>

    <div
      id="browse-challenge"
      v-wow="{ 'animation-name': 'fadeInUp', 'animation-duration': '1s' }"
    >
      <browse-challenge />
    </div>
    <div
      id="description-video"
      v-wow="{ 'animation-name': 'fadeInUp', 'animation-duration': '1s' }"
    >
      <description-video />
    </div>
    <div
      id="get-app"
      v-wow="{ 'animation-name': 'fadeInUp', 'animation-duration': '1.2s' }"
    >
      <get-app ref="get-app" />
    </div>
    <div
      id="joining-the-challenge"
      v-wow="{ 'animation-name': 'fadeInUp', 'animation-duration': '1.2s' }"
    >
      <joining-the-challenge ref="joining-the-challenge" />
    </div>
    <div v-wow="{ 'animation-name': 'fadeInUp', 'animation-duration': '1.2s' }">
      <challenges-slides />
    </div>
    <div
      id="what-you-can-find"
      v-wow="{ 'animation-name': 'fadeInUp', 'animation-duration': '1.2s' }"
    >
      <what-you-can-find ref="what-you-can-find" />
    </div>
    <div v-wow="{ 'animation-name': 'fadeInUp', 'animation-duration': '1.2s' }">
      <ready-to-get-started />
    </div>
    <Footer />
  </div>
</template>

<script>
import TopBanner from "../components/landing/TopBanner.vue";
import BrowseChallenge from "../components/landing/BrowseChallenge.vue";
import DescriptionVideo from "../components/landing/DescriptionVideo.vue";
import GetApp from "../components/landing/GetApp.vue";
import JoiningTheChallenge from "../components/landing/JoiningTheChallenge.vue";
import ChallengesSlides from "../components/landing/ChallengesSlides.vue";
import ReadyToGetStarted from "../components/landing/ReadyToGetStarted.vue";
import WhatYouCanFind from "../components/landing/WhatYouCanFind.vue";
import mediaCheckerMixins from "../helpers/media-checker";
import FixedHeader from "vue-fixed-header";
import Footer from "../components/landing/Footer.vue";

export default {
  metaInfo() {
    return {
      title: "LandingPage"
    };
  },
  mixins: [mediaCheckerMixins],
  components: {
    TopBanner,
    BrowseChallenge,
    DescriptionVideo,
    GetApp,
    JoiningTheChallenge,
    ChallengesSlides,
    ReadyToGetStarted,
    WhatYouCanFind,
    FixedHeader,
    // ContactForm,
    Footer
  },
  name: "LandingPage",
  data() {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      loadingBtn: false,
      modalVisible: false,
      form: {
        name: "",
        email: ""
      },
      visible: false,
      menuIcon: "menu",
      copyrightYear: new Date().getFullYear(),
      rules: {
        name: [
          {
            required: true,
            message: "Your name cannot be empty",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change"
          },
          {
            pattern: /\S+@\S+\.\S+/,
            message: "Please type in a valid email address"
          }
        ]
      }
    };
  },
  methods: {
    showDrawer() {
      this.visible = true;
      this.menuIcon = "menu-fold";
    },
    onClose() {
      this.visible = false;
      this.menuIcon = "menu-unfold";
    },
    showModal() {
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
    }
  },
  mounted() {
    if (this.$route.hash && this.$route.hash.toString().indexOf("#") >= 0) {
      const element = this.$refs[this.$route.hash.replace("#", "")];
      if (element) {
        element.$el.scrollIntoView();
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";
@import "../styles/fonts.less";
@import "../styles/colors.less";
#youvme-lading-page {
  font-family: "Gilroy";
}
.row-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page_link_ft {
  color: #715fa6;
}
.ld_header_wrap {
  // .header__landing--bg();
  background-color: #201347;
  .ld_header.vue-fixed-header--isFixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    background: #2f205b;
    z-index: 99;
  }
  .ld_header {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 30px 20px 30px;
    .youvme_logo {
      max-width: 120px;
    }
    .header_content {
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: center;
      .header_title {
        .gilroy-bold(3.5em, #fff);
      }
      .header_username {
        .gilroy-bold(1.2em, #8c8bbc);
      }
      .header_description {
        .gilroy-bold(1.2em, #fff);
      }
    }
  }
}
.ld_content {
  padding-top: 50px;
  padding-bottom: 80px;
  .ld_form {
    display: flex;
    justify-content: center;
  }
  .landing_page_form {
    min-width: 300px;
  }
  .landing_page_form .form-label {
    color: #2f205b !important;
    font-weight: bold;
  }
  .appstore-box {
    padding-top: 70px;
    display: flex;
    justify-content: center;
    text-align: center;
    p {
      color: #2f205b;
      font-weight: bold;
      .gilroy-bold(1.2em, #2f205b);
    }
  }
}
.ld_footer {
  .logo_ft {
    .youvme_logo {
      max-width: 120px;
    }
    padding: 30px 90px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #291b54;
    #contact_us-button {
      background: #322360;
      border: none;
      padding: 0px 40px;
      height: 40px;
      .gilroy-bold(1em, #fff);
    }
  }
  .link_ft {
    padding: 30px 90px;
    background: #2f205c;
    .nav_ft {
      display: flex;
      padding-top: 8px;
      .page_link_ft {
        .gilroy(1em, #715fa6);
        padding: 0px 30px;
      }
      .page_link_ft:nth-child(1) {
        padding-left: 0px !important;
      }
    }
    .social_icon_ft {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .copyright-wraper {
      padding-top: 30px;
      display: flex;
      justify-content: flex-start;
      .copyright {
        padding-right: 50px;
        .gilroy(1em, #4c3c7d);
        a {
          .gilroy(1em, #4c3c7d);
        }
      }
    }
  }
}
.menu-toggle {
  position: absolute;
  right: 0px;
  z-index: 999;
  min-width: 200px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.desktop-menu {
  display: none;
}
.menu-mobile-btn {
  display: block;
}
.btn-get-the-app {
  font-size: 0.8rem;
  color: white;
  background: #00b7e8;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .ld_header {
    padding-left: 0;
    padding-right: 0;
  }

  .menu-mobile-btn {
    display: none;
  }

  .desktop-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    align-items: center;
    li {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
    a {
      color: #715fa6;
      font-size: 1rem;
    }
    .btn-get-the-app {
      font-size: 1rem;
    }
  }
}

.ant-drawer {
  z-index: 999;
}

@media (min-width: 1220px) {
  .desktop-menu {
    li {
      margin-left: 40px;
    }
  }
}

@import "../styles/responsive.less";
</style>
