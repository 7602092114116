<template>
  <base-modal :title="'Block User'" :modalVisible="modalVisible" :maskStyle="maskStyle" :dialogStyle="dialogStyle"
    @closeModal="$emit('closeModal')" :width="'480px'">
    <template v-slot:modal-content>
      <a-table :columns="columns" :data-source="blockUserData" :pagination="false" :loading="loading">
        <span :key="record._id" @click="selectUser(record._id)" slot="avatar" slot-scope="text, record">
          <a-avatar :src="record.avatar" :size="37">{{
              record.username.slice(0, 2)
          }}</a-avatar>
          <span class="username">{{ record.username }}</span>
        </span>
      </a-table>
      <a-row type="flex" justify="end" style="margin-top: 30px">
        <a-pagination :total="pageCount * 10" @change="onChange"></a-pagination>
      </a-row>
    </template>
  </base-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseModal from "../base/BaseModal.vue";
const columns = [
  {
    title: "Username",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "avatar" },
    ellipsis: true,
    width: "15%"
  },
];
export default {
  name: "BlockUserModal",
  props: ["modalVisible", "maskStyle", "userInfo", "dialogStyle", "loading", "userId"],
  components: {
    BaseModal,
  },
  data () {
    return {
      columns,
      wrapperCol: { span: 24 },
      labelCol: { span: 24 },
    };
  },
  computed: {
    ...mapGetters([
      "blockUserData",
      "pageCount",
      "userLoading",
    ])
  },
  methods: {
    ...mapActions({
      getBlockUser: "FETCH_BLOCK_USERS"
    }),
    onChange (current) {
      this.currentPage = current;
      this.getBlockUser({
        page: current,
        limit: 12,
        userId: this.userId
      });
    },
    selectUser (userId) {
      this.$router.push(`/admin/users/${userId}`);
    }
  },
  created () {
    this.getBlockUser({
      page: 1,
      limit: 12,
      userId: this.userId
    });
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.delete-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 350px;

  >div:nth-child(1) {
    .gilroy(14px, @main-dark);
  }
}

.delete-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  .gilroy-bold(26px, @main-dark);
}

.delete-button {
  .red-button(127px);
  margin-right: 5px;
}

.no-delete-button {
  .secondary-button(135px);
  width: 135px;
  margin-left: 5px;
}

#change-password-button {
  .secondary-button(115px);
}
</style>
