<template>
  <a-form-model-item :prop="prop" has-feedback>
    <label :for="prop" class="form-label">{{ label }}</label>
    <slot name="form-input"></slot>
  </a-form-model-item>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Base Form Item"
    };
  },
  name: "BaseFormItem",
  props: ["label", "prop"]
};
</script>

<style lang="less">
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.form-label {
  .gilroy(14px, @dark);
  opacity: 0.5;
  letter-spacing: -0.03em;
}

.ant-input {
  .gilroy(14px, @main-dark);
  opacity: 1 !important;
  height: 48px;
}

.ant-form-item {
  margin-bottom: 15px;
  box-sizing: border-box;
}

.ant-form-explain {
  position: absolute;
  z-index: 1;
}
</style>
