import { render, staticRenderFns } from "./ChallengeUser.vue?vue&type=template&id=529fcfb6&scoped=true&"
import script from "./ChallengeUser.vue?vue&type=script&lang=js&"
export * from "./ChallengeUser.vue?vue&type=script&lang=js&"
import style0 from "./ChallengeUser.vue?vue&type=style&index=0&id=529fcfb6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529fcfb6",
  null
  
)

export default component.exports