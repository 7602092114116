<template>
  <a-layout-sider
    id="sidebar"
    v-model="collapsed"
    collapsible
    width="256"
    :style="{ overflow: 'auto', height: '100vh', position: 'fixed', top: 0 }"
    :trigger="null"
    @collapse="onCollapse"
    breakpoint="lg"
  >
    <div class="logo">
      <img
        :src="
          !collapsed
            ? require('../assets/images/youvme.svg')
            : require('../assets/images/applogo.svg')
        "
        width="150"
        height="40"
      />
    </div>
    <div class="action-container">
      <a-menu theme="dark" :default-selected-keys="['1']" mode="inline">
        <a-menu-item
          v-for="(field, index) in fields"
          :key="index + 1"
          :class="{ selected: field.value === activeRoute }"
          :title="field.label"
        >
          <router-link :to="`/${field.value}`">
            <component
              :is="field.icon"
              :active="field.value === activeRoute"
              class="icon"
            ></component>
            <span v-if="!collapsed">{{ field.label }}</span>
          </router-link>
        </a-menu-item>
      </a-menu>
      <div class="toggle-sidebar" @click="toggleSidebar()">
        <img
          src="../assets/images/toggle.svg"
          alt="toggle-icon"
          width="14"
          height="14"
        />
        <span v-if="!collapsed">Toggle Sidebar</span>
      </div>
    </div>
  </a-layout-sider>
</template>

<script>
import DashBoardIcon from "../components/icon/DashBoardIcon.vue";
import CategoriesIcon from "../components/icon/CategoriesIcon.vue";
import ChallengesIcon from "../components/icon/ChallengesIcon.vue";
import UsersIcon from "../components/icon/UsersIcon.vue";
import SettingIcon from "../components/icon/SettingIcon.vue";
import FlagIcon from "../components/icon/FlagIcon.vue";

export default {
  name: "Sidebar",
  props: ["activeRoute"],
  components: {
    DashBoardIcon,
    CategoriesIcon,
    ChallengesIcon,
    UsersIcon,
    SettingIcon,
    FlagIcon
  },
  data: () => ({
    collapsed: false,
    fields: [
      { value: "admin", label: "Dashboard", icon: "dash-board-icon" },
      {
        value: "admin/challenges",
        label: "Challenges",
        icon: "challenges-icon"
      },
      {
        value: "admin/users",
        label: "Users",
        icon: "users-icon",
        sicon: "users"
      },
      {
        value: "admin/categories",
        label: "Categories",
        icon: "categories-icon"
      },
      { value: "admin/settings", label: "Settings", icon: "setting-icon" },
      { value: "admin/flags", label: "Flags", icon: "flag-icon" }
    ]
  }),
  methods: {
    toggleSidebar() {
      this.collapsed = !this.collapsed;
      this.$emit("toggleSidebar");
    },
    onCollapse() {
      this.$emit("toggleSidebar");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/fonts.less";
@import "../styles/colors.less";

@bg-color: #2f205b;

@transition: all 0.1s linear;

#sidebar {
  transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  -webkit-transition: @transition;
}

.logo {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.action-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 60px);
}

.toggle-sidebar {
  height: 55px;
  letter-spacing: 0.01em;
  background-color: #221548;
  border-top: 1px solid #59419f;
  .poppins-medium(13px, rgba(255, 255, 255, 0.5));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > span:nth-child(2) {
    box-sizing: border-box;
    margin-left: 10px;
  }
  &:hover {
    .poppins-medium(13px, @white);
  }
}

.ant-menu-item {
  height: 60px;
  background-color: transparent;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;

  padding-left: 30px !important;
  > a {
    .poppins-medium(13px, #454176) !important;
    display: flex;
    align-items: center;
    > .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      box-sizing: border-box;
      top: 10px;
    }
  }
}

.ant-layout-sider,
.ant-menu {
  background-color: @bg-color;
  overflow-x: hidden !important;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}
.selected {
  > a {
    .poppins-medium(13px, @white) !important;
  }
}
</style>
