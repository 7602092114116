<template>
  <base-icon :viewBox="'0 0 16 16'">
    <template v-slot:icon-name>
      <path
        class="st0"
        :class="{ active: active }"
        d="M4.8,4.1c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2S4.8,5.9,4.8,4.1z M0.9,12.9
	c0-0.4,0.2-0.8,0.6-1.2c0.4-0.4,1.1-0.8,1.9-1.1C4.9,10,6.8,9.7,8,9.7s3.1,0.3,4.6,0.9c0.8,0.3,1.4,0.7,1.9,1.1
	c0.4,0.4,0.6,0.8,0.6,1.2v2.2H0.9V12.9z"
      />
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "../base/BaseIcon.vue";
export default {
  name: "Users",
  props: ["active"],
  components: {
    BaseIcon
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";

.st0 {
  fill: none;
  stroke: #454176;
  stroke-width: 1.4;
}

.active {
  stroke: @blue;
}
</style>
