<template>
  <div class="browse-challenges-wrapper landing-container">
    <h2>Browse<wbr /> Challenges &amp; Vote</h2>
    <p class="description">
      Browse thousands of video challenges from categories you love. Listen to
      both challengers and place your vote on the video your support!
    </p>
    <div class="items mobile">
      <VueSlickCarousel
        :arrows="false"
        :dots="false"
        :centerMode="true"
        :centerPadding="'15px'"
        :infinite="false"
        :responsive="responsiveOpts"
      >
        <div class="item first">
          <div class="content">
            <h3>30 Second <span class="highlight">Challenges</span></h3>
            <p>
              Upload your 30 second video challenge and start competing against
              friends, family and random opponents. The bragging rights are up
              for the taking.
            </p>
          </div>
        </div>
        <div class="item second">
          <div class="content">
            <h3>Browse by <span class="highlight">Category</span></h3>
            <p>
              Love to sing, dance or debate? We've got a category for everyone!
              Sit back and enjoy your favorite genres with an edless supply of
              entertainment.
            </p>
          </div>
        </div>
        <div class="item last">
          <div class="content">
            <h3>Like. Comment. <span class="highlight">Vote!</span></h3>
            <p>
              Make your voice heard by leaving a like, comment, or better yet
              voting on your favorite challenges. Who will win? You be the
              judge!
            </p>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  metaInfo() {
    return {
      title: "Browse Challenges"
    };
  },
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      responsiveOpts: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 2550,
          settings: "unslick"
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
@import "~vue-slick-carousel/dist/vue-slick-carousel.css";

.browse-challenges-wrapper {
  padding: 40px 24px;

  h2 {
    font-size: 32px;
    color: #2f205b;
    line-height: 1.2;
    font-weight: 800;
    margin: 0 0 40px;
  }

  .description {
    font-size: 16px;
    line-height: 1.6;
    color: #978fad;
    margin: 0 0 40px;
  }

  .items {
    margin: 0 -24px;
  }

  .item {
    display: flex;
    padding: 40px 7.5px;

    .content {
      padding: 5px;
      border-radius: 10px;
      transform: scaleY(0.8);
      transition: 0.3s;
      text-align: center;
      height: 365px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      border-radius: 8px;
      background-size: cover;

      h3 {
        font-size: 26px;
        line-height: 1;
        font-weight: 800;
        color: white;
        position: relative;
        z-index: 2;

        .highlight {
          color: #00b7e8;
        }
      }

      p {
        position: relative;
        z-index: 3;
        color: #8472b5;
      }
    }

    &.first .content {
      background-image: url("../../assets/images/browse-challenge-1.png");
    }

    &.second .content {
      background-image: url("../../assets/images/browse-challenge-2.png");
    }

    &.last .content {
      background-image: url("../../assets/images/browse-challenge-3.png");
    }
  }

  .slick-active .item .content {
    transform: scaleY(1);
  }
}

@media screen and (min-width: 768px) {
  .browse-challenges-wrapper {
    padding: 110px 24px;

    h2 {
      font-size: 60px;
      margin: 0 0 32px;
    }

    .description {
      font-size: 20px;
      line-height: 1.25;
    }

    .slider {
      justify-content: center;
    }

    .item {
      padding: 0;

      .content {
        max-width: 389px;
        min-height: 488px;
        transform: none;
      }

      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      &.second {
        margin-top: 40px;
      }
    }
  }
}
</style>
