<template>
  <base-icon :viewBox="'0 0 15 183'">
    <template v-slot:icon-name>
      <path
        class="st0"
        d="M13.8,112.6c0.1-1-1.1-1.8-2.3-1.5c-0.6,0.1-1.4-0.2-1.2-0.8L15,67.8c0.1-2-0.5-5-2.7-4.3
	c-1,0.3-2.7-0.6-2.7-1.5L15,1.4C15,1.2,15.2,0,15,0H6.9C5.5,0,4.5-0.3,4.1,3.4L0,70.4c0,2.1,1.4,3.4,3.6,2.6
	c1.2-0.2,2.2,1.6,2.2,2.5l-3.3,40.4c0,1.5,2.4,2.1,4,1.6c0.9-0.2,1,1.7,1,2.4l-0.8,63h3L13.8,112.6z"
      />
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "../base/BaseIcon.vue";
export default {
  name: "ThunderIcon",
  components: {
    BaseIcon
  }
};
</script>

<style lang="less" scoped>
.st0 {
  fill: #ffffff;
}
</style>
