var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{attrs:{"title":'Edit Category',"closable":true,"width":'480px',"maskStyle":_vm.maskStyle,"modalVisible":_vm.modalVisible,"dialogStyle":_vm.dialogStyle},on:{"closeModal":function($event){return _vm.$emit('closeEditModal')}},scopedSlots:_vm._u([{key:"modal-content",fn:function(){return [_c('a-row',{staticClass:"image-input",attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('a-avatar',{attrs:{"size":56,"icon":"user","src":_vm.displayedAvatar}})],1),_c('a-col',[_c('a-input',{attrs:{"type":"file","id":"upload","accept":"image/*"},on:{"change":_vm.handleUpload}}),_c('label',{attrs:{"for":"upload"}},[_c('span',{attrs:{"role":"button"}},[_c('span',[_vm._v("Add Photo")])])])],1)],1),_c('a-form-model',{ref:"editCategoryForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.onSubmit}},[_c('base-form-item',{attrs:{"prop":'name',"label":'Name'},scopedSlots:_vm._u([{key:"form-input",fn:function(){return [_c('a-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]},proxy:true}])}),_c('base-form-item',{attrs:{"prop":'description',"label":'Description'},scopedSlots:_vm._u([{key:"form-input",fn:function(){return [_c('a-input',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]},proxy:true}])}),_c('base-form-item',{attrs:{"label":'Background Color'},scopedSlots:_vm._u([{key:"form-input",fn:function(){return [_c('verte',{attrs:{"picker":"square","model":"hex","menuPosition":"top"},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}}),_c('div',{style:({
              backgroundColor: _vm.form.color,
              height: '48px',
              width: '100%',
              borderRadius: '4px'
            })})]},proxy:true}])}),_c('base-form-item',{attrs:{"prop":'status',"label":'Status'},scopedSlots:_vm._u([{key:"form-input",fn:function(){return [_c('base-select-dropdown',{attrs:{"valueList":[
              { key: 'active', label: 'Active' },
              { key: 'inactive', label: 'Inactive' }
            ],"defaultValue":{ key: _vm.form.status }},on:{"selected":_vm.onSelect}})]},proxy:true}])}),_c('a-row',{staticClass:"row__mt--40",attrs:{"type":"flex","justify":"center"}},[_c('a-button',{staticClass:"button__state--secondary",attrs:{"html-type":"submit","loading":_vm.isSubmitting}},[_vm._v(" Save ")])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }