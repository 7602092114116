import { render, staticRenderFns } from "./ChallengeList.vue?vue&type=template&id=5bc06092&scoped=true&"
import script from "./ChallengeList.vue?vue&type=script&lang=js&"
export * from "./ChallengeList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc06092",
  null
  
)

export default component.exports