<template>
  <div>
    <a-row type="flex" justify="space-between" class="image-input">
      <a-col>
        <a-avatar :size="56" icon="user" :src="displayedAvatar" />
      </a-col>
      <a-col>
        <a-input
          type="file"
          id="upload"
          accept="image/*"
          @change="handleUpload"
        />
        <label for="upload">
          <span role="button">
            <span>Add Photo</span>
          </span>
        </label>
      </a-col>
    </a-row>
    <a-form-model
      ref="addUserForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      @submit="onSubmit"
    >
      <base-form-item :prop="'fullName'" :label="'Full Name'">
        <template v-slot:form-input>
          <a-input v-model="form.fullName" />
        </template>
      </base-form-item>
      <base-form-item :prop="'username'" :label="'Username'">
        <template v-slot:form-input>
          <a-input v-model="form.username" />
        </template>
      </base-form-item>
      <base-form-item :prop="'email'" :label="'Email'">
        <template v-slot:form-input>
          <a-input v-model="form.email" />
        </template>
      </base-form-item>
      <a-row type="flex" justify="center" class="submit-button-container"
        ><a-button
          id="add-user-button"
          html-type="submit"
          :loading="isSubmitting"
        >
          Add User
        </a-button></a-row
      >
    </a-form-model>
  </div>
</template>

<script>
import BaseFormItem from "../base/BaseFormItem.vue";
import { userApi } from "../../apis";
import { message } from "ant-design-vue";

export default {
  metaInfo() {
    return {
      title: "Add User Form"
    };
  },
  name: "AddUserForm",
  props: ["visible"],
  components: {
    BaseFormItem
  },
  data() {
    return {
      loading: false,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      form: {
        fullName: "",
        username: "",
        email: ""
      },
      rules: {
        fullName: [
          {
            required: true,
            message: "Full Name cannot be empty",
            trigger: "change"
          }
        ],
        username: [
          {
            required: true,
            message: "Username cannot be empty",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "change"
          }
        ]
      },
      displayedAvatar: null,
      formAvatar: null,
      isSubmitting: false
    };
  },
  methods: {
    handleUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.formAvatar = file;
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const imageBuffer = reader.result;
          this.displayedAvatar = imageBuffer;
        };
      }
    },
    onSubmit(e) {
      e.preventDefault();
      this.$refs.addUserForm.validate(async valid => {
        if (valid) {
          let formData = new FormData();
          formData.append("username", this.form.username);
          formData.append("fullName", this.form.fullName);
          formData.append("email", this.form.email);
          formData.append("createByAdmin", JSON.stringify(true));
          if (this.formAvatar) {
            formData.append("avatar", this.formAvatar);
          }
          this.isSubmitting = true;
          const { success } = await userApi.createUser({
            payload: formData
          });
          this.isSubmitting = false;
          formData = null;
          if (success) {
            message.success("User added successfully");
            this.$emit("submitForm");
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/mixins.less";

.image-input {
  box-sizing: border-box;
  margin-bottom: 20px;
}

#upload {
  display: none !important;
}

label {
  &:focus {
    outline: none !important;
  }
  > span[role="button"] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 124px;
    background-color: rgba(47, 69, 104, 0.05);
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.01em;
    .poppins-medium(12px, @blue);
  }
}

.user-avatar {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  object-fit: cover;
}

.submit-button-container {
  box-sizing: border-box;
  margin-top: 35px;
  margin-bottom: 15px;
}

#add-user-button {
  .secondary-button(115px);
}
</style>
