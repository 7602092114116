import { request } from "../helpers";

export const fetchSettings = async () =>
  request({
    method: "GET",
    url: "/settings"
  });

export const updateSettings = async payload =>
  request({
    method: "PUT",
    url: "/settings",
    data: payload
  });

export const updateWebKeyToken = async payload =>
  request({
    method: "POST",
    url: "/settings/update-web-key-token",
    data: payload
  });
export const deleteWebKeyToken = async payload =>
  request({
    method: "DELETE",
    url: "/delete-web-key-token",
    data: payload
  });
