import { render, staticRenderFns } from "./ChallengeCommentItem.vue?vue&type=template&id=5e83014e&scoped=true&"
import script from "./ChallengeCommentItem.vue?vue&type=script&lang=js&"
export * from "./ChallengeCommentItem.vue?vue&type=script&lang=js&"
import style0 from "./ChallengeCommentItem.vue?vue&type=style&index=0&id=5e83014e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e83014e",
  null
  
)

export default component.exports