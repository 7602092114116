<template>
  <div>
    <!-- 
     <a-row type="flex" justify="space-between">
      <a-col>Notifications on comment reports</a-col>
      <a-col>
        <a-switch v-model="notifications.onCommentReport"></a-switch>
      </a-col>
    </a-row>
     -->

    <a-row type="flex" justify="space-between">
      <a-col>Notifications on new users</a-col>
      <a-col>
        <a-switch v-model="notifications.onNewUser"></a-switch>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between">
      <a-col>Notifications on challenge ending</a-col>
      <a-col>
        <a-switch v-model="notifications.onChallengeEnding"></a-switch>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between">
      <a-col>Notifications on new challenges</a-col>
      <a-col>
        <a-switch v-model="notifications.onNewChallenge"></a-switch>
      </a-col>
    </a-row>
    <!-- 
      <a-row type="flex" justify="space-between">
      <a-col>Notifications on promotion end</a-col>
      <a-col>
        <a-switch v-model="notifications.onPromotionEnd"></a-switch>
      </a-col>
    </a-row>
     -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Notification Settings"
    };
  },
  name: "NotificationSettings",
  computed: {
    ...mapGetters(["notifications"])
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.ant-row-flex {
  margin-bottom: 20px;
  > .ant-col:nth-child(1) {
    .gilroy(14px, rgba(47, 69, 104, 0.5));
    letter-spacing: -0.03em;
  }
  > .ant-col:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media screen and (max-width: 470px) {
  .ant-row-flex {
    > .ant-col:nth-child(1) {
      width: 100%;
    }
    > .ant-col:nth-child(2) {
      justify-content: flex-start;
    }
  }
}

.ant-switch {
  width: 25px !important;
  height: 18px;
  background-color: #f7fbff;
  border: 1px solid rgba(44, 24, 140, 0.07);
}

.ant-switch::after {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  top: -2.5px;
  left: -1px;
}

.ant-switch-checked::after {
  margin-left: 0px;
  left: 105%;
}

.ant-switch-checked {
  background-color: @green;
}
</style>
