<template>
  <div>
    <a-row type="flex" justify="space-between" class="header-container">
      <a-col :span="6">
        <div class="header__headline--font">Flagged Challenges</div>
        <div class="header__description--font">{{ flagCount }} Challenges</div>
      </a-col>
      <a-col>
        <a-button @click="showDropdown = !showDropdown"
          ><span>{{ chosenStatus }}</span> <a-icon type="down"
        /></a-button>
        <transition name="slide">
          <a-menu v-if="showDropdown">
            <a-menu-item
              v-for="status in statusList"
              :key="status"
              @click="setStatus(status)"
              >{{ status }}</a-menu-item
            >
          </a-menu>
        </transition>
      </a-col>
    </a-row>
    <flag-table :tableData="flagData" :loading="flagLoading"></flag-table>
    <a-row type="flex" justify="end" style="margin-top: 30px">
      <a-pagination
        :current="currentPage"
        :total="flagPageCount * 10"
        @change="onPaginate"
      ></a-pagination>
    </a-row>
  </div>
</template>

<script>
import FlagTable from "../components/flags/FlagTable.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { FlagTable },
  metaInfo() {
    return {
      title: "Flags"
    };
  },
  name: "Flags",
  data() {
    return {
      showDropdown: false,
      statusList: ["all", "pending", "ignore", "suspend"],
      chosenStatus: "all",
      currentPage: 1
    };
  },
  computed: {
    ...mapGetters(["flagData", "flagPageCount", "flagCount", "flagLoading"])
  },
  methods: {
    ...mapActions({
      fetchFlags: "FETCH_FLAGS"
    }),
    async onPaginate(current) {
      this.currentPage = current;
      this.loading = true;
      await this.fetchFlags({
        status:
          this.chosenStatus !== "all" ? this.chosenStatus.toUpperCase() : null,
        page: current,
        limit: 10
      });
      this.loading = false;
    },
    async setStatus(status) {
      this.chosenStatus = status;
      this.showDropdown = false;
      this.loading = true;
      await this.fetchFlags({
        status:
          this.chosenStatus !== "all" ? this.chosenStatus.toUpperCase() : null,
        page: 1,
        limit: 10
      });
      this.loading = false;
      this.currentPage = 1;
    }
  },
  created() {
    this.fetchFlags({
      page: this.currentPage,
      limit: 10
    });
  }
};
</script>

<style lang="less" scoped>
@import "../styles/fonts.less";
@import "../styles/colors.less";
@import "../styles/mixins.less";

.ant-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  height: 38px;
  width: 150px;
  box-sizing: border-box;
  border-radius: 5px;
  .poppins-light(12px, @dark-blue);
  border: 1px solid #ebeff2;
  &:hover,
  &:focus {
    border: 1px solid #ebeff2;
  }
  > .anticon {
    color: @blue;
  }
}

.ant-menu {
  position: absolute;
  z-index: 3;
  width: 150px;
  left: 2px;
  top: 70px;
  border-radius: 5px;
  box-shadow: 0px 17px 20px rgba(50, 50, 50, 0.03);
}

.ant-menu-item {
  margin: 0px 0px !important;
  .poppins-light(12px, @dark-blue);
  text-transform: capitalize;
  &:hover {
    background: rgba(27, 160, 251, 0.4);
  }
  &:first-child {
    border-radius: 5px 5px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 5px 5px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s linear;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-300px);
}
</style>
