<template>
  <div
    class="card-container"
    :class="{ lifting: cardHover }"
    @click="getChallengesByCategory"
    @mouseenter="cardHover = true"
    @mouseleave="cardHover = false"
  >
    <a-row type="flex" align="middle" justify="space-between">
      <a-col :style="{ 'background-color': iconBgColor }" class="icon-container"
        ><slot name="icon"></slot
      ></a-col>
      <a-col class="challenge-tag"
        ><a-tag v-if="!cardHover">{{ challengeNum }} challenges</a-tag>
        <a-row v-if="cardHover && title !== 'All'">
          <a-col
            class="icon-container"
            style="background-color: rgba(0,183,232, 0.05)"
            :span="10"
            @click="openeditform"
          >
            <img src="../../assets/images/edit.svg" alt="edit-icon"
          /></a-col>
          <a-col
            class="icon-container"
            style="background-color: rgba(247,104,91,0.05); margin-left:8px;"
            :span="10"
            @click="opendeleteModal"
          >
            <img src="../../assets/images/delete.svg" alt="delete-icon"
          /></a-col>
        </a-row>
      </a-col>
    </a-row>
    <div class="card-title">{{ title }}</div>
    <div class="card-subTitle">{{ subTitle }}</div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Base Category Card"
    };
  },
  name: "BaseCategoryCard",
  props: [
    "challengeNum",
    "title",
    "subTitle",
    "iconBgColor",
    "categoryId",
    "icon",
    "status"
  ],
  inject: ["openEditForm", "openDeleteModal"],
  data() {
    return {
      cardHover: false
    };
  },
  methods: {
    getChallengesByCategory() {
      this.$router.push(`/admin/challenges/${this.categoryId}`);
    },
    openeditform(e) {
      e.stopPropagation();
      this.openEditForm({
        title: this.title,
        description: this.subTitle,
        id: this.categoryId,
        icon: this.icon,
        status: this.status,
        iconBgColor: this.iconBgColor
      });
    },
    opendeleteModal(e) {
      e.stopPropagation();
      this.openDeleteModal({
        title: this.title,
        description: this.subTitle,
        id: this.categoryId,
        icon: this.icon
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";

.lifting {
  transition: all 0.5s linear;
  transform: translateY(-20px);
  z-index: 2;
}

.card-container {
  width: 100%;
  height: 220px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  background-color: @white;
  display: inline-block;
  padding: 20px;
  cursor: pointer;
  border: 1px solid rgba(47, 69, 104, 0.05);
  box-shadow: 0px 17px 20px -5px rgba(50, 50, 50, 0.06);
  > .ant-row-flex {
    box-sizing: border-box;
    margin-bottom: 20px;
  }
}

.paragraph-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title {
  .poppins-bold(18px, @main-dark);
  .paragraph-overflow();
  white-space: nowrap;
  letter-spacing: 0.01em;
  margin-bottom: 15px;
}

.card-subTitle {
  .gilroy(15px, @dark);
  .paragraph-overflow();
  letter-spacing: -0.03em;
  opacity: 0.5;
  height: 70px;
}

.ant-tag {
  height: 20px;
  margin-right: 0px;
  border-radius: 28px;
  background: rgba(47, 69, 104, 0.1);
  .gilroy-bold(12px, @main-dark);
  letter-spacing: -0.03em;
  opacity: 0.5;
  border: none;
}

.icon-container {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  z-index: 3;
}

.challenge-tag {
  align-self: flex-start;
}
</style>
